/* eslint-disable */
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import React, { useEffect, useMemo } from "react";

import { ContentCopy, ThumbUpOutlined, ThumbDownOutlined } from "@mui/icons-material";
import { Button } from "@/components/Button";
import {
  ActionMessage as ActionMessageType,
  Action as ActionType,
  ErrorFile as ErrorFileType,
  ErrorMessage as ErrorMessageType,
  FeedbackMessage as FeedbackMessageType,
  FileMessage as FileMessageType,
  FlowMessage as FlowMessageType,
  HandleEvaluation,
  InMessageFile as InMessageFileType,
  Message,
  TextMessage as TextMessageType,
  UploadedFile as UploadedFileType,
  UploadingFile as UploadingFileType,
  WaitingForResponse,
  WaitingForResponseType,
  actionCreditMap,
  useMessagesContext,
} from "@/contexts/MessagesContext";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { Avatar, IconButton, SxProps, Tooltip } from "@mui/material";
import { logger } from "@/core/logger";
import { Toast } from "@//components/core/Toast";
import { ConfirmCancelModal } from "@//components/Chat/components/ConfirmCancelModal";
import { Evaluation } from "@/contexts/WebSocketContext";
import LauraImage from "@/assets/images/Laura.jpg";

export function MessageCard({
  message,
  uploadingFiles,
  isLastMessage,
  isLoading,
  handleEvaluation,
}: {
  message: Message;
  uploadingFiles: { [fileId: string]: number };
  isLastMessage: boolean;
  isLoading?: boolean;
  handleEvaluation: (props: HandleEvaluation) => Promise<void>;
}) {
  const { newAssistentScreensEnabled } = useFeatureFlags();

  const handleCopy = async () => {
    const successMessage = "Texto copiado para a área de transferência.";
    const errorMessage = "Falha ao copiar texto. Por favor, tente copiar manualmente.";

    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(message.text.toString());
        Toast.success(successMessage);
        return;
      } catch (err) {
        if (err instanceof Object && "message" in err) {
          logger.error(`Error copying text to clipboard: ${err.message}`, err);
        }
      }
    }

    const textArea = document.createElement("textarea");
    textArea.value = message.text.toString();
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy") ? Toast.success(successMessage) : Toast.error(errorMessage);
    } catch (err) {
      Toast.error(errorMessage);
      if (err instanceof Object && "message" in err) {
        logger.error(`Fallback copy text command failed: ${err.message}`, err);
      }
    }
    document.body.removeChild(textArea);
  };

  const handleLike = async (evaluation?: Evaluation) => {
    if (evaluation === "THUMBS_UP") {
      handleEvaluation({ messageId: message.id, currentEvaluation: evaluation });
      return;
    }
    handleEvaluation({ messageId: message.id, newEvaluation: "THUMBS_UP", currentEvaluation: evaluation });
  };

  const handleDislike = async (evaluation?: Evaluation) => {
    if (evaluation === "THUMBS_DOWN") {
      handleEvaluation({ messageId: message.id, currentEvaluation: evaluation });
      return;
    }
    handleEvaluation({ messageId: message.id, newEvaluation: "THUMBS_DOWN", currentEvaluation: evaluation });
  };

  switch (message.type) {
    case "TEXT":
      return (
        <TextMessage
          message={message}
          active={isLastMessage}
          handleCopy={handleCopy}
          handleDislike={handleDislike}
          handleLike={handleLike}
          newAssistentScreensEnabled={newAssistentScreensEnabled}
        />
      );
    case "ACTION":
      return (
        <ActionMessage message={message} active={!isLoading} newAssistentScreensEnabled={newAssistentScreensEnabled} />
      );
    case "INITIAL":
      return (
        <ActionMessage
          message={message}
          active={isLastMessage && !isLoading}
          newAssistentScreensEnabled={newAssistentScreensEnabled}
        />
      );
    case "FILE":
      return (
        <FileMessage
          message={message}
          uploadingFiles={uploadingFiles}
          newAssistentScreensEnabled={newAssistentScreensEnabled}
        />
      );
    case "ERROR":
      return (
        <ErrorMessage
          message={message}
          active={isLastMessage}
          isLoading={isLoading}
          newAssistentScreensEnabled={newAssistentScreensEnabled}
        />
      );
    case "FLOW":
      return (
        <FlowMessage
          message={message}
          uploadingFiles={uploadingFiles}
          handleCopy={handleCopy}
          handleDislike={handleDislike}
          handleLike={handleLike}
          newAssistentScreensEnabled={newAssistentScreensEnabled}
        />
      );
    case "FEEDBACK":
      return <FeedbackMessage message={message} />;
    default:
      return <div style={{ flexShrink: 0 }}>NULL</div>;
  }
}

function TextMessage({
  message,
  handleCopy,
  handleLike,
  handleDislike,
  newAssistentScreensEnabled,
}: {
  message: TextMessageType;
  active: boolean;
  handleCopy: () => void;
  handleLike: (evaluation?: Evaluation) => void;
  handleDislike: (evaluation?: Evaluation) => void;
  newAssistentScreensEnabled: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>();
  const [isHovering, setIsHovering] = React.useState(false);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const isReceived = message.direction === "RECEIVED";
  const showCopyIcon = !!message.copyable;

  return (
    <Box
      id={message.id}
      ref={ref}
      sx={{
        maxWidth: "90%",
        pb: 2,
        alignSelf: getMessageAlignment(message),
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "certer",
          gap: "8px",
          justifyContent: "left",
          width: "100%",
        }}
      >
        {newAssistentScreensEnabled && <Avatar src={LauraImage} sx={{ width: "40px", height: "40px" }} />}
        <Typography
          color={"text.primary"}
          sx={{
            alignSelf: getMessageAlignment(message),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {newAssistentScreensEnabled ? "Laura - " : ""}
          {message.date.toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
      </Box>
      <Card
        sx={{
          mt: 1,
          backgroundColor: getMessageBackgroundColor(message),
          borderColor: getMessageBorderColor(message),
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 2,
          borderTopRightRadius: getBorderTopRightRadius(message),
          borderTopLeftRadius: getBorderTopLeftRadius(message),
        }}
      >
        <CardContent sx={{ paddingBottom: "16px!important" }}>
          <ContextMessage message={message} />
          <MessageText message={message} />
          {isHovering && isReceived ? (
            <MessageBottomActions
              handleCopy={handleCopy}
              showCopyIcon={showCopyIcon}
              handleLike={() => handleLike(message.evaluation)}
              handleDislike={() => handleDislike(message.evaluation)}
              evaluation={message.evaluation}
            />
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
}

function ActionMessage({
  message,
  active,
  newAssistentScreensEnabled,
}: {
  message: ActionMessageType;
  active: boolean;
  newAssistentScreensEnabled: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <Box
      id={message.id}
      ref={ref}
      sx={{
        maxWidth: "90%",
        pb: 2,
        alignSelf: getMessageAlignment(message),
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "certer",
          gap: "8px",
          justifyContent: "left",
          width: "100%",
        }}
      >
        {newAssistentScreensEnabled && <Avatar src={LauraImage} sx={{ width: "40px", height: "40px" }} />}
        <Typography
          color={"text.primary"}
          sx={{
            alignSelf: getMessageAlignment(message),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {newAssistentScreensEnabled ? "Laura - " : ""}
          {message.date.toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
      </Box>
      <Card
        sx={{
          mt: 1,
          backgroundColor: getMessageBackgroundColor(message),
          borderColor: getMessageBorderColor(message),
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 2,
          borderTopRightRadius: getBorderTopRightRadius(message),
          borderTopLeftRadius: getBorderTopLeftRadius(message),
        }}
      >
        <CardContent sx={{ paddingBottom: "16px!important" }}>
          <ContextMessage message={message} />
          <MessageText message={message} />
          <ActionsBox actions={message.actions} message={message} active={active} />
        </CardContent>
      </Card>
    </Box>
  );
}

function FileMessage({
  message,
  uploadingFiles,
  newAssistentScreensEnabled,
}: {
  message: FileMessageType;
  uploadingFiles: { [fileId: string]: number };
  newAssistentScreensEnabled: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <Box
      id={message.id}
      ref={ref}
      sx={{
        maxWidth: "90%",
        pb: 2,
        alignSelf: getMessageAlignment(message),
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "certer",
          gap: "8px",
          justifyContent: "left",
          width: "100%",
        }}
      >
        {newAssistentScreensEnabled && <Avatar src={LauraImage} sx={{ width: "40px", height: "40px" }} />}
        <Typography
          color={"text.primary"}
          sx={{
            alignSelf: getMessageAlignment(message),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {newAssistentScreensEnabled ? "Laura - " : ""}
          {message.date.toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
      </Box>

      <Card
        sx={{
          mt: 1,
          backgroundColor: getMessageBackgroundColor(message),
          borderColor: getMessageBorderColor(message),
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 2,
          borderTopRightRadius: getBorderTopRightRadius(message),
          borderTopLeftRadius: getBorderTopLeftRadius(message),
          minWidth: 360,
        }}
      >
        <CardContent sx={{ paddingBottom: "16px!important" }}>
          <ContextMessage message={message} />

          {message.text ? (
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <MessageText message={message} />
              <Divider sx={{ width: 140, alignSelf: "center", mt: 1, mb: 1 }} />
            </Box>
          ) : null}

          {message.files.map((file) => (
            <InFileMessage key={file.id} message={message} file={file} uploadingFiles={uploadingFiles} />
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}

function ErrorMessage({
  message,
  active,
  isLoading,
  newAssistentScreensEnabled,
}: {
  message: ErrorMessageType;
  active: boolean;
  isLoading?: boolean;
  newAssistentScreensEnabled: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>();

  const buttonStyle: SxProps = {
    color: "text.primary",
    borderColor: "text.primary",
    borderRadius: "20px",
    borderWidth: "1px",
    width: "100%",
    lineHeight: "20px",
    textTransform: "none",
    fontWeight: "normal",
  };

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <Box
      id={message.id}
      ref={ref}
      sx={{
        maxWidth: "90%",
        pb: 2,
        alignSelf: getMessageAlignment(message),
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "certer",
          gap: "8px",
          justifyContent: "left",
          width: "100%",
        }}
      >
        {newAssistentScreensEnabled && <Avatar src={LauraImage} sx={{ width: "40px", height: "40px" }} />}
        <Typography
          color={"text.primary"}
          sx={{
            alignSelf: getMessageAlignment(message),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {newAssistentScreensEnabled ? "Laura - " : ""}
          {message.date.toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
      </Box>

      <Card
        sx={{
          mt: 1,
          backgroundColor: getMessageBackgroundColor(message),
          borderColor: getMessageBorderColor(message),
          borderWidth: 1,
          borderRadius: 2,
          borderTopRightRadius: getBorderTopRightRadius(message),
          borderTopLeftRadius: getBorderTopLeftRadius(message),
        }}
      >
        <CardContent sx={{ paddingBottom: "16px!important" }}>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <WarningAmberRoundedIcon sx={{ color: "error.main" }} />

            <Box sx={{ ml: 1 }}>
              <MessageText message={message} />
            </Box>

            {active && !!message.retry && (
              <Button disabled={isLoading} sx={buttonStyle} onClick={message.retry} variant="outlined">
                Tentar Novamente
              </Button>
            )}
            {active && !!message.cancel && (
              <Button disabled={isLoading} sx={buttonStyle} onClick={message.cancel} variant="outlined">
                Cancelar
              </Button>
            )}
          </Box>
          <ActionsBox actions={message.actions} message={message} active={active} />
        </CardContent>
      </Card>
    </Box>
  );
}

function FlowMessage({
  message,
  uploadingFiles,
  handleCopy,
  handleLike,
  handleDislike,
  newAssistentScreensEnabled,
}: {
  message: FlowMessageType;
  uploadingFiles: { [fileId: string]: number };
  handleCopy: () => void;
  handleLike: (evaluation?: Evaluation) => void;
  handleDislike: (evaluation?: Evaluation) => void;
  newAssistentScreensEnabled: boolean;
}) {
  const ref = React.useRef<HTMLDivElement>();
  const [isHovering, setIsHovering] = React.useState(false);

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const isReceived = message.direction === "RECEIVED";
  const showCopyIcon = !Boolean(message.actionId);

  return (
    <Box
      id={message.id}
      ref={ref}
      sx={{
        maxWidth: "90%",
        pb: 2,
        alignSelf: getMessageAlignment(message),
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isReceived ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "certer",
            gap: "8px",
            justifyContent: "left",
            width: "100%",
          }}
        >
          {newAssistentScreensEnabled && <Avatar src={LauraImage} sx={{ width: "40px", height: "40px" }} />}
          <Typography
            color={"text.primary"}
            sx={{
              alignSelf: getMessageAlignment(message),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {newAssistentScreensEnabled ? "Laura - " : ""}
            {message.date.toLocaleString(DateTime.TIME_SIMPLE)}
          </Typography>
        </Box>
      ) : (
        <Typography
          variant="multiLineBody"
          color={"text.primary"}
          sx={{
            alignSelf: getMessageAlignment(message),
          }}
        >
          {message.date.toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
      )}

      <Card
        sx={{
          mt: 1,
          color: "text.primary",
          backgroundColor: getMessageBackgroundColor(message),
          borderColor: getMessageBorderColor(message),
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 2,
          borderTopRightRadius: getBorderTopRightRadius(message),
          borderTopLeftRadius: getBorderTopLeftRadius(message),
        }}
      >
        <CardContent sx={{ paddingBottom: "16px!important" }}>
          <Typography variant="multiLineBody">{message.text}</Typography>

          {isReceived && isHovering ? (
            <MessageBottomActions
              handleCopy={handleCopy}
              showCopyIcon={showCopyIcon}
              handleLike={() => handleLike(message.evaluation)}
              handleDislike={() => handleDislike(message.evaluation)}
              evaluation={message.evaluation}
            />
          ) : null}

          {!message.hideFiles && (
            <>
              {message.files.length ? (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Divider sx={{ width: 140, alignSelf: "center", mt: 2, mb: 1 }} />
                </Box>
              ) : null}

              {message.files.map((file) => (
                <InFileMessage key={file.id} message={message} file={file} uploadingFiles={uploadingFiles} />
              ))}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

function FeedbackMessage({ message }: { message: FeedbackMessageType }) {
  const ref = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  return (
    <Box
      id={message.id}
      ref={ref}
      sx={{
        maxWidth: "90%",
        pb: 2,
        alignSelf: getMessageAlignment(message),
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
      }}
    >
      <Typography
        variant="multiLineBody"
        color={"text.primary"}
        sx={{
          alignSelf: getMessageAlignment(message),
        }}
      >
        {message.date.toLocaleString(DateTime.TIME_SIMPLE)}
      </Typography>

      <Card
        sx={{
          mt: 1,
          backgroundColor: getMessageBackgroundColor(message),
          borderColor: getMessageBorderColor(message),
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: 2,
          borderTopRightRadius: getBorderTopRightRadius(message),
          borderTopLeftRadius: getBorderTopLeftRadius(message),
        }}
      >
        <CardContent sx={{ paddingBottom: "16px!important" }}>
          <MessageText message={message} />
        </CardContent>
      </Card>
    </Box>
  );
}

const parseMarkdown = (text: string) => {
  const regex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
  const parts: (JSX.Element | string)[] = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }
    parts.push(
      <a
        key={match[2]}
        href={match[2]}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit", textDecoration: "underline" }}
      >
        {match[1]}
      </a>
    );
    lastIndex = regex.lastIndex;
  }
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return parts;
};

function MessageText(props: { message: Message }) {
  const { message } = props;

  const commonStyles = {
    variant: "multiLineBody" as const,
    color: "text.primary",
    sx: { display: "block" },
  };

  return typeof message.text === "string" && message.direction === "RECEIVED" ? (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {message.text.split("\n").map((line, index) => (
        <Typography key={index} {...commonStyles}>
          {parseMarkdown(line)}
        </Typography>
      ))}
    </Box>
  ) : typeof message.text === "string" ? (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {message.text.split("\n").map((line, index) => (
        <Typography key={index} {...commonStyles}>
          {line}
        </Typography>
      ))}
    </Box>
  ) : (
    <Typography {...commonStyles}>{message.text}</Typography>
  );
}

function ContextMessage({ message }: { message: Message }) {
  if (!message.context) return null;

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: "text.secondary",
        p: 1,
        mb: 1,
        maxHeight: 90,
        overflowY: "auto",
      }}
    >
      <Typography variant="multiLineBody" color="text.secondary">
        {message.context}
      </Typography>
    </Box>
  );
}

function ActionsBox(props: { actions: ActionType[]; message: Message; active: boolean }) {
  const { actions, message, active } = props;

  if (!actions.length) return null;
  const { newActionCardWithCreditAmountEnabled } = useFeatureFlags();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 2, gap: 1 }}>
      {actions.map((action, index) => (
        <ActionListItem
          key={index}
          action={action}
          message={message}
          active={active}
          showCredits={newActionCardWithCreditAmountEnabled}
        />
      ))}
    </Box>
  );
}

function ActionListItem(props: { action: ActionType; message: Message; active: boolean; showCredits?: boolean }) {
  const { action, message, active, showCredits } = props;

  const { documentCreationText } = useMessagesContext();

  const disabled = React.useMemo(() => {
    return action.disabled || !active;
  }, [action, active]);

  const handleClick = React.useCallback(() => {
    if (disabled) return;
    action.onClick(message);
  }, [action, disabled, message]);

  if (action.hidden) return null;

  const isDocumentCreation = action.text === documentCreationText;
  const creditAmount = action.id ? actionCreditMap[action.id] : 0;

  const isPrimaryAction = isDocumentCreation || creditAmount > 0;

  return (
    <Button
      onClick={handleClick}
      hidden={action.hidden}
      disabled={disabled}
      variant="outlined"
      sx={{
        justifyContent: "space-between",
        textAlign: "left",
        bgcolor: "common.white",
        "&:hover": {
          backgroundColor: "common.white",
        },
      }}
      fullWidth
    >
      <Box>
        {action.text}

        {!showCredits
          ? ""
          : isDocumentCreation
            ? "Clique para verificar o valor da peça específica"
            : creditAmount > 0
              ? `${creditAmount} CRÉDITOS`
              : ""}
      </Box>
      {isPrimaryAction && <ChevronRightIcon sx={{ fontSize: 32 }} />}
    </Button>
  );
}

function InFileMessage(props: {
  file: InMessageFileType;
  message: Message;

  uploadingFiles: { [fileId: string]: number };
}) {
  const { file, message, uploadingFiles } = props;

  if (file.type === "UPLOADING") {
    return <UploadingFile file={file} message={message} progress={uploadingFiles[file.id]} />;
  } else if (file.type === "UPLOADED") {
    return <UploadedFile file={file} message={message} />;
  }
  return <ErrorFile file={file} message={message} />;
}

function UploadingFile(props: { file: UploadingFileType; message: Message; progress: number }) {
  const { file, message, progress: propProgress } = props;
  const [progress, setProgress] = React.useState<number>(propProgress);

  useEffect(() => {
    if (propProgress) {
      setProgress(propProgress);
    }
  }, [propProgress]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 2,
        }}
      >
        <TextSnippetOutlinedIcon
          fontSize="small"
          sx={{
            color: "text.primary",
          }}
        />

        <Typography
          color={"text.primary"}
          variant="body"
          sx={{
            ml: 1,
          }}
        >
          {file.file.name}
        </Typography>
      </Box>

      <Box
        sx={{
          pb: 2,
          pl: 2,
          pr: 2,
        }}
      >
        <LinearProgress variant="determinate" value={progress || 0} />
      </Box>
    </Box>
  );
}

function UploadedFile(props: { file: UploadedFileType; message: Message }) {
  const { file, message } = props;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 2,
        }}
      >
        <TextSnippetOutlinedIcon
          fontSize="small"
          sx={{
            color: "text.primary",
          }}
        />

        <Typography
          color={"text.primary"}
          variant="body"
          sx={{
            ml: 1,
          }}
        >
          {file.name}
        </Typography>
      </Box>
    </Box>
  );
}

function ErrorFile(props: { file: ErrorFileType; message: Message }) {
  const { file, message } = props;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 2,
        }}
      >
        <WarningAmberRoundedIcon
          fontSize="small"
          sx={{
            color: "error.main",
          }}
        />

        <Typography
          color={"text.primary"}
          variant="body"
          sx={{
            ml: 1,
            color: "error.main",
          }}
          title={file.error}
        >
          {file.name}
        </Typography>
      </Box>
    </Box>
  );
}

interface LoadingMessageProps {
  text?: string;
  waitingForResponse?: WaitingForResponse;
}

export function LoadingMessage({ waitingForResponse, text }: LoadingMessageProps) {
  const ref = React.useRef<HTMLDivElement>();
  const [isCancelModalOpen, setCancelModalOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const flags = useFeatureFlags();

  const { cancelOngoingExecution } = useMessagesContext();

  const handleOpenCancelModal = () => {
    setCancelModalOpen(true);
  };

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
  };

  const handleCancelOngoingExecution = async () => {
    setIsLoading(true);
    await cancelOngoingExecution();
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const textToShow = useMemo(() => {
    if (!waitingForResponse && !text) return null;

    if (text) return text;

    switch (waitingForResponse?.type) {
      case WaitingForResponseType.CREATE_MOTION:
        return "Gerando a peça solicitada. Esse processo pode levar até 5 minutos.";
      case WaitingForResponseType.CREATE_DOCUMENT:
        return "Gerando o documento solicitado. Esse processo pode levar alguns minutos.";
      case WaitingForResponseType.CREATE_SUMMARY:
        return "Gerando o resumo solicitado. Esse processo pode levar alguns minutos.";
      case WaitingForResponseType.INTERACTION_WITH_CHAT:
        return "Só um momento, já estou trabalhando na resposta de sua interação.";
      case WaitingForResponseType.GENERIC:
        return "Ok! Estamos executando sua solicitação, isso pode levar alguns segundos.";
      case WaitingForResponseType.LEGAL_QUESTION:
        return "Só um momento, já estou trabalhando na resposta para a sua dúvida jurídica.";
      case WaitingForResponseType.DOCUMENT_UPLOAD:
        return "Fazendo upload do seu documento. Isso pode levar alguns instantes.";
      case WaitingForResponseType.CONTRACT:
        return "Elaborando contrato.";
      default:
        return null;
    }
  }, [waitingForResponse, text, flags]);

  const cancelModalDescription =
    waitingForResponse?.type === WaitingForResponseType.LEGAL_QUESTION
      ? "A resposta para sua dúvida jurídica ainda está sendo gerada. Tem certeza que deseja cancelar?"
      : "Seu documento ainda está sendo processado. Tem certeza que deseja cancelar?";

  return (
    <>
      <Box
        ref={ref}
        sx={{
          maxWidth: "90%",
          alignSelf: "flex-start",
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          pb: 2,
        }}
      >
        <Card
          sx={{
            mt: 1,
            backgroundColor: "orange.light.20",
            borderRadius: 2,
            borderTopLeftRadius: 0,
            border: "1px solid",
            borderColor: "orange.light.70",
          }}
        >
          <CardContent
            sx={{
              paddingBottom: "16px!important",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            {textToShow && (
              <Typography variant="multiLineBody" color="common.black">
                {textToShow}
              </Typography>
            )}

            <CircularProgress
              size="24px"
              sx={{
                color: "common.coral",
              }}
            />
            {waitingForResponse?.executionId && (
              <Button
                onClick={handleOpenCancelModal}
                color="secondary"
                variant="outlined"
                sx={{
                  mt: 2,
                  color: "common.coral",
                  borderColor: "common.coral",
                  borderRadius: "20px",
                  borderWidth: "1px",
                  padding: "4px 12px",
                  minWidth: "200px",
                  height: "30px",
                  textTransform: "none",
                  fontWeight: "normal",
                  "&:hover": {
                    backgroundColor: "common.coral",
                    color: "common.white",
                  },
                }}
              >
                Cancelar
              </Button>
            )}
          </CardContent>
        </Card>
      </Box>
      <ConfirmCancelModal
        isOpen={isCancelModalOpen}
        onConfirm={handleCancelOngoingExecution}
        onCancel={handleCloseCancelModal}
        isLoading={isLoading}
        cancelModalDescription={cancelModalDescription}
      />
    </>
  );
}

function getMessageBackgroundColor(message: Message): string {
  return message.direction === "SENT" ? "grey.50" : "secondary.light";
}

function getMessageBorderColor(message: Message): string | undefined {
  return message.direction === "RECEIVED" ? "primary.main" : "transparent";
}

function getMessageAlignment(message: Message): "flex-start" | "flex-end" {
  return message.direction === "SENT" ? "flex-end" : "flex-start";
}

function getBorderTopRightRadius(message: Message): number {
  return message.direction === "SENT" ? 0 : 8;
}

function getBorderTopLeftRadius(message: Message): number {
  return message.direction === "SENT" ? 8 : 0;
}

const MessageBottomActions = ({
  showCopyIcon,
  handleCopy,
  handleLike,
  handleDislike,
  evaluation,
}: {
  showCopyIcon: boolean;
  handleCopy: () => void;
  handleLike: () => void;
  handleDislike: () => void;
  evaluation?: Evaluation;
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
      {showCopyIcon && (
        <Tooltip title="Copiar conteúdo">
          <IconButton onClick={handleCopy} size="small">
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Like">
        <IconButton
          onClick={handleLike}
          sx={{ color: evaluation === "THUMBS_UP" ? "success.main" : undefined }}
          size="small"
        >
          <ThumbUpOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Dislike">
        <IconButton
          onClick={handleDislike}
          sx={{ color: evaluation === "THUMBS_DOWN" ? "error.main" : undefined }}
          size="small"
        >
          <ThumbDownOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
