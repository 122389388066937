import React from "react";
import { Box, TextField } from "@mui/material";

export const CustomCasesListToolbar = ({
  disabled,
  onSearch,
}: {
  disabled: boolean;
  onSearch: (text: string) => void;
}) => {
  const [searchValue, setSearchValue] = React.useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const newValue = event.target.value;
    setSearchValue(newValue);
    onSearch(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 2,
        py: 1,
      }}
    >
      <TextField
        sx={{
          flexGrow: 2,
          maxWidth: "500px",
          mt: 1,
        }}
        placeholder="Pesquisar por nome do caso, cliente ou número do processo"
        fullWidth
        value={searchValue}
        variant="outlined"
        label="Pesquisar"
        disabled={disabled}
        onChange={handleSearch}
      />
      {/* <GridToolbarFilterButton
        slotProps={{
          button: {
            startIcon: null,
            endIcon: <FilterList />,
            size: "small",
            disabled: disabled,
          },
        }}
      /> */}
    </Box>
  );
};
