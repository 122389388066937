import { applyMaskFunctionFactory } from "./applyMaskFunctionFactory";

const maskCnj = (input: string) => {
  if (!input) return "";
  const cleanInput = input.replace(/\D/g, "");

  return cleanInput
    .replace(/^(\d{7})(\d{2})/, "$1-$2")
    .replace(/^(\d{7}-\d{2})(\d{4})/, "$1.$2")
    .replace(/^(\d{7}-\d{2}\.\d{4})(\d{1})/, "$1.$2")
    .replace(/^(\d{7}-\d{2}\.\d{4}\.\d{1})(\d{2})/, "$1.$2")
    .replace(/^(\d{7}-\d{2}\.\d{4}\.\d{1}\.\d{2})(\d{4})/, "$1.$2");
};

export const applyCnjMask = applyMaskFunctionFactory(maskCnj);
