import { Close } from "@mui/icons-material";
import { AppBar, IconButton, Dialog, Toolbar, Typography, DialogContent, SxProps, Theme } from "@mui/material";
import React from "react";

type FullScreenModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export const FullScreenModal = ({ open, onClose, children, title, sx }: FullScreenModalProps) => {
  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <AppBar position="relative" color="transparent" sx={{ borderBottom: "1px solid", borderColor: "divider", ...sx }}>
        <Toolbar>
          <Typography sx={{ flex: 1 }} variant="h6" fontWeight={400}>
            {title}
          </Typography>
          <IconButton edge="start" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent sx={sx}>{children}</DialogContent>
    </Dialog>
  );
};
