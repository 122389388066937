import React, { useState } from "react";

import { DeleteOutline } from "@mui/icons-material";
import {
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import { OABType } from "@/services/legalProceeding/types";
import { useDeleteOab } from "@/hooks/oabs/useDeleteOab";
import { useAuthContext } from "@/contexts/AuthContext";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { Button } from "@/components/Button";
import { normalizedOABToDisplay } from "@/utils/normalizeOab";

type DeleteOabButtonProps = {
  oab: OABType;
};

export const DeleteOabButton = ({ oab }: DeleteOabButtonProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <DeleteOabModal open={open} onClose={handleClose} oab={oab} />
      <IconButton onClick={handleOpen}>
        <DeleteOutline sx={{ color: "common.mediumShade" }} fontSize="small" />
      </IconButton>
    </>
  );
};

interface DeleteOabModalProps {
  open: boolean;
  onClose: () => void;
  oab: OABType;
}

const DeleteOabModal = ({ open, onClose, oab }: DeleteOabModalProps) => {
  const { mutateAsync: deleteOab, isPending: isDeleting } = useDeleteOab();
  const { user } = useAuthContext();

  const handleDelete = async () => {
    try {
      if (user) {
        await deleteOab({ userId: user.userId, oabId: oab.id });
      }
    } catch (error) {
      WebToast.error("Erro ao excluir OAB. Tente novamente mais tarde.");
      logger.error(JSON.stringify(error));
    } finally {
      onClose();
    }
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: 400 }}>
        Você deseja excluir a inscrição {normalizedOABToDisplay(oab.oab, oab.uf).toUpperCase()}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Ao excluir uma inscrição OAB vinculada, os processos em que o advogado é titular deixarão de ser
            monitorados. No entanto, fique tranquilo: o caso não será excluído permanentemente.
          </Typography>
          <Typography variant="body1">Deseja prosseguir com a exclusão?</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose} disabled={isDeleting}>
          Cancelar
        </Button>
        <Button
          startIcon={DeleteOutline}
          onClick={handleDelete}
          isLoading={isDeleting}
          variant="contained"
          loaderStyle={{ width: "80px" }}
        >
          Excluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
