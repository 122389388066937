import { LegalProceedingService } from "@/services/legalProceeding";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { courtCredentialsQueryKey } from "../useCourtCredentials";
import { CourtCredentialType } from "@/services/legalProceeding/types";

export const useRevokeCourtCredential = () => {
  const api = LegalProceedingService;
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["revokeCourtCredential"],
    mutationFn: async ({ userId, credentialId }: { userId: string; credentialId: string }) =>
      await api.revokeCourtCredentials(userId, credentialId),
    onMutate: async ({ credentialId }) => {
      await queryClient.cancelQueries({ queryKey: courtCredentialsQueryKey });
      const previousCredentials = queryClient.getQueryData(courtCredentialsQueryKey);

      queryClient.setQueryData(courtCredentialsQueryKey, (old: CourtCredentialType[] | undefined) => {
        if (!old) return [];
        return old.map((credential) =>
          credential.id === credentialId ? { ...credential, status: "INACTIVE" } : credential
        );
      });

      return { previousCredentials };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(courtCredentialsQueryKey, context?.previousCredentials);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: courtCredentialsQueryKey });
    },
  });
};
