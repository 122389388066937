import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/Button";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { useAuthContext } from "@/contexts/AuthContext";
import { useImportLegalProceedingFromOAB } from "@/hooks/legalProceedings/useImportLegalProceedingFromOAB";
import { normalizeOAB } from "@/utils/normalizeOab";

const oabSchema = z.object({
  oab: z
    .string()
    .regex(/^\d{1,7}\/[a-zA-Z]{2}$/, {
      message: "O número da OAB deve estar no formato 0000000/UF",
    })
    .refine(
      (oab) => {
        const parts = oab.split("/");
        return parts[1] ? ufs.map((uf) => uf.value).includes(parts[1].toLowerCase()) : false;
      },
      {
        message: "UF inválida",
      }
    ),
});

type ImportSingleOabModalProps = {
  open: boolean;
  onClose: () => void;
  handleOpenImportingByOAB: () => void;
};

export const ImportSingleOabModal = ({ open, onClose, handleOpenImportingByOAB }: ImportSingleOabModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(oabSchema),
    defaultValues: { oab: "" },
  });

  const { user } = useAuthContext();
  const { mutateAsync: importLegalProceedingFromOAB, isPending: isImporting } = useImportLegalProceedingFromOAB();

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data: { oab: string }) => {
    try {
      await importLegalProceedingFromOAB({
        userId: user!.userId,
        oabs: [normalizeOAB(data.oab)],
      });
      handleOpenImportingByOAB();
      handleClose();
    } catch (err) {
      logger.error(`Error importing legal proceeding from OAB: ${JSON.stringify(err)}`);
      WebToast.error("Erro ao importar processo por OAB. Tente novamente mais tarde.");
    }
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose} fullWidth>
      <Box sx={{ pt: 1 }}>
        <DialogTitle>
          <Typography variant="h6" fontWeight="400" component="span">
            Importar processo por OAB
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              sx={{ mt: 2, width: "100%" }}
              label="Inscrição OAB"
              placeholder="0000000/UF"
              {...register("oab")}
              error={!!errors.oab}
              helperText={errors.oab?.message}
              FormHelperTextProps={{
                sx: {
                  fontSize: 12,
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            pb: 3,
          }}
        >
          <Button type="button" onClick={handleClose} disabled={isImporting}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            isLoading={isImporting}
            loaderStyle={{ width: "85px" }}
          >
            Continuar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export const ufs = [
  { value: "ac", label: "AC" },
  { value: "al", label: "AL" },
  { value: "ap", label: "AP" },
  { value: "am", label: "AM" },
  { value: "ba", label: "BA" },
  { value: "ce", label: "CE" },
  { value: "df", label: "DF" },
  { value: "es", label: "ES" },
  { value: "go", label: "GO" },
  { value: "ma", label: "MA" },
  { value: "mt", label: "MT" },
  { value: "ms", label: "MS" },
  { value: "mg", label: "MG" },
  { value: "pa", label: "PA" },
  { value: "pb", label: "PB" },
  { value: "pr", label: "PR" },
  { value: "pe", label: "PE" },
  { value: "pi", label: "PI" },
  { value: "rj", label: "RJ" },
  { value: "rn", label: "RN" },
  { value: "rs", label: "RS" },
  { value: "ro", label: "RO" },
  { value: "rr", label: "RR" },
  { value: "sc", label: "SC" },
  { value: "sp", label: "SP" },
  { value: "se", label: "SE" },
  { value: "to", label: "TO" },
];
