import { SkillGroup, SkillGroupShortcut } from "@/core/skillGroups/types";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import React, { useMemo } from "react";

import { ChevronRight as ChevronRightIcon, LockClockOutlined } from "@mui/icons-material";
import { SKILL_FORM_MAX_WIDTH } from "@/constants/layout";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { SkillId } from "@/core/skills/types";
import { SearchSkillsBar } from "../SkillGroupView/components/SearchSkillsBar";
type NewSkillGroupSkillProps = {
  skillGroup: SkillGroup;
  onSelectSkill: (props: { skillId: SkillId; customTitle?: string; defaultData?: Record<string, string> }) => void;
  withSearch?: boolean;
};

export const NewSkillGroupView = ({ skillGroup, onSelectSkill, withSearch = false }: NewSkillGroupSkillProps) => {
  const { isOnboarding } = useDocumentOnboarding();
  const flags = useFeatureFlags();

  const groupedShortcuts = useMemo(() => {
    const shortcuts = skillGroup.skillShortcuts;
    return shortcuts.reduce(
      (acc, shortcut) => {
        const category = shortcut.category || "Outros";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(shortcut);
        return acc;
      },
      {} as Record<string, SkillGroupShortcut[]>
    );
  }, [skillGroup.skillShortcuts]);

  const isSkillEnabled = (skillId: string) => {
    if (!isOnboarding) return true;
    return skillGroup.onboarding?.enabledSkills?.includes(skillId) ?? false;
  };

  return (
    <Box sx={{ my: 2, px: 4, width: "100%" }}>
      <Box
        sx={{
          mx: "auto",
          maxWidth: SKILL_FORM_MAX_WIDTH,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          gap: 6,
        }}
      >
        <Box sx={{ mx: -2, display: "flex", flexDirection: "column", gap: 2 }}>
          {withSearch && (
            <Box sx={{ display: "flex", flexDirection: "column", my: 3 }}>
              <SearchSkillsBar onSelectSkill={onSelectSkill} />
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {Object.entries(groupedShortcuts).map(([category, shortcuts]) => (
              <Box key={category} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {category !== "Outros" && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontSize: "12px",
                      letterSpacing: "0.03em",
                      lineHeight: "19.92px",
                      fontWeight: 700,
                    }}
                  >
                    {category}
                  </Typography>
                )}
                {shortcuts.map((skillShortcut) => (
                  <SkillGroupShortcutButton
                    hidden={skillShortcut.flag && !flags[skillShortcut.flag]}
                    key={skillShortcut.skillId}
                    skillShortcut={skillShortcut}
                    disabled={!isSkillEnabled(skillShortcut.skillId)}
                    onClick={() => onSelectSkill({ skillId: skillShortcut.skillId })}
                  />
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SkillGroupShortcutButton = ({
  skillShortcut,
  onClick,
  disabled,
  hidden,
}: {
  skillShortcut: SkillGroupShortcut;
  onClick: () => void;
  disabled?: boolean;
  hidden?: boolean;
}) => {
  if (hidden) return null;
  return (
    <Tooltip
      placement="right"
      title={disabled ? "Essa opção será liberada após experimentar a criação gratuita de peças." : ""}
    >
      <span>
        <Button
          sx={{
            justifyContent: "space-between",
            px: 2,
            py: 2,
            color: disabled ? "text.disabled" : "text.primary",
            textAlign: "left",
          }}
          endIcon={disabled ? <LockClockOutlined /> : <ChevronRightIcon />}
          onClick={onClick}
          fullWidth
          disabled={disabled}
        >
          <Typography
            variant="body1"
            sx={{
              color: disabled ? "text.disabled" : "text.primary",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0.15px",
            }}
          >
            {skillShortcut.label}
          </Typography>
        </Button>
      </span>
    </Tooltip>
  );
};
