import { LexZapChat } from "@/hooks/lexZap/types";
import { TemplateKey } from "@/services/lexZap/types/templateMessage.types";
import { DateTime } from "luxon";

export const getTemplateToUse = ({ chat }: { chat?: LexZapChat }): TemplateKey | null => {
  if (!chat) return null;

  if (!chat.lastEndClientMessageDate) {
    return TemplateKey.InitChat;
  }

  const lastClientMessageTime = DateTime.fromMillis(chat.lastEndClientMessageDate);

  const hoursFromLastClientMessage = DateTime.now().diff(lastClientMessageTime, "hours").hours;

  const moreThan24HoursSinceLastMessage = hoursFromLastClientMessage >= 23; // gordura de 1h

  if (moreThan24HoursSinceLastMessage) {
    return TemplateKey.ReactivateChat;
  } else {
    return null;
  }
};
