export const tjNameToFullName = {
  STF: "Superior Tribunal Federal",
  CNJ: "Conselho Nacional de Justiça",
  STJ: "Superior Tribunal de Justiça",
  CJF: "Conselho da Justiça Federal",
  TRF1: "Tribunal da Primeira Região",
  TRF2: "Tribunal da Segunda Região",
  TRF3: "Tribunal da Terceira Região",
  TRF4: "Tribunal da Quarta Região",
  TRF5: "Tribunal da Quinta Região",
  TST: "Tribunal Superior do Trabalho",
  CSJT: "Conselho Superior da Justiça do Trabalho",
  TRT1: "TRT da Primeira Região",
  TRT2: "TRT da Segunda Região",
  TRT3: "TRT da Terceira Região",
  TRT4: "TRT da Quarta Região",
  TRT5: "TRT da Quinta Região",
  TRT6: "TRT da Sexta Região",
  TRT7: "TRT da Sétima Região",
  TRT8: "TRT da Oitava Região",
  TRT9: "TRT da Nona Região",
  TRT10: "TRT da Décima Região",
  TRT11: "TRT da Décima Primeira Região",
  TRT12: "TRT da Décima Segunda Região",
  TRT13: "TRT da Décima Terceira Região",
  TRT14: "TRT da Décima Quarta Região",
  TRT15: "TRT da Décima Quinta Região",
  TRT16: "TRT da Décima Sexta Região",
  TRT17: "TRT da Décima Sétima Região",
  TRT18: "TRT da Décima Oitava Região",
  TRT19: "TRT da Décima Nona Região",
  TRT20: "TRT da Vigésima Região",
  TRT21: "TRT da Vigésima Primeira Região",
  TRT22: "TRT da Vigésima Segunda Região",
  TRT23: "TRT da Vigésima Terceira Região",
  TRT24: "TRT da Vigésima Quarta Região",
  TSE: "Tribunal Superior Eleitoral",
  "TRE-AC": "Tribunal Regional Eleitoral do Acre",
  "TRE-AL": "Tribunal Regional Eleitoral do Alagoas",
  "TRE-AP": "Tribunal Regional Eleitoral do Amapá",
  "TRE-AM": "Tribunal Regional Eleitoral do Amazonas",
  "TRE-BA": "Tribunal Regional Eleitoral da Bahia",
  "TRE-CE": "Tribunal Regional Eleitoral do Ceará",
  "TRE-DF": "Tribunal Regional Eleitoral do Distrito Federal",
  "TRE-ES": "Tribunal Regional Eleitoral do Espírito Santo",
  "TRE-GO": "Tribunal Regional Eleitoral de Goiás",
  "TRE-MA": "Tribunal Regional Eleitoral do Maranhão",
  "TRE-MT": "Tribunal Regional Eleitoral do Mato Grosso",
  "TRE-MS": "Tribunal Regional Eleitoral do Mato Grosso do Sul",
  "TRE-MG": "Tribunal Regional Eleitoral de Minas Gerais",
  "TRE-PA": "Tribunal Regional Eleitoral do Pará",
  "TRE-PB": "Tribunal Regional Eleitoral da Paraíba",
  "TRE-PR": "Tribunal Regional Eleitoral do Paraná",
  "TRE-PE": "Tribunal Regional Eleitoral de Pernambuco",
  "TRE-PI": "Tribunal Regional Eleitoral do Piauí",
  "TRE-RJ": "Tribunal Regional Eleitoral do Rio de Janeiro",
  "TRE-RN": "Tribunal Regional Eleitoral do Rio Grande do Norte",
  "TRE-RS": "Tribunal Regional Eleitoral do Rio Grande do Sul",
  "TRE-RO": "Tribunal Regional Eleitoral de Rondônia",
  "TRE-RR": "Tribunal Regional Eleitoral de Roraima",
  "TRE-SC": "Tribunal Regional Eleitoral de Santa Catarina",
  "TRE-SE": "Tribunal Regional Eleitoral de Sergipe",
  "TRE-SP": "Tribunal Regional Eleitoral de São Paulo",
  "TRE-TO": "Tribunal Regional Eleitoral do Tocantins",
  STM: "Superior Tribunal Militar",
  CJM1: "Primeira Circunscrição Judiciária Militar",
  CJM2: "Segunda Circunscrição Judiciária Militar",
  CJM3: "Terceira Circunscrição Judiciária Militar",
  CJM4: "Quarta Circunscrição Judiciária Militar",
  CJM5: "Quinta Circunscrição Judiciária Militar",
  CJM6: "Sexta Circunscrição Judiciária Militar",
  CJM7: "Sétima Circunscrição Judiciária Militar",
  CJM8: "Oitava Circunscrição Judiciária Militar",
  CJM9: "Nona Circunscrição Judiciária Militar",
  CJM10: "Décima Circunscrição Judiciária Militar",
  CJM11: "Décima Primeira Circunscrição Judiciária Militar",
  CJM12: "Décima Segunda Circunscrição Judiciária Militar",
  TJAC: "Tribunal Estadual do Acre",
  TJAL: "Tribunal Estadual do Alagoas",
  TJAP: "Tribunal Estadual do Amapá",
  TJAM: "Tribunal Estadual do Amazonas",
  TJBA: "Tribunal Estadual da Bahia",
  TJCE: "Tribunal Estadual do Ceará",
  TJDF: "Tribunal Estadual do Distrito Federal",
  TJES: "Tribunal Estadual do Espírito Santo",
  TJGO: "Tribunal Estadual de Goiás",
  TJMA: "Tribunal Estadual do Maranhão",
  TJMT: "Tribunal Estadual do Mato Grosso",
  TJMS: "Tribunal Estadual do Mato Grosso do Sul",
  TJMG: "Tribunal Estadual de Minas Gerais",
  TJPA: "Tribunal Estadual do Pará",
  TJPB: "Tribunal Estadual da Paraíba",
  TJPR: "Tribunal Estadual do Paraná",
  TJPE: "Tribunal Estadual de Pernambuco",
  TJPI: "Tribunal Estadual do Piauí",
  TJRJ: "Tribunal Estadual do Rio de Janeiro",
  TJRN: "Tribunal Estadual do Rio Grande do Norte",
  TJRS: "Tribunal Estadual do Rio Grande do Sul",
  TJRO: "Tribunal Estadual de Rondônia",
  TJRR: "Tribunal Estadual de Roraima",
  TJSC: "Tribunal Estadual de Santa Catarina",
  TJSP: "Tribunal de Justiça de São Paulo",
  TJSE: "Tribunal Estadual de Sergipe",
  "TJM-MG": "Tribunal de Justiça Militar do Estado de Minas Gerais",
  "TJM-RS": "Tribunal de Justiça Militar do Estado do Rio Grande do Sul",
  "TJM-SP": "Tribunal de Justiça Militar do Estado de São Paulo",
};
