import { Tooltip } from "@mui/material";
import { Case } from "@/hooks/lexZap/types";
import { useAuthContext } from "@/contexts/AuthContext";
import { CNJ } from "@/core/CNJ";
import { useCourtCredentials } from "@/hooks/courtCredentials/useCourtCredentials";
import { AuthorizeAccessModal } from "@/pages/LegalProceedingMonitoring/components/AuthorizeAccessModal";
import { CourtCredentialType } from "@/services/legalProceeding/types";
import { applyCnjMask } from "@/utils/applyInputMask/cnjMask";
import { zodResolver } from "@hookform/resolvers/zod";
import { Search } from "@mui/icons-material";
import { Box, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { WebToast, WebToastColoredWithTitle } from "../core/Toast";
import { z } from "zod";
import { Checkbox } from "../Checkbox";
import { Button } from "../Button";
import { isJudicialProcess } from "validation-br";
import { useLegalProceedingToCaseMutation } from "@/hooks/legalProceedings/useLegalProceedingToCaseMutation";
import { useAttachLegalProceedingToCaseMutation } from "@/hooks/legalProceedings/useAttachLegalProceedingToCaseMutation";

const cnjPayloadSchema = z.object({
  cnj: z
    .string()
    .max(25, { message: "Máximo de 25 caracteres" })
    .refine(
      (value) => {
        try {
          return isJudicialProcess(value);
        } catch {
          return false;
        }
      },
      { message: "CNJ inválido" }
    ),
  isSecret: z.boolean().optional(),
});

type CnjPayload = z.infer<typeof cnjPayloadSchema>;

interface LegalProceedingImportByCNJProps {
  caseData?: Case;
  onSuccess?: () => void;
}

export const LegalProceedingImportByCNJ = ({ caseData, onSuccess }: LegalProceedingImportByCNJProps) => {
  const { user } = useAuthContext();
  const { mutateAsync: attachLegalProceedingToCase, isPending } = useAttachLegalProceedingToCaseMutation();
  const { mutateAsync: legalProceedingToCase, isPending: isPendingLegalProceedingToCase } =
    useLegalProceedingToCaseMutation();
  const { data: courtCredentials, isLoading: isLoadingCourtCredentials } = useCourtCredentials({
    userId: user?.userId,
  });
  const [openAuthorizeAccessModal, setOpenAuthorizeAccessModal] = React.useState(false);
  const [tj, setTj] = React.useState<{
    courtCredential: CourtCredentialType | null;
    needAdd: boolean;
  } | null>(null);
  const [disabled, setDisabled] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<CnjPayload>({
    defaultValues: {
      cnj: "",
      isSecret: false,
    },
    mode: "onChange",
    resolver: zodResolver(cnjPayloadSchema),
  });

  const onSubmit = handleSubmit(async (submittedData) => {
    const { cnj, isSecret } = submittedData;

    if (isSecret) {
      await handleSecretLegalProceeding(cnj);
      return;
    }

    await handleAttachOrImportLegalProceeding();
  });

  const attachLegalProceeding = async () => {
    try {
      const { cnj } = getValues();
      setDisabled(true);
      await attachLegalProceedingToCase({ cnj, caseId: caseData!.id });
      WebToastColoredWithTitle.success("Processo vinculado com sucesso");
      onSuccess?.();
    } catch (error) {
      WebToastColoredWithTitle.error("Erro ao vincular processo", "Ocorreu um erro ao vincular o processo");
    } finally {
      setDisabled(false);
    }
  };

  const handleSecretLegalProceeding = async (cnj: string) => {
    const cnjOrError = CNJ.create(cnj);
    if (cnjOrError.isFailure) {
      WebToast.error("Número de processo inválido");
      return;
    }

    const cnjObject = cnjOrError.getValue();
    const tjAcronym = cnjObject.getTj();
    if (!tjAcronym) {
      WebToast.error("Tribunal não encontrado");
      return;
    }

    const courtCredentialOrNull = courtCredentials?.find((credential) => credential.tj === tjAcronym);
    if (courtCredentialOrNull) {
      if (courtCredentialOrNull.status === "INACTIVE") {
        setTj({
          courtCredential: courtCredentialOrNull,
          needAdd: false,
        });
        setOpenAuthorizeAccessModal(true);
      } else {
        await handleAttachOrImportLegalProceeding();
      }
    } else {
      setTj({
        courtCredential: {
          id: tjAcronym,
          createdAt: new Date(),
          updatedAt: new Date(),
          status: "INACTIVE",
          tj: tjAcronym,
        },
        needAdd: true,
      });
      setOpenAuthorizeAccessModal(true);
    }
  };

  const onSuccessAuthorizeAccessModal = async () => {
    setOpenAuthorizeAccessModal(false);
    setTj(null);
    await handleAttachOrImportLegalProceeding();
  };

  const handleAttachOrImportLegalProceeding = async () => {
    if (caseData?.id) return attachLegalProceeding();
    return handleImportLegalProceeding();
  };

  const handleImportLegalProceeding = async () => {
    try {
      const { cnj } = getValues();
      setDisabled(true);
      await legalProceedingToCase({ cnjs: [cnj] });
      WebToastColoredWithTitle.success("Processo importado com sucesso");
      onSuccess?.();
    } catch (error) {
      WebToastColoredWithTitle.error("Erro ao importar processo", "Ocorreu um erro ao importar o processo");
    } finally {
      setDisabled(false);
    }
  };

  const isCaseInactive = caseData?.status === "INACTIVE";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "start",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        height: "fit-content",
      }}
    >
      <AuthorizeAccessModal
        open={openAuthorizeAccessModal}
        onClose={() => setOpenAuthorizeAccessModal(false)}
        tjToAdd={tj?.needAdd && tj?.courtCredential ? [tj.courtCredential] : []}
        selectedCourtCredential={tj?.courtCredential ?? null}
        onSuccess={onSuccessAuthorizeAccessModal}
      />
      <Tooltip title={isCaseInactive ? "Ative esse caso para monitorar o processo" : ""}>
        <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
          <TextField
            fullWidth
            {...register("cnj")}
            error={!!errors.cnj}
            helperText={errors.cnj?.message}
            variant="outlined"
            label="Número do processo"
            disabled={isCaseInactive}
            placeholder={!caseData?.id ? "0000000-00.0000.0.00.0000" : "Digite o número do processo aqui"}
            inputProps={{
              maxLength: 25,
              onKeyUp: applyCnjMask,
            }}
          />
          <Checkbox
            {...register("isSecret")}
            error={!!errors.isSecret}
            helperText={errors.isSecret?.message}
            label="Em segredo de justiça"
            disabled={isCaseInactive}
            sx={{
              mt: 1,
              ml: 1,
              "& .MuiTypography-body1": {
                alignSelf: "center",
              },
            }}
          />
          <Button
            variant="text"
            color="primary"
            startIcon={isPending || isPendingLegalProceedingToCase ? undefined : Search}
            sx={{ alignSelf: "center", fontWeight: 600 }}
            onClick={onSubmit}
            disabled={!isValid || isLoadingCourtCredentials || disabled || isCaseInactive}
            isLoading={isPending || isPendingLegalProceedingToCase}
            loaderStyle={{
              width: "222px",
              alignSelf: "center",
              borderRadius: 2,
            }}
          >
            {caseData?.id ? "Buscar processo e vincular" : "Buscar processo e importar"}
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

export const LegalProceedingImportByCNJModal = ({
  open,
  onClose,
  caseData,
  onSuccess,
}: {
  open: boolean;
  onClose: () => void;
  caseData?: Case;
  onSuccess?: () => void;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          p: 3,
          borderRadius: 1,
          width: "400px",
          display: "flex",
          justifyContent: "center",
          gap: 3,
          flexDirection: "column",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "background.paper",
        }}
      >
        {caseData?.id ? (
          <Typography variant="subtitle1" fontWeight={700}>
            Vincular processo
          </Typography>
        ) : (
          <Typography variant="subtitle1" fontWeight={700} mb={2}>
            Importar processo por Número
          </Typography>
        )}
        <LegalProceedingImportByCNJ caseData={caseData} onSuccess={onSuccess} />
      </Box>
    </Modal>
  );
};
