import React from "react";

import { Box, Stack, Typography, styled } from "@mui/material";
import { ArrowOutwardOutlined } from "@mui/icons-material";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import { Chip } from "../Chip";
import { getPlanDaysLeftDescription } from "@/utils/plans";

export const PlanChips = ({
  planName,
  isTestingPlan,
  nextBillingDate,
  activeCasesCount,
  activeCasesQuota,
  handleOpenCasesScreen,
  usersCount,
  usersQuota,
  handleOpenUsersScreen,
}: {
  planName: string | undefined;
  isTestingPlan: boolean;
  nextBillingDate: string | undefined;
  activeCasesCount: number;
  activeCasesQuota: number;
  handleOpenCasesScreen: () => void;
  usersCount: number;
  usersQuota: number;
  handleOpenUsersScreen: () => void;
}) => {
  return (
    <ChipRow>
      {planName && <PlanChip planName={planName} showDaysLeft={isTestingPlan} nextBillingDate={nextBillingDate} />}
      {/* This is commented because we don't have the next bill price yet */}
      {/* {!isTestingPlan && nextBillingDate && <NextBillChip nextBillDate={nextBillingDate} price={price} />} */}
      <ActiveCasesChip
        activeCasesCount={activeCasesCount}
        activeCasesQuota={activeCasesQuota}
        onOpenCasesScreen={handleOpenCasesScreen}
      />
      <UsersChip usersCount={usersCount} usersQuota={usersQuota} onOpenUsersScreen={handleOpenUsersScreen} />
    </ChipRow>
  );
};

const ChipInRow = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

const PlanChip = ({
  planName,
  showDaysLeft,
  nextBillingDate,
}: {
  planName: string;
  showDaysLeft: boolean;
  nextBillingDate: string | undefined;
}) => {
  const daysLeft = getPlanDaysLeftDescription(nextBillingDate);

  return (
    <Chip title="Plano atual">
      <ChipContent>
        <Typography variant="h6" fontWeight={400}>
          {planName}
        </Typography>
        {showDaysLeft && <Typography variant="subtitle2">{daysLeft}</Typography>}
      </ChipContent>
    </Chip>
  );
};

const ChipRow = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  width: "100%",
}));

const ChipContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  width: "100%",
}));

// const NextBillChip = ({ price, nextBillDate }: { price: number; nextBillDate: string }) => {
//   return (
//     <Chip title="Próxima cobrança">
//       <ChipContent>
//         <Stack>
//           <Typography variant="h6" fontWeight={400}>
//             R$ {price},00
//           </Typography>
//           <Typography variant="subtitle2">Vence em: {nextBillDate}</Typography>
//         </Stack>
//       </ChipContent>
//     </Chip>
//   );
// };

const ActiveCasesChip = ({
  activeCasesCount,
  activeCasesQuota,
  onOpenCasesScreen,
}: {
  activeCasesCount: number;
  activeCasesQuota: number;
  onOpenCasesScreen: () => void;
}) => {
  const { shouldValidateCasesQuota } = useFeatureFlags();
  return (
    <Chip title="Casos ativos" onClick={onOpenCasesScreen}>
      <ChipContent>
        <ChipInRow>
          <Typography variant="h6" fontWeight={400}>
            {activeCasesCount}
            {shouldValidateCasesQuota && ` de ${activeCasesQuota}`}
          </Typography>
          <ArrowOutwardOutlined />
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};

const UsersChip = ({
  usersCount,
  usersQuota,
  onOpenUsersScreen,
}: {
  usersCount: number;
  usersQuota: number;
  onOpenUsersScreen: () => void;
}) => {
  return (
    <Chip title="Qtde. de usuários" onClick={onOpenUsersScreen}>
      <ChipContent>
        <ChipInRow>
          <Typography variant="h6" fontWeight={400}>
            {usersCount} de {usersQuota}
          </Typography>
          <ArrowOutwardOutlined />
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};
