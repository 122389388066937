import React, { ChangeEvent, useMemo } from "react";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Loading } from "@/components/Loading";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { WebToast } from "@/components/core/Toast";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { ActivityArea, activityAreaOptions, Department, getActivityAreaLabel } from "@/hooks/company/types";
import { CloseOutlined } from "@mui/icons-material";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { useUpdateCompanyMember } from "@/hooks/company/useUpdateCompanyMember";
import { Phone } from "@/core/Phone";

type Form = {
  isAdmin?: boolean;
  notificationNumber?: string;
  department?: Department;
  activityAreas: { value: ActivityArea; label: string }[];
};

export const EditMemberModal = ({ onClose, memberId }: { onClose: () => void; memberId: string }) => {
  const [form, setForm] = React.useState<Form>({
    notificationNumber: "",
    activityAreas: [],
  });
  const { data: companyMember, isLoading } = useCompanyMember({ memberId });
  const { mutateAsync: updateCompanyMember, isPending: isUpdating } = useUpdateCompanyMember();
  const [isValidPhoneNumber, setIsValidPhoneNumber] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (companyMember) {
      setForm({
        isAdmin: companyMember.isAdmin,
        notificationNumber: companyMember.notificationNumber?.substring(2),
        department: companyMember.department,
        activityAreas:
          companyMember.activityAreas?.map((value) => ({
            value,
            label: getActivityAreaLabel(value),
          })) || [],
      });
    }
  }, [companyMember]);

  const handleEditCompanyMember = async () => {
    if (!form.notificationNumber || !form.department || !companyMember) {
      WebToast.error("Preencha todos os campos obrigatórios");
      return;
    }

    const notificationNumber = Phone.new(form.notificationNumber).isSuccess
      ? Phone.new(form.notificationNumber).getValue().toPersistence()
      : form.notificationNumber;

    try {
      await updateCompanyMember({
        memberId: companyMember.userId,
        data: {
          isAdmin: form.isAdmin,
          notificationNumber: notificationNumber,
          department: form.department,
          activityAreas:
            form.department === Department.LEGAL ? form.activityAreas?.map((area) => area.value) : undefined,
        },
      });

      onClose();
      return WebToast.success("Colaborador atualizado com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao atualizar colaborador");
    }
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (prop === "notificationNumber") {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value.replace(/\D/g, ""),
        }));
      } else if (prop === "isAdmin") {
        if (companyMember?.isOwner) {
          WebToast.warn("Você não pode alterar o status de administrador do proprietário da conta");
          return;
        }
        if (companyMember?.userId === memberId) {
          WebToast.warn("Você não pode alterar o status de administrador do próprio usuário");
          return;
        }

        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.checked,
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value,
        }));
      }
    };
  };

  const saveButtonDisabled = useMemo(() => {
    return (
      form.notificationNumber === "" ||
      !form.department ||
      !isValidPhoneNumber ||
      (form.department === Department.LEGAL && (form.activityAreas?.length === 0 || !form.activityAreas))
    );
  }, [form, isValidPhoneNumber]);

  return (
    <ModalRight
      title={"Editar colaborador"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        !isLoading &&
        !isUpdating && (
          <ModalFooter
            confirmLabel={"Salvar mudanças"}
            onConfirm={handleEditCompanyMember}
            cancelLabel="Cancelar"
            onCancel={onClose}
            saveButtonDisabled={saveButtonDisabled}
          />
        )
      }
      open={true}
    >
      <Loading isLoading={isLoading || isUpdating}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "16px",
            marginBottom: "8px",
          }}
        >
          <Typography variant="body2">
            Todos os colaboradores podem criar e visualizar as peças criadas na sua plataforma Lexter
          </Typography>

          <TextField placeholder="E-mail" value={companyMember?.email} label="Email" disabled />
          <TextField
            placeholder="Telefone"
            onChange={(e) => {
              handleChangeForm("notificationNumber")(e as ChangeEvent<HTMLInputElement>);
              setIsValidPhoneNumber(
                e.target.value === "" ? true : Phone.new(e.target.value.replace(/[^\d]+/g, "")).isSuccess
              );
            }}
            inputProps={{
              maxLength: 15,
            }}
            value={
              form?.notificationNumber
                ? Phone.new(form.notificationNumber).isSuccess
                  ? Phone.new(form.notificationNumber).getValue().toString()
                  : form.notificationNumber
                : ""
            }
            label="Telefone"
            error={!isValidPhoneNumber}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginBop: "8px",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Administrador
              </Typography>
              <Typography variant="body2">
                Administrador é um colaborador que pode administar outros usuários
              </Typography>
            </Box>
            <FormControl>
              <FormControlLabel
                control={<Checkbox checked={form?.isAdmin || false} onChange={(e) => handleChangeForm("isAdmin")(e)} />}
                label="Administrador"
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Setor
              </Typography>
              <Typography variant="body2">
                Os setores influenciam especialmente no redirecionamento de um atendimento para um colaborador
              </Typography>
            </Box>
            <FormControl>
              <RadioGroup onChange={handleChangeForm("department")} defaultValue={companyMember?.department}>
                <FormControlLabel value={Department.ADMINISTRATIVE} control={<Radio />} label="Administrativo" />
                <FormControlLabel value={Department.FINANCIAL} control={<Radio />} label="Financeiro" />
                <FormControlLabel value={Department.LEGAL} control={<Radio />} label="Jurídico" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {form.department === Department.LEGAL && (
              <>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Área de atuação
                  </Typography>
                  <Typography variant="body2">
                    A área de atuação impacta no redirecionamento ou não de um atendimento para você
                  </Typography>
                </Box>
                <Box>
                  <Autocomplete
                    disablePortal
                    options={activityAreaOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <TextField {...params} label="Comece a digitar para buscar" />}
                    multiple
                    value={form.activityAreas}
                    noOptionsText="Nenhuma opção encontrada"
                    sx={{
                      "& .MuiChip-root": {
                        visibility: "hidden",
                        display: "none",
                      },
                      "& .MuiChip-label": {
                        display: "none",
                        visibility: "hidden",
                      },
                    }}
                    onChange={(_, value) => {
                      setForm((prevState) => ({
                        ...prevState,
                        activityAreas: value,
                      }));
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {form.activityAreas.map((areaOption) => (
                    <Chip
                      key={areaOption.value}
                      label={areaOption.label}
                      onDelete={() => {
                        setForm((prevState) => ({
                          ...prevState,
                          activityAreas: prevState.activityAreas.filter((item) => item.value !== areaOption.value),
                        }));
                      }}
                      sx={{
                        backgroundColor: "primary.main",
                        color: "common.white",
                      }}
                      deleteIcon={<CloseOutlined />}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Loading>
    </ModalRight>
  );
};
