import { LegalProceedingService } from "@/services/legalProceeding";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { legalProceedingsQueryKey } from "../usePaginatedLegalProceedingsQuery";
import { ImportLegalProceedingFromOABPayload } from "@/services/legalProceeding/types";

export const useImportLegalProceedingFromOAB = () => {
  const api = LegalProceedingService;
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["importLegalProceedingFromOAB"],
    mutationFn: async (payload: ImportLegalProceedingFromOABPayload) => await api.importLegalProceedingFromOAB(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: legalProceedingsQueryKey });
    },
  });
};
