import { useQuery } from "@tanstack/react-query";
import { LegalProceedingService } from "@/services/legalProceeding";

export const getLegalProceedingQueryKey = (cnj: string, instance?: string) =>
  instance ? ["legalProceeding", cnj, instance] : ["legalProceeding", cnj];

export const useLegalProceedingHistory = ({ cnj, instance }: { cnj: string; instance: string }) => {
  return useQuery({
    queryKey: [...getLegalProceedingQueryKey(cnj, instance), "history"],
    queryFn: async () => await LegalProceedingService.getLegalProceedingHistory(cnj, instance),
    refetchOnWindowFocus: false,
  });
};
