import React, { useState } from "react";
import { IconButton, Menu, Box, Typography, Divider } from "@mui/material";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { Loading } from "@/components/Loading";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { getCasePath, ROUTE_PATHS } from "@/routes/routePaths";
import { Notification } from "@/services/notification/types";
import { useOpenNotificationMutation } from "@/hooks/notifications/useOpenNotificationMutation";
import { useNotificationsQuery } from "@/hooks/notifications/useNotificationsQuery";

export const Notifications: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const { data: notifications, isLoading: isLoadingNotifications } = useNotificationsQuery();
  const { mutate: openNotification } = useOpenNotificationMutation();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNotification = (notification: Notification) => {
    openNotification(notification.id);
    if (notification.notificationType === "THREAD_UPDATED") {
      navigate(`${ROUTE_PATHS.CHAT}/${notification.data.threadId}`);
    }
    if (notification.notificationType === "CASE_UPDATED") {
      navigate(getCasePath({ caseId: notification.data.caseId }));
    }
    if (notification.notificationType === "FINISHED_LISTING_LEGAL_PROCEEDINGS") {
      navigate(ROUTE_PATHS.IMPORT_LEGAL_PROCEEDINGS_BY_OAB);
    }
    handleClose();
  };

  return (
    <Box>
      <IconButton onClick={handleOpenMenu}>
        {notifications?.some((notification) => !notification.visualizedAt) && (
          <Box
            sx={{
              borderRadius: "100%",
              position: "absolute",
              bgcolor: "primary.main",
              width: "12px",
              height: "12px",
              right: "8px",
              top: "8px",
            }}
          />
        )}
        <NotificationsNoneRoundedIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          borderRadius: "12px",
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
          "& .MuiPaper-root": {
            borderRadius: "12px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: "400px" }}>
          <Box
            sx={{
              height: "196px",
              overflowY: notifications && notifications?.length > 1 ? "auto" : "hidden",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-track": {
                borderRadius: "10px",
                backgroundColor: "common.white",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "text.secondary",
              },
            }}
          >
            {notifications?.length || isLoadingNotifications ? (
              <Loading isLoading={isLoadingNotifications}>
                {notifications?.map((notification, idx) => (
                  <Box
                    key={notification.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleOpenNotification(notification);
                    }}
                  >
                    <Box
                      sx={{
                        paddingY: "16px",
                        paddingX: "12px",
                        borderLeft: "8px",
                        borderLeftStyle: "solid",
                        borderLeftColor: notification.visualizedAt ? "transparent" : "secondary.main",
                        display: "grid",
                        gap: "4px",
                      }}
                    >
                      <Typography variant="title" sx={{ textTransform: "none" }}>
                        {notification.title}
                      </Typography>
                      <Typography variant="body" sx={{ fontSize: "12px" }}>
                        {`${notification.text} - ${getTimeSince(notification.createdAt)}`}
                      </Typography>
                    </Box>
                    {notifications.length - 1 !== idx && <Divider />}
                  </Box>
                ))}
              </Loading>
            ) : (
              <Box
                sx={{
                  padding: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  Nenhuma notificação
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

const getTimeSince = (date: string) => {
  const now = DateTime.now();
  const dateIsoString = date.trim().replace(" ", "T").replace("Z", "") + "Z";
  const then = DateTime.fromISO(dateIsoString);

  const diff = now.diff(then, ["days", "hours", "minutes"]);
  const diffObject = diff.toObject();

  const days = getDays(diffObject?.days);
  const hours = getHours(diffObject?.hours);
  const min = getMinutes(diffObject?.minutes);

  const message = getMessage({ days, hours, min });

  if (!message) return "agora";
  return `${message} atrás`;
};

const getMessage = (diff: { days?: string; hours?: string; min?: string }) => {
  if (diff.days) return diff.days;
  if (diff.hours) return diff.hours;
  if (diff.min) return diff.min;
  return null;
};

const getDays = (diff?: number) => {
  if (!diff) return;
  return diff > 1 ? `${diff?.toFixed(0)} dias` : `${diff?.toFixed(0)} dia`;
};

const getHours = (diff?: number) => {
  if (!diff) return;
  return diff > 1 ? `${diff?.toFixed(0)} horas` : `${diff?.toFixed(0)} hora`;
};

const getMinutes = (diff?: number) => {
  if (!diff) return;
  return diff >= 1 ? `${diff?.toFixed(0)} min` : "<1 min";
};
