import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DialogModal } from "@/components/DialogModal";

import { WebToast } from "@/components/core/Toast";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { useDeleteCompanyMember } from "@/hooks/company/useDeleteCompanyMember";

export const DeleteMemberModal = ({
  isOpen,
  onConfirm,
  onCancel,
  memberId,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  memberId: string;
}) => {
  const { data: companyMember, isLoading } = useCompanyMember({ memberId });
  const { mutateAsync: deleteCompanyMember, isPending: isDeleting } = useDeleteCompanyMember();

  const handleDeleteCompanyMember = async () => {
    try {
      await deleteCompanyMember({
        memberId,
      });

      onConfirm();
      return WebToast.success("Colaborador removido com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao remover colaborador");
    }
  };

  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title={"Remover usuário"}
      description={
        isLoading ? (
          <Box display="flex" color="secondary" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: "text.primary", textAlign: "center" }}>
            Confirmando deletaremos essa conta e o usuário associado ao email{" "}
            <Typography
              sx={{
                display: "inline",
                fontWeight: "bold !important",
                color: "primary.main",
              }}
            >
              {companyMember?.email}
            </Typography>{" "}
            deixará de ter acesso a plataforma Lexter
          </Typography>
        )
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "Cancelar",
          onClick: isLoading || isDeleting ? undefined : onCancel,
          sx: {
            color: "text.primary",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          label: "Confirmar",
          onClick: handleDeleteCompanyMember,
          color: "primary",
          disabled: isLoading || isDeleting,
        },
      ]}
      variant="error"
    />
  );
};
