import React, { forwardRef } from "react";
import { TextFieldProps, Typography } from "@mui/material";
import { InputField } from "../InputField";

export const PhoneNumberField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  return (
    <InputField
      {...props}
      ref={ref}
      inputProps={{
        maxLength: 15,
        ...props.inputProps,
      }}
      InputProps={{
        startAdornment: (
          <Typography
            sx={{
              mr: 0.5,
            }}
          >
            +55
          </Typography>
        ),
        ...props.InputProps,
      }}
    />
  );
});

PhoneNumberField.displayName = "PhoneNumberField";
