import { useAuthContext } from "@/contexts/AuthContext";

import React, { useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { useOabs } from "@/hooks/oabs/useOabs";
import { normalizedOABToDisplay } from "@/utils/normalizeOab";
import { DeleteOabButton } from "./DeleteOabButton";
import { Box } from "@mui/material";

export const OabsList = () => {
  const { user } = useAuthContext();
  const { data: paginatedRecords, isLoading: isLoadingOabs, isFetching: isFetchingOabs } = useOabs(user?.userId ?? "");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const loading = useMemo(() => {
    return isLoadingOabs || isFetchingOabs;
  }, [isLoadingOabs, isFetchingOabs]);

  return (
    <Box sx={{ height: loading ? "525px" : "auto" }}>
      <DataGrid
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10]}
        rows={paginatedRecords?.data ?? []}
        rowCount={paginatedRecords?.totalResults || 0}
        getRowId={(row) => row.id}
        columns={[
          {
            field: "oab",
            type: "string",
            headerName: "Inscrição OAB",
            width: 400,
            renderCell: (params) => {
              return <>{normalizedOABToDisplay(params.row.oab, params.row.uf)}</>;
            },
          },
          {
            field: "createdAt",
            type: "string",
            headerName: "Adicionado em",
            width: 150,
            valueGetter: (value: string) => {
              return new Date(value).toLocaleDateString("pt-BR");
            },
          },
          {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 150,
            renderCell: (params) => {
              return <DeleteOabButton oab={params.row} />;
            },
          },
        ]}
        loading={loading}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        autoHeight={loading ? false : true}
        sx={{
          borderRadius: 2,
          "& .MuiDataGrid-cell": {
            pl: 2,
          },
          "& .MuiDataGrid-columnHeader": {
            pl: 2,
          },
          "& .MuiDataGrid-virtualScroller": {
            overflow: loading ? "hidden" : "scroll",
          },
        }}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
        disableColumnFilter
        disableColumnResize
        disableColumnSorting
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </Box>
  );
};
