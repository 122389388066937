import { useQueryClient, useQuery } from "@tanstack/react-query";

const IMPERSONATION_KEY = ["impersonation"];

interface ImpersonatedUser {
  companyId: string;
  companyEmail?: string;
  companyName?: string;
  whatsappNumber?: string;
}

export const useImpersonation = () => {
  const queryClient = useQueryClient();

  const setImpersonatedUser = (user?: ImpersonatedUser) => {
    queryClient.setQueryData<ImpersonatedUser>(IMPERSONATION_KEY, user);
  };

  const { data: impersonatedUser } = useQuery({
    queryKey: IMPERSONATION_KEY,
    queryFn: () => queryClient.getQueryData<ImpersonatedUser>(IMPERSONATION_KEY) || null,
  });

  return {
    impersonatedUser,
    setImpersonatedUser,
  };
};
