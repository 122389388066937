import "firebase/compat/auth";
import { LoginWithEmailAndPassword } from "./hooks/useLogin/types";
import { User as FirebaseUser } from "firebase/auth";

export interface AuthContextData {
  user?: LoggedInUserData;
  loginType?: LoginType;
  loading: boolean;
  loginWithEmailAndPassword: LoginWithEmailAndPassword;
  loginWithOffice: () => Promise<{ success: boolean; status?: number }>;
  loginWithGoogle: () => Promise<{
    success: boolean;
    needSignUp?: { user?: FirebaseUser };
  }>;
  setupFirebaseUser: (firebaseUser?: FirebaseUser | null, loginType?: string) => Promise<void>;
}

export enum LoginType {
  FIREBASE = "firebase",
}

export enum CompanyRole {
  ADMIN = "ADMIN",
  USER = "USER",
}

interface AcceptedTermsOfUseDTO {
  version: string;
  date: string;
}

export interface LoggedInUserData {
  userId: string;
  companyId: string;
  companyRole: CompanyRole;
  userName: string;
  userEmail?: string;
  userAcceptedTermsOfUse: AcceptedTermsOfUseDTO[];
  isAdmin: boolean;
  isCompanyOwner: boolean;
  profileCompleted?: boolean;
  numberOfMotionsPerWeek?: "-2" | "3-5" | "6-10" | "10-20" | "20+";
  annualRevenue?: "0-180k" | "181k-360k" | "361k-720k" | "720k-1800k" | "1800k+";
  numberOfMotionsPerMonth?: string;
  activityAreas?: string[];
}

export interface CompanyData {
  companyId: string;
  name: string;
  corporateName: string;
  cnpj?: string;
  cpf?: string;
  type: string;
  activityAreas?: string[];
  university?: string;
  signUpUtm?: string | unknown;
  gclid?: string;
  numberOfEmployees?: string;
  addresses: string[] | unknown;
  comments?: string;
  products: string[];
  hybridDocumentTypes: string[];
  automatedDocumentTypes: string[];
  loginConfig: string[];
  isActive: boolean;
  serviceLevelAgreement: string;
  samlProviderName?: string;
  externalCustomerId?: string;
  signUpProvider?: string;
}

export enum UpdatePasswordErrors {
  "INVALID_PASSWORD" = "INVALID_PASSOWRD",
  "GENERIC" = "GENERIC",
}
