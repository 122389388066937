import { ModalRight } from "@/components/Modal/ModalRight";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getSkillPath } from "@/routes/routePaths";
import { CreateANewDocumentSkillGroup } from "@/core/skillGroups/implementations/createANewDocument";
import { NewSkillGroupView } from "@/components/Chat/NewSkillGroupView";
import { mapCaseToSkillFields } from "@/utils/mapCaseToSkillFields";
import { Case } from "@/hooks/lexZap/types";
import { SkillId } from "@/core/skills/types";
import { SelectCaseModal } from "../SelectCaseModal";
import { removeUndefinedValues } from "@/utils/removeUndefinedValues";
import { CreateCaseModal } from "@/components/Cases/createCaseModal";

type WriteDocumentsActionsProps = { isOpen: boolean; onClose: () => void; caseData?: Case };

const WriteDocumentsActions = ({ isOpen, onClose, caseData }: WriteDocumentsActionsProps) => {
  const navigate = useNavigate();
  const [isSelectCaseModalOpen, setIsSelectCaseModalOpen] = useState(false);
  const [isCreateANewCaseOpen, setIsCreateANewCaseOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<{
    skillId: SkillId;
    customTitle?: string;
    defaultData?: Record<string, string>;
  } | null>(null);

  const handleCaseSelection = (
    selectedCase: Case | null,
    skillData?: {
      skillId: SkillId;
      customTitle?: string;
      defaultData?: Record<string, string>;
    }
  ) => {
    const skill = selectedSkill || skillData;
    if (!skill) return;

    if (!selectedCase) {
      const url = getSkillPath({
        skillId: skill.skillId,
      });

      navigate(url, {
        state: {
          ...skill.defaultData,
          customTitle: skill.customTitle,
        },
      });

      setIsSelectCaseModalOpen(false);
      onClose();
      return;
    }

    const url = getSkillPath({
      skillId: skill.skillId,
      threadId: selectedCase.threadId,
      customTitle: skill.customTitle,
    });

    const skillFields = mapCaseToSkillFields({ caseData: selectedCase, skillId: skill.skillId });

    navigate(url, {
      state: removeUndefinedValues(skillFields),
    });

    setIsSelectCaseModalOpen(false);
    onClose();
  };

  const handleSkillSelect = (skillData: {
    skillId: SkillId;
    customTitle?: string;
    defaultData?: Record<string, string>;
  }) => {
    setSelectedSkill(skillData);
    if (caseData) {
      handleCaseSelection(caseData, skillData);
    } else {
      setIsSelectCaseModalOpen(true);
    }
  };

  const handleCreateNewCase = () => {
    setIsCreateANewCaseOpen(true);
  };

  return (
    <>
      <ModalRight title={"Escrever novo documento"} open={isOpen} onClose={onClose} size="LARGE">
        <NewSkillGroupView
          skillGroup={CreateANewDocumentSkillGroup}
          onSelectSkill={handleSkillSelect}
          withSearch={true}
        />
      </ModalRight>
      <SelectCaseModal
        isOpen={isSelectCaseModalOpen}
        onClose={() => setIsSelectCaseModalOpen(false)}
        onConfirm={handleCaseSelection}
        onCreateNewCase={handleCreateNewCase}
      />
      {isCreateANewCaseOpen && (
        <CreateCaseModal onClose={() => setIsCreateANewCaseOpen(false)} onConfirm={handleCaseSelection} />
      )}
    </>
  );
};

export default WriteDocumentsActions;
