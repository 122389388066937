import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { CaseMessage, LexZapChat, Ticket } from "../types";
import { getMessagesQueryKey } from "../useLexZapMessages";
import { useImpersonation } from "@/hooks/useImpersonation";
import { AdminLexZapService, LexZapService } from "@/services/lexZap";
import { useLexZapCases } from "../useLexZapCases";
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { getTicketsQueryKey, useLexZapGetTickets } from "../useLexZapGetTickets";

interface UseLexZapChatsProps {
  disabled?: boolean;
}

export const getChatsQueryKey = () => ["lexZapChats"];

export const useLexZapChats = (props?: UseLexZapChatsProps) => {
  const queryClient = useQueryClient();
  const { data: casesData, isLoading: isLoadingCases } = useLexZapCases();
  const { impersonatedUser } = useImpersonation();
  const { data: applicants } = useApplicants();
  const { data: tickets } = useLexZapGetTickets();

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (impersonatedUser) {
        return;
      }

      if (update.type === WhatsappUpdateType.CHAT_UPDATE) {
        queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) => {
          const existingMessages = queryClient.getQueryData<CaseMessage[]>(
            getMessagesQueryKey({ applicantPhoneNumber: update.applicantPhoneNumber })
          );
          const lastMessage =
            existingMessages && existingMessages.length > 0 ? existingMessages[existingMessages.length - 1] : undefined;

          const chatExists = oldChats.some((chat) => chat.endClientNumber === update.applicantPhoneNumber);
          if (chatExists) {
            return oldChats.map((chat) => {
              if (chat.endClientNumber !== update.applicantPhoneNumber) return chat;

              const updatedChat: LexZapChat = {
                ...chat,
                ...update.updatedFields,
              };

              return updatedChat;
            });
          } else {
            const newChat: LexZapChat = {
              ...update.updatedFields,
              lastMessage,
              endClientNumber: update.applicantPhoneNumber,
            };

            return [...oldChats, newChat];
          }
        });
      }

      if (update.type === WhatsappUpdateType.TICKET_UPDATE) {
        queryClient.setQueryData(getTicketsQueryKey(), (old: Ticket[] | undefined) => {
          const { ticketId, updatedFields } = update;

          if (!old) return [];
          if (old.find((ticket) => ticket.id === ticketId)) {
            return old.map((ticket) =>
              ticket.id === ticketId
                ? {
                    ...ticket,
                    ...updatedFields,
                  }
                : ticket
            );
          } else {
            return [...old, updatedFields];
          }
        });
      }
    },
  });

  const { data, ...query } = useQuery<LexZapChat[]>({
    queryKey: getChatsQueryKey(),
    queryFn: async () => {
      if (impersonatedUser) {
        return AdminLexZapService.getLexZapChats({ companyId: impersonatedUser.companyId });
      }

      return LexZapService.getLexZapChats();
    },
    enabled: !props?.disabled,
    staleTime: 1000 * 60,
  });

  return {
    ...query,
    isLoading: query.isLoading || isLoadingCases,
    data: data
      ?.filter((chat) => !chat.lawyerChat)
      .map<LexZapChat>((chat) => {
        const filteredCases = casesData
          ?.filter(
            (caseItem) =>
              caseItem.applicantPhoneNumber === chat.endClientNumber && ["INACTIVE", "ACTIVE"].includes(caseItem.status)
          )
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        const lastCase = filteredCases?.[0];

        const applicant = applicants?.find((item) => item.phoneNumber === chat.endClientNumber);

        const lastTicket = tickets?.find((ticket) => ticket.id === chat.lastTicket?.id);

        return {
          ...chat,
          applicantName: applicant?.name || lastCase?.applicantName || chat.applicantName,
          lastCase,
          cases: filteredCases || [],
          isLoadingCase: isLoadingCases,
          lastTicket,
        };
      }),
  };
};
