import { Case } from "@/hooks/lexZap/types";
import { SkillId } from "@/core/skills/types";

export const mapCaseToSkillFields = ({ caseData, skillId }: { caseData: Case; skillId: SkillId }) => {
  switch (skillId) {
    case "create_labor_complaint":
    case "create_contestation":
    case "create_reply":
    case "intercurrent_motion":
    case "create_appeal":
    case "create_ordinary_appeal":
    case "create_other_type_of_piece":
    case "create_interview_script":
    case "hearings_script":
    case "fee_agreement":
    case "create_initial_petition":
    case "create_initial_petition_v2":
      return {
        client: caseData.applicantName,
        case_breakdown: caseData.facts,
      };
    case "create_contract":
      return {
        case_breakdown: caseData.facts,
      };
    case "settlement_offer":
      return {
        client: caseData.applicantName,
      };
    default:
      return {};
  }
};
