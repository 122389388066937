import React from "react";
import { Button } from "@/components/Button";
import { CourtCredentialType } from "@/services/legalProceeding/types";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";

type ActionCellProps = {
  row: CourtCredentialType;
  openAuthorizeAccessModal: (row: CourtCredentialType) => void;
  openRevokeAccessModal: (row: CourtCredentialType) => void;
};

export const ActionCell = ({ row, openAuthorizeAccessModal, openRevokeAccessModal }: ActionCellProps) => {
  const handleReview = () => {
    openAuthorizeAccessModal(row);
  };

  const handleRevoke = () => {
    openRevokeAccessModal(row);
  };

  switch (row.status) {
    case "ACTIVE":
      return <Button onClick={handleRevoke}>Revogar acesso</Button>;
    case "INACTIVE":
      return <Button onClick={handleReview}>Autorizar acesso</Button>;
  }
};

export const CreateCourtCredentialAction = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        gap: 2,
        borderTop: "1px solid",
        borderColor: "divider",
        p: 2,
      }}
    >
      <Button startIcon={Add} variant="contained" size="small">
        Adicionar acesso ao tribunal
      </Button>
    </Box>
  );
};
