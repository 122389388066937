import { jtrcodeToTj } from "@/utils/jtrcodeToTj";
import { Result } from "./Result";

const JUDICIARY_BRANCH_MAP = {
  "1": "Supremo Tribunal Federal",
  "2": "Conselho Nacional de Justiça",
  "3": "Superior Tribunal de Justiça",
  "4": "Justiça Federal",
  "5": "Justiça do Trabalho",
  "6": "Justiça Eleitoral",
  "7": "Justiça Militar da União",
  "8": "Justiça dos Estados e do Distrito Federal e Territórios",
  "9": "Justiça Militar Estadual",
} as const;

type JudiciaryBranch = keyof typeof JUDICIARY_BRANCH_MAP;

/**
 * Resolução: https://atos.cnj.jus.br/atos/detalhar/atos-normativos?documento=119
 * Resulução PDF: https://atos.cnj.jus.br/files/compilado23285720221017634de539229ab.pdf
 *
 * O número CNJ tem o seguinte formato: NNNNNNN-DD.AAAA.J.TR.OOOO
 * Onde:
 *  NNNNNNN: Número sequencial do processo
 *  DD: Dígito verificador
 *  AAAA: Ano do ajuizamento
 *  J: Órgão ou segmento do Poder Judiciário
 *  TR: Tribunal do respectivo segmento
 *  OOOO: Unidade de origem
 */
export class CNJ {
  private constructor(
    private readonly sequence: string,
    private readonly checkDigit: string,
    private readonly year: string,
    private readonly branch: JudiciaryBranch,
    private readonly court: string,
    private readonly venue: string
  ) {}

  static create(value: string): Result<CNJ> {
    // Remove any existing formatting
    const cleanValue = value.replace(/[^\d]/g, "").trim();

    // Check if it has 20 digits
    if (cleanValue.length !== 20) {
      return Result.fail("CNJ number must contain 20 digits");
    }

    // Extract parts of the number
    const sequence = cleanValue.slice(0, 7);
    const checkDigit = cleanValue.slice(7, 9);
    const year = cleanValue.slice(9, 13);
    const branch = this.getBranchFromString(cleanValue.slice(13, 14));
    const court = cleanValue.slice(14, 16);
    const venue = cleanValue.slice(16, 20);

    if (branch.isFailure) {
      return Result.fail("Invalid Judiciary Branch");
    }

    // TODO: Fix it, this validation is not working, i didnt tested deep enough
    // Maybe the problem is about the big int values
    // if (!this.validateCheckDigit(sequence, checkDigit, year, branch.getValue(), court, venue)) {
    //   return Result.fail("Invalid check digit");
    // }

    if (!this.validateYear(year)) {
      return Result.fail("Invalid year");
    }

    return Result.ok(new CNJ(sequence, checkDigit, year, branch.getValue(), court, venue));
  }

  private static validateCheckDigit(
    sequence: string,
    checkDigit: string,
    year: string,
    branch: JudiciaryBranch,
    court: string,
    venue: string
  ): boolean {
    try {
      // Extrai os componentes do número do processo
      const nnnnnnn = sequence; // N6 N5 N4 N3 N2 N1 N0
      const dd = checkDigit; // D1 D0 (dígitos verificadores)
      const aaaa = year; // A3 A2 A1 A0
      const j = branch; // J2
      const tr = court; // T1 R0
      const oooo = venue; // O3 O2 O1 O0

      // Fatoração do cálculo devido a limitações de precisão
      const r1 = BigInt(nnnnnnn) % BigInt(97);

      const r2Str = r1.toString() + aaaa + j + tr;
      const r2 = BigInt(r2Str) % BigInt(97);
      const r3Str = r2.toString() + oooo + "0100";
      const r3 = BigInt(r3Str) % BigInt(97);

      // Calcula os dígitos verificadores
      const calculatedDD = 98 - Number(r3);

      // Formata com zero à esquerda se necessário
      const calculatedDDStr = calculatedDD.toString().padStart(2, "0");
      // Compara com os dígitos verificadores fornecidos
      return calculatedDDStr === dd;
    } catch (error) {
      return false;
    }
  }

  private static validateYear(year: string): boolean {
    const yearNum = parseInt(year);
    const currentYear = new Date().getFullYear();
    return yearNum >= 1950 && yearNum <= currentYear;
  }

  private static getBranchFromString(branch: string): Result<JudiciaryBranch> {
    const validBranchCodes = Object.keys(JUDICIARY_BRANCH_MAP);
    if (validBranchCodes.includes(branch)) {
      return Result.ok(branch as JudiciaryBranch);
    }
    return Result.fail("Invalid branch code");
  }

  toString(): string {
    return `${this.sequence}-${this.checkDigit}.${this.year}.${this.branch}.${this.court}.${this.venue}`;
  }

  // Returns number without formatting
  toNumber(): number {
    return parseInt(this.toString().replace(/[^\d]/g, ""));
  }

  // Getters for specific parts of the number
  getSequenceNumber(): string {
    return this.sequence;
  }

  getCheckDigit(): string {
    return this.checkDigit;
  }

  getYear(): string {
    return this.year;
  }

  getBranchCode(): string {
    return this.branch;
  }

  getCourtCode(): string {
    return this.court;
  }

  getTj(): string | undefined {
    if (`${this.branch}${this.court}` in jtrcodeToTj) {
      return jtrcodeToTj[`${this.branch}${this.court}` as keyof typeof jtrcodeToTj];
    }
    return undefined;
  }

  getVenueCode(): string {
    return this.venue;
  }

  getBranchName(): string {
    return JUDICIARY_BRANCH_MAP[this.branch];
  }
}
