import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case } from "../types";
import { getCasesQueryKey } from "../useLexZapCases";

export const useEditCase = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["updateCase"],
    mutationFn: async ({
      caseId,
      data,
    }: {
      caseId: string;
      data: {
        name: string;
        applicantId?: string | null;
        step: string;
        legalArea?: string;
      };
    }) => await api.updateCase({ caseId, data }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getCasesQueryKey() });
    },
    onMutate: async ({ caseId, data }) => {
      await queryClient.cancelQueries({ queryKey: getCasesQueryKey() });
      const previousCases = queryClient.getQueryData<Case[]>(getCasesQueryKey());
      queryClient.setQueryData(getCasesQueryKey(), (old: Case[] = []) => {
        return old.map((c) => (c.id === caseId ? { ...c, ...data } : c));
      });
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCasesQueryKey(), context?.previousCases);
    },
  });
};
