import { authenticatedCopilotServer } from "@/core/api";
import { ActivityArea, CompanyMember } from "@/hooks/company/types";

export const CompanyService = {
  async getTeam() {
    const response = await authenticatedCopilotServer.get<CompanyMember[]>(`/company/myTeam`);
    return response.data;
  },

  async createTeamMember(data: {
    email: string;
    department: string;
    activityAreas?: ActivityArea[];
  }): Promise<{ id: string }> {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/company/myTeam`, data);
    return response.data;
  },

  async getTeamMember(id: string) {
    const response = await authenticatedCopilotServer.get<CompanyMember>(`/company/myTeam/${id}`);
    return response.data;
  },

  async updateTeamMember({
    memberId,
    data,
  }: {
    memberId: string;
    data: { notificationNumber: string; department: string; activityAreas?: ActivityArea[] };
  }) {
    const response = await authenticatedCopilotServer.post<CompanyMember>(`/company/myTeam/${memberId}`, data);
    return response.data;
  },

  async deleteTeamMember({ memberId }: { memberId: string }) {
    const response = await authenticatedCopilotServer.delete(`/company/myTeam/${memberId}`);
    return response.data;
  },

  async resendTeamMemberInvitation({ memberId }: { memberId: string }) {
    const response = await authenticatedCopilotServer.post(`/company/myTeam/${memberId}/resendInvitation`, {});
    return response.data;
  },
};
