import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Ticket, TicketStatus } from "@/hooks/lexZap/types";
import { DateTime } from "luxon";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { Button } from "@/components/Button";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";

interface TicketCardProps {
  ticket: Ticket;
  openCloseTicketModal: (ticketId: string, applicantPhoneNumber: string) => void;
}

export const TicketCard = ({ ticket, openCloseTicketModal }: TicketCardProps) => {
  const { data: companyMember } = useCompanyMember({
    memberId: ticket.createdBy,
  });
  const navigate = useNavigate();

  const goToTicket = () => {
    navigate(generatePath(ROUTE_PATHS.TICKET_DETAILS, { ticketId: ticket.id }));
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "2px solid",
        borderColor: "grey.300",
        position: "relative",
      }}
    >
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box>
          <Typography variant="subtitle1">Atendimento</Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 700,
              color: "grey.800",
            }}
          >
            {DateTime.fromMillis(ticket.createdAt).toFormat("dd/MM/yyyy")}
            {" - "}
            {ticket.closedAt && `${DateTime.fromMillis(ticket.closedAt).toFormat("dd/MM/yyyy")}`}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              color: "grey.800",
            }}
          >
            Iniciador
          </Typography>
          <Typography variant="body1">{companyMember?.name || "Cliente"}</Typography>
        </Box>
        {/* <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              color: "grey.800",
            }}
          >
            Atendido por
          </Typography>
          <Typography variant="body1">{ticket.createdBy}</Typography>
        </Box> */}
        {ticket.summary && (
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                color: "grey.800",
              }}
            >
              Assunto
            </Typography>
            <Tooltip title={ticket.summary} arrow placement="top">
              <Typography
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  textWrap: "wrap",
                  overflow: "hidden",
                }}
                variant="body1"
              >
                {ticket.summary}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          gap: "16px",
        }}
      >
        {ticket.status === TicketStatus.ACTIVE && (
          <Button
            sx={{
              borderRadius: "4px",
              fontWeight: 500,
              backgroundColor: "orange.light.20",
              "&:hover": {
                backgroundColor: "orange.light.40",
              },
            }}
            variant="contained"
            color="primary"
            onClick={() => openCloseTicketModal(ticket.id, ticket.applicantPhoneNumber)}
          >
            Encerrar atendimento
          </Button>
        )}

        {ticket.status === TicketStatus.CLOSED ? (
          <Button
            sx={{
              borderRadius: "4px",
              fontWeight: 500,
              backgroundColor: "orange.light.20",
              "&:hover": {
                backgroundColor: "orange.light.40",
              },
            }}
            variant="contained"
            color="primary"
            onClick={goToTicket}
          >
            Ver resumo
          </Button>
        ) : (
          <Button
            sx={{
              borderRadius: "4px",
              fontWeight: 600,
            }}
            variant="contained"
            color="primary"
            onClick={goToTicket}
          >
            {ticket.summary ? "Ver resumo" : "Ver detalhes"}
          </Button>
        )}
      </Box>
    </Box>
  );
};
