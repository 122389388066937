import React from "react";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";

interface NumberCardProps {
  title: string;
  actualValue: number;
  rangeValue?: number;
  loading?: boolean;
}

export function NumberCard({ title, actualValue, rangeValue, loading }: NumberCardProps) {
  return (
    <Card
      sx={{
        bgcolor: "common.dorian",
        width: "100%",
        boxShadow: "none",
        borderRadius: "8px",
        py: 0.5,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pb: "8px!important",
          height: "100%",
        }}
      >
        <Typography variant="body2">{title}</Typography>
        {loading ? (
          <Skeleton variant="text" width={rangeValue !== undefined ? 100 : 20} height={28} />
        ) : (
          <Typography variant="body1" fontWeight={700} fontSize={16}>
            {actualValue} {rangeValue !== undefined && <>de {rangeValue}</>}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
