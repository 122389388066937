import { SkillId } from "@/core/skills/types";
import { RecordType } from "../records/types";
import { ActivityArea, ActivityAreaOptions, Department } from "../company/types";
import { TemplateMessageParams } from "@/services/lexZap/types/templateMessage.types";

export interface LexZapUser {
  companyId: string;
  userId: string;
  createdAt: number;
  notificationNumber?: string;
  department: Department;
  activityAreas?: ActivityArea[];
}

export interface LexZapCompany {
  companyId: string;
  email: string;
  phoneNumber: string;
  apiKey: string;
  createdAt: number;
  isActive?: boolean;
  assistantName?: string;
  companyName?: string;
}

export enum CaseStep {
  CASE_STARTED = "CASE_STARTED",
  SUMMARY_GENERATED = "SUMMARY_GENERATED",
  AWAITING_APPLICANT_APPROVAL = "AWAITING_APPLICANT_APPROVAL",
  CASE_FAILED = "CASE_FAILED",
  LAWYER_INVOKED_BY_APPLICANT = "LAWYER_INVOKED_BY_APPLICANT",
  EXCEEDED_PROGRESS_LIMIT = "EXCEEDED_PROGRESS_LIMIT",
}

export type CaseNextStep = {
  id: string;
  completed: boolean;
  description: string;
  skillId?: SkillId;
  fileId?: number;
};

export type LegalProceedingFile = {
  id: string;
  cnj: string;
  instance: string;
  createdAt: string;
  integration: string;
  integrationFileId: string;
  legalProceedingStepId: string;
  name: string;
  extension: string;
};

export interface Case {
  id: string;
  summary: string;
  step: CaseStep;
  status: "INACTIVE" | "ACTIVE" | "ARCHIVED";
  userId: string;
  companyId: string;
  chatId?: string;
  threadId: string;
  name: string;
  applicantName?: string;
  applicantPictureUrl?: string;
  applicantPhoneNumber?: string;
  partyIdentification?: string;
  facts?: string;
  legalQuestions?: string;
  recommendations?: string;
  documentationAndEvidence?: string;
  legalArea?: string;
  conclusion?: string;
  timeline: CaseTimeline[];
  applicantId?: string;
  lastModifiedAt?: string;
  createdAt: string;
  cnjs?: string[];

  // campos para os mocks
  description?: string;
  nextSteps?: Array<CaseNextStep>;
  caseFiles?: Array<{
    createdAt: number;
    name: string;
  }>;

  ticketId?: string;
}

export interface CaseWithChat extends Case {
  lastMessage: CaseMessage;
  chatId: string;
}

export interface CaseTimeline {
  id: number;
  createdAt: string;
  status:
    | "CASE_STARTED"
    | "SUMMARY_GENERATED"
    | "AWAITING_APPLICANT_APPROVAL"
    | "CASE_FAILED"
    | "LAWYER_INVOKED_BY_APPLICANT"
    | "MANUALLY_CREATED"
    | "EXCEEDED_PROGRESS_LIMIT";
}

export enum CaseMessageType {
  TEXT = "text",
  AUDIO = "audio",
  IMAGE = "image",
  DOCUMENT = "document",
  STICKER = "sticker",
  VIDEO = "video",
  CONTACTS = "contacts",
  LOCATION = "location",
  TEMPLATE = "template",
  SNIPPET = "snippet",
}

export enum CaseMessageToSendType {
  TEXT = CaseMessageType.TEXT,
  AUDIO = CaseMessageType.AUDIO,
  IMAGE = CaseMessageType.IMAGE,
  DOCUMENT = CaseMessageType.DOCUMENT,
  STICKER = CaseMessageType.STICKER,
  VIDEO = CaseMessageType.VIDEO,
}

export const isValidMessageToSendType = (value: unknown): value is CaseMessageToSendType => {
  return Object.values(CaseMessageToSendType).includes(value as CaseMessageToSendType);
};

export interface LexZapChat {
  lawyerId?: string;
  endClientNumber: string;
  status?: ChatStatus;
  applicantName?: string;
  lawyerChat?: boolean;
  lastMessage?: CaseMessage;
  lastEndClientMessageDate?: number;
  chatMode?: ChatMode;
  lastReadMessageId?: string;
  unansweredMessagesCount?: number;

  lastCase?: Case;
  cases?: Case[];
  isLoadingCase?: boolean;
  lastTicket?: Ticket;
}

export interface Ticket {
  id: string;
  companyId: string;
  applicantPhoneNumber: string;
  createdAt: number;
  updatedAt: number;
  closedAt?: number;
  closedBy?: string;
  createdBy?: string;
  responsibleUserId?: string;
  department?: Department;
  activityAreas?: ActivityArea[];
  status: TicketStatus;
  suppressInactiveMessage?: boolean;
  step?: TicketStep;
  summary?: string;
  classification?: string;
}

export enum TicketStep {
  TICKET_STARTED = "TICKET_STARTED",
  TICKET_FAILED = "TICKET_FAILED",
  SUMMARY_GENERATED = "SUMMARY_GENERATED",
  LAWYER_INVOKED_BY_APPLICANT = "LAWYER_INVOKED_BY_APPLICANT",
  EXCEEDED_PROGRESS_LIMIT = "EXCEEDED_PROGRESS_LIMIT",
}

export enum TicketStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
}

export const isCompleteTicket = (ticket: Partial<Ticket>): ticket is Ticket => {
  return !!(
    ticket.id &&
    ticket.companyId &&
    ticket.applicantPhoneNumber &&
    ticket.createdAt &&
    ticket.updatedAt &&
    ticket.status
  );
};

export enum ChatStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
}

export enum ChatMode {
  AI = "ai",
  MANUAL = "manual",
  AI_PAUSED_FOR_CURRENT_TICKET = "ai_paused_for_current_ticket",
}

export interface CaseMessage {
  chatId: string;
  from: string;
  lawyerId?: string;
  fromLawyer: boolean;
  fromAssistant: boolean;
  id: string;
  to: string;
  applicantPhoneNumber: string;
  body: string;
  type: CaseMessageType;
  isForwarded: boolean;
  timestamp: number;
  media?: {
    filename: string;
    mimeType: string;
    url: string;
    error?: boolean;
  };
  transcription?: string;
  applicantName?: string;
  statusUpdateTimestamp?: Partial<Record<MessageStatus, number>>;
  contacts?: Array<{
    phones: Array<{
      wa_id?: string;
      phone: string;
      type?: string;
    }>;
    name: string;
  }>;
  location?: {
    latitude: number;
    longitude: number;
  };
  reactions?: Array<{
    id: string;
    from: string;
    timestamp: number;
    emoji: string;
  }>;

  isSending?: boolean;
  requestId?: string;

  snippetInfo?: SnippetInfo;
}

export type SnippetInfo = TicketClosedSnippet;

export type SnippetMessageType = TicketClosedSnippet | TicketInactiveSnippet;

export enum SnippetTypes {
  TICKET_CLOSED = "TICKET_CLOSED",
  TICKET_INACTIVE = "TICKET_INACTIVE",
}

interface TicketClosedSnippet {
  type: SnippetTypes.TICKET_CLOSED;
  closedBy?: string;
  closedAt: number;
}

export interface TicketInactiveSnippet {
  type: SnippetTypes.TICKET_INACTIVE;
}

export enum MessageStatus {
  Sent = "sent",
  Delivered = "delivered",
  Read = "read",
  Failed = "failed",
}

export interface CaseMessageWithMedia extends CaseMessage {
  media: {
    filename: string;
    mimeType: string;
    url: string;
    error?: boolean;
  };
}

export type CaseMessageToSend =
  | {
      body: string;
      type: CaseMessageToSendType;
      media?: {
        filename: string;
        url: string;
        mimeType: string;
        location: string;
      };
    }
  | TemplateMessageParams;

export enum ConnectionStatus {
  CONNECTING = "connecting",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
}

export type CaseFileType =
  | {
      type: "RECORD";
      name: string;
      createdAt: number;
      recordType: RecordType;
      id: number;
      threadId: string;
    }
  | {
      type: "MEDIA";
      name: string;
      url: string;
      mimeType: string;
      createdAt: number;
      id: string;
    }
  | {
      type: "LEGAL_PROCEEDING_ATTACHED_FILE";
      name: string;
      createdAt: number;
      id: string;
      cnj: string;
      instance: string;
      integration: string;
      integrationFileId: string;
      legalProceedingStepId: string;
      extension: string;
    };

export enum ChatFilter {
  MY_CHATS = "MY_CHATS",
  AWAITING_HUMAN = "AWAITING_HUMAN",
  ARCHIVED = "ARCHIVED",
}

export interface ChatFilterOption {
  label: string;
  value: ChatFilter;
}

export interface ChatFilters {
  activityAreas: ActivityAreaOptions;
  options: ChatFilterOption[];
}
