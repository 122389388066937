import { useNavigate } from "react-router-dom";

import { ROUTE_PATHS } from "@/routes/routePaths";

import { useCompanyTeam } from "../company/useCompanyTeam";
import { useLexZapCases } from "../lexZap/useLexZapCases";
import { useCredits } from "../credits/useCredits";
import { PlanType } from "../credits/types";
import { planTitles } from "../credits";

export const usePlanChips = () => {
  const { data: credits } = useCredits();
  // TODO: fetch only the count of active cases
  const { data: cases } = useLexZapCases();
  // TODO: fetch only the count of users
  const { data: users } = useCompanyTeam();
  const navigate = useNavigate();

  const plan = credits?.companyPlan?.plan;
  const nextBillingDate = credits?.companyPlan?.nextBillingDate;
  const planName = plan && planTitles[plan][0];
  const isTestingPlan = plan === PlanType.TRIAL || plan === PlanType.FREEMIUM;
  const activeCasesQuota = credits?.companyPlan?.limitQuotaCases || 0;
  const usersQuota = credits?.companyPlan?.limitQuotaUsers || 0;
  const activeCasesCount = cases?.filter((c) => c.status === "ACTIVE").length || 0;
  const usersCount = users?.length || 0;

  function handleOpenCasesScreen() {
    navigate(ROUTE_PATHS.CASES);
  }

  function handleOpenUsersScreen() {
    navigate(ROUTE_PATHS.CLIENT_CONFIG_MY_TEAM);
  }

  return {
    planName,
    isTestingPlan,
    nextBillingDate,
    activeCasesQuota,
    usersQuota,
    activeCasesCount,
    usersCount,
    plan,
    handleOpenCasesScreen,
    handleOpenUsersScreen,
  };
};
