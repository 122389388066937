import { useMemo } from "react";
import { PlanType } from "../credits/types";
import { useFeatureFlags } from "../useFeatureFlags";
import { NewPlan } from "@/contexts/PaymentContext";
import { ROUTE_PATHS } from "@/routes/routePaths";

export const useNewPlans = () => {
  const {
    onlyUnlimitedPlanEnabled,
    unlimitedPlanEnabled,
    newPlansEnabled,
    whatsAppAssistantEnabled,
    newPlansInCheckoutEnabled,
  } = useFeatureFlags();

  const PLANS: { [key: string]: NewPlan } = {
    [PlanType.INITIAL]: {
      id: PlanType.INITIAL,
      title: "Inicial",
      subtitle: "Até 5 peças por mês",
      description:
        "Comece a utilizar o Assistente Lexter com um custo acessível. Utilize o melhor da inteligência artificial gerando até 5 peças por mês.",
      price: 59,
      credits: 1000,
      perksDescription: ["Atendimento via e-mail"],
    },
    [PlanType.BASIC]: {
      id: PlanType.BASIC,
      title: "Básico",
      subtitle: "Até 10 peças por mês",
      description: "Transforme suas tarefas jurídicas diárias com o melhor da inteligência artificial.",
      price: 99,
      credits: 2000,
      perksDescription: ["Atendimento via e-mail"],
    },
    [PlanType.INTERMEDIARY]: {
      id: PlanType.INTERMEDIARY,
      title: "Intermediário",
      subtitle: "Até 22 peças por mês",
      description: "Potencialize seu trabalho jurídico diário usando inteligência artificial de ponta.",
      price: 199,
      credits: 4500,
    },
    [PlanType.ADVANCED]: {
      id: PlanType.ADVANCED,
      title: "Avançado",
      subtitle: "Até 35 peças por mês",
      description: "Leve a eficiência ao máximo em suas tarefas jurídicas com o auxílio da inteligência artificial.",
      price: 299,
      credits: 7000,
    },
    [PlanType.UNLIMITED]: {
      id: PlanType.UNLIMITED,
      title: "Essencial",
      description: "Para criar peças e documentos",
      subtitle: "Para criar peças e documentos",
      price: 199,
      credits: Infinity,
      withoutPerksList: ["<strong>Lia</strong>, Assistente de IA para atendimento de clientes no Whatsapp."],
      perksDescription: ["<strong>Laura</strong>, a assistente jurídica 24 horas por dia."],
    },
    [PlanType.PROFESSIONAL_WITH_WPP]: {
      id: PlanType.PROFESSIONAL_WITH_WPP,
      title: "Profissional",
      description: "Para acelerar o seu negócio",
      subtitle: "Para acelerar o seu negócio",
      price: 799,
      credits: Infinity,
      perksDescription: [
        "Assistente de IA para atendimento de clientes no Whatsapp",
        "Gestor de casos, clientes e tarefas integrado a todos os assistentes de IA com 200 casos ativos",
        "Criação de peças e documentos com IA",
        "Tarefas jurídicas otimizadas com IA",
        "Suporte via e-mail",
      ],
    },
    [PlanType.PERFORMANCE]: {
      id: PlanType.PERFORMANCE,
      title: "Performance",
      description: "Para alcançar mais clientes",
      subtitle: "Para alcançar mais clientes",
      price: 1499,
      credits: Infinity,
      perksDescription: [
        "Assistente de IA para atendimento de clientes no Whatsapp",
        "Gestor de casos, clientes e tarefas integrado a todos os assistentes de IA com 500 casos ativos",
        "Criação de peças e documentos com IA",
        "Tarefas jurídicas otimizadas com IA",
        "Suporte jurídico e técnico dedicado",
      ],
    },
  };

  return useMemo((): NewPlan[] => {
    const internalPlans = Object.values(PLANS).map((plan) => {
      switch (plan.id) {
        case PlanType.INITIAL:
        case PlanType.BASIC:
          return { ...plan, hidden: onlyUnlimitedPlanEnabled };
        case PlanType.INTERMEDIARY:
        case PlanType.ADVANCED:
          return { ...plan, hidden: unlimitedPlanEnabled || onlyUnlimitedPlanEnabled };
        case PlanType.PROFESSIONAL_WITH_WPP:
        case PlanType.PERFORMANCE:
          return {
            ...plan,
            hidden: !newPlansEnabled || !whatsAppAssistantEnabled,
            buttonRedirectTo: !newPlansInCheckoutEnabled ? ROUTE_PATHS.WHATSAPP_ASSISTANT : undefined,
          };
        default:
          return { ...plan, hidden: false };
      }
    });

    return internalPlans.filter((plan) => !plan.hidden);
  }, [unlimitedPlanEnabled, onlyUnlimitedPlanEnabled, newPlansEnabled, whatsAppAssistantEnabled]);
};
