import React, { useMemo } from "react";
import { FilterAlt as FilterAltIcon } from "@mui/icons-material";
import { Box, Button, Chip, Typography } from "@mui/material";
import { FilterModal } from "../FilterModal";
import { ChatFilters } from "@/hooks/lexZap/types";

interface FilterProps {
  onChange: (filters: ChatFilters) => void;
  selectedFilters: ChatFilters;
  chatFilters: ChatFilters;
  totalResults: number;
}

export const Filter = ({ onChange, selectedFilters, chatFilters, totalResults }: FilterProps) => {
  const [isFilterModalOpen, setIsFilterModalOpen] = React.useState<boolean>(false);
  const handleChange = (newFilterOptions: ChatFilters) => {
    onChange(newFilterOptions);
  };

  const hasFilters = useMemo(() => {
    return selectedFilters.options.length > 0 || selectedFilters.activityAreas.length > 0;
  }, [selectedFilters.options, selectedFilters.activityAreas]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Button
        sx={{
          height: hasFilters ? "32px" : "48px",
          width: "48px",
          minWidth: "48px",
          minHeight: "32px",
          backgroundColor: "orange.light.30",
          "&:hover": {
            backgroundColor: "orange.light.20",
          },
        }}
        onClick={() => setIsFilterModalOpen(true)}
      >
        <FilterAltIcon
          sx={{
            color: "grey.700",
          }}
          fontSize={hasFilters ? "small" : "medium"}
        />
      </Button>

      {selectedFilters.options.length + selectedFilters.activityAreas.length > 0 && (
        <Chip
          sx={{
            backgroundColor: "info.light",
            borderRadius: "8px",
            textOverflow: "ellipsis",
            "& .MuiChip-label": {
              fontWeight: "500",
            },
          }}
          label={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              {selectedFilters.options.length ? (
                <Typography>{selectedFilters.options[0].label}</Typography>
              ) : (
                selectedFilters.activityAreas.length && (
                  <Typography>
                    Área:{" "}
                    {selectedFilters.activityAreas.length < 3
                      ? selectedFilters.activityAreas.map((area) => area.label).join(", ")
                      : selectedFilters.activityAreas
                          .slice(0, 2)
                          .map((area) => area.label)
                          .join(", ") + `, +${selectedFilters.activityAreas.length - 2}`}
                  </Typography>
                )
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "error.main",
                  width: "20px",
                  height: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "common.white",
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                  variant="body1"
                >
                  {totalResults}
                </Typography>
              </Box>
            </Box>
          }
        />
      )}
      {((selectedFilters.options.length > 0 && selectedFilters.activityAreas.length > 0) ||
        selectedFilters.options.length > 1) && (
        <Chip
          label={`+${selectedFilters.options.length + selectedFilters.activityAreas.length - 1}`}
          sx={{
            backgroundColor: "info.light",
            borderRadius: "8px",
            "& .MuiChip-label": {
              fontWeight: "500",
            },
          }}
        />
      )}
      {isFilterModalOpen && (
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          chatFilters={chatFilters}
          selectedFilters={selectedFilters}
          onConfirm={handleChange}
        />
      )}
    </Box>
  );
};
