import { Autocomplete, Box, createFilterOptions, TextField } from "@mui/material";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { ChangeEvent, useMemo } from "react";
import { WebToast } from "@//components/core/Toast";
import { useCreateCase } from "@/hooks/lexZap/useCreateCase";
import { useApplicants } from "@/hooks/applicants/useApplicants";
import { ApplicantModal } from "@/components/Applicants";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { Case } from "@/hooks/lexZap/types";

const CREATE_APPLICANT_ID = "__create__";

type BaseApplicant = {
  id: string;
  name: string;
  label: string;
};

type AutocompleteProps = React.ComponentProps<typeof Autocomplete<BaseApplicant>>;

type Form = {
  name: string;
  applicantId: string | null;
  applicantName: string | null;
  applicantPhoneNumber: string | null;
};

const filter = createFilterOptions<BaseApplicant>();

export function CreateCaseModal({
  onClose,
  onConfirm,
}: {
  onClose: () => void;
  onConfirm?: (selectedCase: Case | null) => void;
}) {
  const { data: applicants, isLoading: isLoadingApplicants } = useApplicants();
  const { mutateAsync: createCase, isPending: isCreatingCase } = useCreateCase();
  const [clientModalData, setClientModalData] = React.useState<{ name: string }>();
  const [form, setForm] = React.useState<Form>({
    name: "",
    applicantId: null,
    applicantName: null,
    applicantPhoneNumber: null,
  });

  const applicantFormOptions = useMemo(() => {
    return (
      applicants?.map((applicant) => ({
        id: applicant.id,
        name: applicant.name,
        label: applicant.name,
        phoneNumber: applicant.phoneNumber,
      })) ?? []
    );
  }, [applicants]);

  const applicantFormValue = useMemo(() => {
    return applicantFormOptions?.find((applicant) => applicant.id === form.applicantId) ?? null;
  }, [applicantFormOptions, form.applicantId]);

  const handleCreateCase = async () => {
    if (form.name === "") {
      return;
    }
    const createdCase = await createCase({
      name: form.name,
      applicantId: form.applicantId,
      applicantName: form.applicantName,
      applicantPhoneNumber: form.applicantPhoneNumber,
    });
    onClose();
    if (createdCase && onConfirm) {
      onConfirm(createdCase);
    }
    return WebToast.success("Caso criado com sucesso");
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
  };

  const handleChangeSelect = <K extends keyof Form>(prop: K) => {
    const handler: AutocompleteProps["onChange"] = (_e, value) => {
      if (value) {
        if (value.id === CREATE_APPLICANT_ID) {
          setClientModalData({ name: value.name });
        } else {
          setForm((prevState) => ({
            ...prevState,
            [prop]: value.id,
            applicantName: value.name,
            applicantPhoneNumber: applicants?.find((applicant) => applicant.id === value.id)?.phoneNumber ?? null,
          }));
        }
      }
    };
    return handler;
  };

  const closeEditClientModal = (applicantId?: string) => {
    setClientModalData(undefined);
    if (applicantId) {
      setForm((prevState) => ({
        ...prevState,
        applicantId,
      }));
    }
  };

  const saveButtonDisabled = form.name === "" || isLoadingApplicants || isCreatingCase;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Criar caso"}
        size={"SMALL"}
        onClose={onClose}
        footer={
          !isLoadingApplicants &&
          !isCreatingCase && (
            <ModalFooter
              confirmLabel={"Criar"}
              onConfirm={handleCreateCase}
              cancelLabel="Cancelar"
              onCancel={onClose}
              saveButtonDisabled={saveButtonDisabled}
            />
          )
        }
        open={true}
      >
        <Loading isLoading={isLoadingApplicants || isCreatingCase}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "32px",
              }}
            >
              <>
                <TextField
                  placeholder="Nome do caso"
                  onChange={handleChangeForm("name")}
                  value={form.name}
                  label="Nome do caso"
                />

                <Autocomplete<BaseApplicant>
                  value={applicantFormValue}
                  onChange={handleChangeSelect("applicantId")}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      id: CREATE_APPLICANT_ID,
                      label: `Adicionar "${params.inputValue || "Novo cliente"}"`,
                      name: params.inputValue,
                    });

                    return filtered;
                  }}
                  options={applicantFormOptions}
                  getOptionLabel={(option) => option.label}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                      label="Cliente"
                      placeholder="Selecione o cliente"
                      fullWidth
                    />
                  )}
                  fullWidth
                />
              </>
            </Box>
          </Box>
        </Loading>
      </ModalRight>

      {!!clientModalData && (
        <ApplicantModal isOpen={true} onClose={closeEditClientModal} defaultApplicant={clientModalData} />
      )}
    </Box>
  );
}
