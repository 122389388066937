import { Config } from "@/config/Config";
import { env } from "@/constants/environment";
import { getDeviceMetadata } from "@/contexts/MessagesContext/utils";
import * as logger from "@/core/logger";
import { EventEmitter } from "@/utils/eventEmitter";
import { outsideOfficeClient } from "@/utils/outsideOfficeClient";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { WebSocketContext } from "./context";
import {
  ConnectionState,
  OnMessageReceivedParam,
  OnNotificationReceivedParams,
  OnThreadConnectParams,
  OnThreadErrorParams,
  WebsocketMessageType,
  WebsocketSendParams,
} from "./types";
import { WhatsappUpdate } from "./types/whatsapp.types";
import { useAuthStore } from "@/stores/useAuthStore";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { AuthService } from "@/services/auth";
import { useInterval } from "@/hooks/useInterval";

const config = Config.getConfig();
const SOCKET_HEARTBEAT_INTERVAL = 1000 * 60 * 3; //3 minutes

interface WebSocketProviderProps {
  children: ReactNode;
}

const getSocketUrl = ({ loginType, token }: { loginType?: string; token?: string }) =>
  loginType && token ? `${env.WEBSOCKET_URL}?token=${token}&loginType=${loginType}&currentDate=${Date.now()}` : "";

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
  const { loginType, token } = useAuthStore();

  const threadConnectedEvent = useMemo(() => new EventEmitter<OnThreadConnectParams>(), []);
  const threadErrorEvent = useMemo(() => new EventEmitter<OnThreadErrorParams>(), []);
  const messageReceivedEvent = useMemo(() => new EventEmitter<OnMessageReceivedParam>(), []);
  const whatsAppUpdateEvent = useMemo(() => new EventEmitter<WhatsappUpdate>(), []);
  const notificationReceivedEvent = useMemo(() => new EventEmitter<OnNotificationReceivedParams>(), []);

  const [socketUrl, setSocketUrl] = useState(() => getSocketUrl({ token, loginType }));

  const updateSocketUrl = useCallback(async () => {
    try {
      const { loginType, token } = await AuthService.getValidToken();
      setSocketUrl(getSocketUrl({ loginType, token }));
    } catch (error) {
      logger.error("Error updating socket url", error);
    }
  }, []);

  const { sendMessage, readyState } = useWebSocket(socketUrl, {
    share: true,
    reconnectAttempts: 5,
    retryOnError: true,
    onClose: updateSocketUrl,
    onError: updateSocketUrl,
    onReconnectStop: () => {
      logger.warn("Socket failed to reconnect");
    },
    onMessage: (event) =>
      messageHandler({
        event,
        threadConnectedEvent,
        threadErrorEvent,
        messageReceivedEvent,
        whatsAppUpdateEvent,
        notificationReceivedEvent,
      }),
  });

  const connectionState = useMemo(() => {
    if (readyState === ReadyState.OPEN) {
      return ConnectionState.OPEN;
    } else if (readyState === ReadyState.CONNECTING) {
      return ConnectionState.CONNECTING;
    } else {
      return ConnectionState.CLOSED;
    }
  }, [readyState]);

  const send = useCallback(
    ({ type, data }: WebsocketSendParams) => {
      const isOutsideOfficeClient = outsideOfficeClient();

      sendMessage(
        JSON.stringify({
          action: "sendmessage",
          type,
          data,
          metadata: {
            platform: isOutsideOfficeClient ? "assistente-web" : "add-in",
            device: getDeviceMetadata(),
            sessionSettings: config.sessionSettings.toHeaders(),
          },
        })
      );
    },
    [sendMessage]
  );

  useInterval(
    useCallback(() => {
      try {
        sendMessage(JSON.stringify({ action: "ping" }));
      } catch (e) {
        logger.warn("Error sending ping", e);
      }
    }, [sendMessage]),
    SOCKET_HEARTBEAT_INTERVAL
  );

  return (
    <WebSocketContext.Provider
      value={{
        connectionState,
        send,
        messageReceivedEvent,
        threadConnectedEvent,
        whatsAppUpdateEvent,
        threadErrorEvent,
        notificationReceivedEvent,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

const messageHandler = ({
  event,
  threadConnectedEvent,
  threadErrorEvent,
  messageReceivedEvent,
  whatsAppUpdateEvent,
  notificationReceivedEvent,
}: {
  event: MessageEvent<string>;
  threadConnectedEvent: EventEmitter<OnThreadConnectParams>;
  threadErrorEvent: EventEmitter<OnThreadErrorParams>;
  messageReceivedEvent: EventEmitter<OnMessageReceivedParam>;
  whatsAppUpdateEvent: EventEmitter<WhatsappUpdate>;
  notificationReceivedEvent: EventEmitter<OnNotificationReceivedParams>;
}) => {
  if (event.data === "Pong") {
    return;
  }

  const message = JSON.parse(event.data);

  if (message.type === WebsocketMessageType.THREAD_CONNECT) {
    const { error } = message.data;

    if (error) {
      logger.error(`Thread connect error`, error);
      threadErrorEvent.emit({
        threadId: message.data.threadId,
        error,
      });
    } else {
      const { threadId, threadName, messages, openDocumentsIds } = message.data;

      threadConnectedEvent.emit({
        threadId,
        threadName,
        messages,
        openDocumentsIds,
      });
    }
    return;
  }

  if (message.type === WebsocketMessageType.NOTIFICATION) {
    const { notification } = message.data;
    notificationReceivedEvent.emit(notification);
    return;
  }

  if (message.type === WebsocketMessageType.WHATSAPP_UPDATE) {
    whatsAppUpdateEvent.emit(message.data);
    return;
  }

  try {
    messageReceivedEvent.emit(message);
    logger.info(`Successfully handling message from websocket`, message);
  } catch (e) {
    if (e instanceof Object && "message" in e) {
      logger.error(`Error handling message from websocket`, e.message);
    }
    throw e;
  }
};
