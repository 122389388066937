import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case } from "../types";
import { isAxiosError } from "axios";
import { logger } from "@/core/logger";
import { WebToast } from "@/components/core/Toast";

const CASES_QUERY_KEY = "lexZapCases";

export const useUnarchiveCases = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["unarchiveCases"],
    mutationFn: ({ casesIds }: { casesIds: string[] }) =>
      Promise.all(casesIds.map((caseId) => api.unarchiveCase({ caseId }))),
    onMutate: async ({ casesIds }) => {
      await queryClient.cancelQueries({ queryKey: [CASES_QUERY_KEY] });
      const previousCases = queryClient.getQueryData<string[]>([CASES_QUERY_KEY]);
      queryClient.setQueryData([CASES_QUERY_KEY], (old: Case[]) => [
        ...old.map((c) => (casesIds.includes(c.id) ? { ...c, status: "ACTIVE" } : c)),
      ]);
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      handleErrorUnarchivingCases(_error);
      queryClient.setQueryData([CASES_QUERY_KEY], context?.previousCases);
    },
  });
};

const handleErrorUnarchivingCases = (error: unknown) => {
  logger.error("handleErrorUnarchivingCases", error);
  if (isAxiosError(error) && error.response?.status === 402) {
    WebToast.error("Você atingiu o limite máximo de casos");
  } else {
    WebToast.error("Erro ao desarquivar caso");
  }
};
