import React from "react";
import { Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CourtCredentialType } from "@/services/legalProceeding/types";
import { tjNameToFullName } from "@/utils/tjNameToFullName";
import { ActionCell } from "./Actions";

export const createCourtCredentialColumns = (
  openAuthorizeAccessModal: (row: CourtCredentialType) => void,
  openRevokeAccessModal: (row: CourtCredentialType) => void
): GridColDef<CourtCredentialType>[] => [
  {
    field: "tj",
    headerName: "Orgão de Origem",
    width: 400,
    valueGetter: (value) => {
      return tjNameToFullName[value];
    },
  },
  {
    field: "status",
    type: "custom",
    headerName: "Autorização",
    width: 150,
    renderCell: (params) => (
      <Chip
        label={params.value === "ACTIVE" ? "Autorizada" : "Não autorizada"}
        size="small"
        sx={{
          bgcolor: params.value === "ACTIVE" ? "success.main" : "error.main",
          color: "white",
        }}
      />
    ),
  },
  {
    field: "actions",
    headerName: "",
    width: 150,
    renderCell: (params) => (
      <ActionCell
        openRevokeAccessModal={openRevokeAccessModal}
        openAuthorizeAccessModal={openAuthorizeAccessModal}
        row={params.row}
      />
    ),
  },
];
