import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LexZapService } from "@/services/lexZap";
import { ChatStatus, LexZapChat } from "../types";
import { getChatsQueryKey } from "../useLexZapChats";

export const useUnarchiveChat = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["unarchiveChat"],
    mutationFn: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) =>
      await LexZapService.unarchiveChat({ applicantPhoneNumber }),
    onMutate: async ({ applicantPhoneNumber }) => {
      await queryClient.cancelQueries({ queryKey: getChatsQueryKey() });
      const previousChats = queryClient.getQueryData(getChatsQueryKey());

      queryClient.setQueryData(getChatsQueryKey(), (old: LexZapChat[] | undefined) => {
        if (!old) return [];
        return old.map((chat) =>
          chat.endClientNumber === applicantPhoneNumber
            ? {
                ...chat,
                status: ChatStatus.ACTIVE,
              }
            : chat
        );
      });

      return { previousChats };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(getChatsQueryKey(), context?.previousChats);
    },
  });
};
