import { CaseFileItem } from "@/core/CaseFile";
import { CaseService } from "@/services/case";
import { getBlobTypeByFileType } from "@/utils/getBlobTypeByFileType";
import { getFileExtensionByFileType } from "@/utils/getFileExtensionByFileType";
import { saveFileAs } from "@/utils/saveFileAs";
import { useMutation } from "@tanstack/react-query";

export const useDownloadCaseLegalProceedingsFiles = () => {
  const api = CaseService;

  return useMutation({
    mutationKey: ["downloadCaseLegalProceedingsFiles"],
    mutationFn: async ({ caseId, file }: { caseId: string; file: CaseFileItem }) => {
      return await api.downloadCaseFile(caseId, file.id as string);
    },
    onSuccess: (data, { file }) => {
      const legalProceedingFile = file;
      const blob = new Blob([data], {
        type: getBlobTypeByFileType(legalProceedingFile.extension!),
      });

      const url = URL.createObjectURL(blob);

      const fileExtension = getFileExtensionByFileType(legalProceedingFile.extension!);
      saveFileAs(url, `${legalProceedingFile.name}.${fileExtension}`);
    },
  });
};
