import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { Case } from "../types";
import { useImpersonation } from "@/hooks/useImpersonation";
import { AdminLexZapService } from "@/services/lexZap";

export const getCasesQueryKey = () => ["lexZapCases"];

export const useLexZapCases = () => {
  const queryClient = useQueryClient();
  const { getLexZapCases } = useApi();
  const { impersonatedUser } = useImpersonation();

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (impersonatedUser) {
        return;
      }

      if (update.type === WhatsappUpdateType.CASE_UPDATE) {
        queryClient.setQueryData<Case[]>(getCasesQueryKey(), (oldCases = []) => {
          const caseExists = oldCases.some((caseItem) => caseItem.id === update.caseData.id);
          if (caseExists) {
            return oldCases.map((caseItem) =>
              caseItem.id === update.caseData.id ? { ...caseItem, ...update.caseData } : caseItem
            );
          } else {
            const updatedCases = oldCases.filter((caseItem) => caseItem.chatId !== update.caseData.chatId);

            return [...updatedCases, update.caseData];
          }
        });
      }
    },
  });

  return useQuery<Case[]>({
    queryKey: getCasesQueryKey(),
    queryFn: async () => {
      if (impersonatedUser) {
        return AdminLexZapService.getLexZapCases({ companyId: impersonatedUser.companyId });
      }

      return getLexZapCases();
    },
    staleTime: 1000 * 60,
    refetchInterval: (query) => {
      if (query.state.data?.some((c) => c.cnjs && c.cnjs.length > 0 && c.name === "")) {
        return 10000;
      }

      return false;
    },
  });
};
