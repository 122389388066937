import { CourtCredentialType } from "@/services/legalProceeding/types";

import { useAuthContext } from "@/contexts/AuthContext";

import { useCourtCredentials } from "@/hooks/courtCredentials/useCourtCredentials";
import React, { useEffect, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { RevokeAccessModal } from "../RevokeAccessModal";
import { AuthorizeAccessModal } from "../AuthorizeAccessModal";
import { ptBR } from "@mui/x-data-grid/locales";
import { createCourtCredentialColumns } from "./Columns";
import { Box } from "@mui/material";

interface CourtCredentialsListProps {
  tj?: string[];
}

export const CourtCredentialsList = ({ tj }: CourtCredentialsListProps) => {
  const { user } = useAuthContext();
  const {
    data: paginatedRecords,
    isFetching: isFetchingCourtCredentials,
    isLoading: isLoadingCourtCredentials,
  } = useCourtCredentials({
    userId: user?.userId,
  });
  const [openAuthorizeAccessModal, setOpenAuthorizeAccessModal] = React.useState(false);
  const [openRevokeAccessModal, setOpenRevokeAccessModal] = React.useState(false);
  const [selectedCourtCredential, setSelectedCourtCredential] = React.useState<CourtCredentialType | null>(null);
  const [tjToAdd, setTjToAdd] = React.useState<CourtCredentialType[]>([]);

  useEffect(() => {
    const tjSet = new Set(tj);
    const tjToAdd = Array.from(tjSet).filter((tj) => !paginatedRecords?.find((record) => record.tj === tj));
    setTjToAdd(
      tjToAdd.map((tj) => ({
        id: tj,
        createdAt: new Date(),
        updatedAt: new Date(),
        status: "INACTIVE",
        tj,
      }))
    );
  }, [paginatedRecords, tj]);

  const onOpenAuthorizeAccessModal = (row: CourtCredentialType) => {
    setSelectedCourtCredential(row);
    setOpenAuthorizeAccessModal(true);
  };

  const onOpenRevokeAccessModal = (row: CourtCredentialType) => {
    setSelectedCourtCredential(row);
    setOpenRevokeAccessModal(true);
  };

  const data = [...(tjToAdd ?? []), ...(paginatedRecords ?? [])];

  const loading = useMemo(() => {
    return isLoadingCourtCredentials || isFetchingCourtCredentials;
  }, [isLoadingCourtCredentials, isFetchingCourtCredentials]);

  return (
    <>
      <AuthorizeAccessModal
        open={openAuthorizeAccessModal}
        onClose={() => setOpenAuthorizeAccessModal(false)}
        tjToAdd={tjToAdd ?? []}
        selectedCourtCredential={selectedCourtCredential}
      />
      <RevokeAccessModal
        open={openRevokeAccessModal}
        onClose={() => setOpenRevokeAccessModal(false)}
        row={selectedCourtCredential}
      />
      <Box sx={{ height: loading ? "525px" : "auto" }}>
        <DataGrid
          rows={data ?? []}
          getRowId={(row) => row.id}
          columns={createCourtCredentialColumns(onOpenAuthorizeAccessModal, onOpenRevokeAccessModal)}
          loading={loading}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          autoHeight={loading ? false : true}
          sx={{
            borderRadius: 2,
            "& .MuiDataGrid-cell": {
              pl: 2,
            },
            "& .MuiDataGrid-columnHeader": {
              pl: 2,
            },
            "& .MuiDataGrid-virtualScroller": {
              overflow: loading ? "hidden" : "scroll",
            },
          }}
          slotProps={{
            loadingOverlay: {
              variant: "skeleton",
              noRowsVariant: "skeleton",
            },
          }}
          slots={{
            footer: () => <></>,
          }}
          disableColumnFilter
          disableColumnResize
          disableColumnSorting
          disableColumnMenu
          disableRowSelectionOnClick
        />
      </Box>
    </>
  );
};
