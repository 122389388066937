export enum TemplateKey {
  ReactivateChat = "reactivate_chat",
  InitChat = "init_chat",
}

export type TemplateParams = {
  [TemplateKey.ReactivateChat]: {
    message: string;
  };
  [TemplateKey.InitChat]: {
    applicantName: string;
    message: string;
  };
};

interface BaseSendMessageParams {
  replyToMessageId?: string;
}

type TemplateMessageParamMap = {
  [K in TemplateKey]: {
    type: "template";
    templateKey: K;
    templateParams: TemplateParams[K];
  } & BaseSendMessageParams;
};

export type TemplateMessageParams = TemplateMessageParamMap[keyof TemplateMessageParamMap];
