import { DateTime } from "luxon";

export const dateTimeFromString = (str: string) => {
  let isoString = str.trim();

  if (!isoString.includes("T")) {
    isoString = isoString.replace(" ", "T");
  }
  if (!isoString.endsWith("Z")) {
    isoString += "Z";
  }

  return DateTime.fromISO(isoString);
};

export const dateTimeFromTimestamp = (timestamp: number) => {
  return DateTime.fromMillis(timestamp);
};
