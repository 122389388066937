import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { CircularProgress, Box, Typography } from "@mui/material";

interface ArchiveUnarchiveChatModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  action: "archive" | "unarchive";
}

export const ArchiveUnarchiveChatModal = ({
  isOpen,
  onConfirm,
  onCancel,
  isLoading,
  action,
}: ArchiveUnarchiveChatModalProps) => {
  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title={action === "archive" ? "Arquivar conversa" : "Desarquivar conversa"}
      description={
        <Box>
          {action === "archive" ? (
            <Typography>
              <Typography>
                Confirmando a Lia, nossa assistente baseada em inteligência artificial deixará de atuar nesta conversa,
                e seu cliente dependerá da sua atuação. Você também pode esconder essa conversa removendo o filtro de
                arquivadas.
              </Typography>
            </Typography>
          ) : (
            <Typography>
              Confirmando a Lia, nossa assistente baseada em inteligência artificial voltará a atuar nesta conversa a
              partir do próximo atendimento. A Lia não assume conversas que já estão em andamento.
            </Typography>
          )}

          {isLoading && (
            <Box display="flex" color="secondary" justifyContent="center" mt={1}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      }
      descriptionTextAlign="center"
      buttonsDirection="row"
      buttons={[
        {
          label: "Cancelar",
          onClick: onCancel,
          sx: {
            color: "primary.main",
            backgroundColor: "transparent",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          },
        },
        {
          label: "Confirmar",
          onClick: onConfirm,
          sx: {
            fontWeight: 600,
          },
          color: "primary",
          disabled: isLoading,
        },
      ]}
      variant="error"
    />
  );
};
