import { SearchOutlined } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";

interface SearchProps {
  placeholder?: string;
  onChange?: (value: string) => void;
}

export const Search = ({ placeholder, onChange }: SearchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <TextField
      sx={{
        width: "100%",
        bgcolor: "common.white",
        height: "52px",

        borderRadius: "24px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "24px",
          "& fieldset": {
            borderColor: "common.lightShade",
          },
          "&:hover fieldset": {
            borderColor: "common.lightShade",
          },
          "&.Mui-focused fieldset": {
            borderColor: "common.lightShade",
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined sx={{ color: "common.lightShade" }} />
          </InputAdornment>
        ),
      }}
      hiddenLabel
      variant="outlined"
      placeholder={placeholder || "Buscar conversa"}
      onChange={handleChange}
      size="medium"
    />
  );
};
