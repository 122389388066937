import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCompanyTeamQueryKey } from "../useCompanyTeam";
import { CompanyService } from "@/services/company";

export const useResendCompanyMemberInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["useResendCompanyMemberInvitation"],
    mutationFn: async ({ memberId }: { memberId: string }) => {
      await CompanyService.resendTeamMemberInvitation({ memberId });
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: getCompanyTeamQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getCompanyTeamQueryKey());
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCompanyTeamQueryKey(), context?.previousCases);
    },
  });
};
