import { CPF } from "@/core/CPF";
import { z } from "zod";

export type Applicant = {
  id: string;
  companyId: string;
  createdAt: string;
  name: string;
  phoneNumber: string | null;
  city: string | null;
  cpf: string | null;
  email: string | null;
  houseNumber: string | null;
  lastModifiedAt: string | null;
  maritalStatus: string | null;
  occupation: string | null;
  profilePictureUrl: string | null;
  state: string | null;
  streetName: string | null;
  userId: string | string;
  zipCode: string | null;
};

export const applicantPayloadSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .max(200, { message: "Máximo de 200 caracteres." }),
  phoneNumber: z
    .string()
    .min(1, { message: "Campo com preenchimento obrigatório." })
    .regex(/^\(?[1-9]{2}\)? ?(?:[2-8]|9?[0-9])[0-9]{3}-?[0-9]{4}$/, {
      message: "Telefone inválido",
    })
    .optional(),
  city: z.string().optional(),
  cpf: z
    .string()
    .refine((val) => (val === "" ? true : CPF.fromString(val).isSuccess), {
      message: "CPF inválido",
    })
    .optional(),
  email: z
    .string()
    .max(200, { message: "Máximo de 200 caracteres." })
    .email({ message: "E-mail inválido." })
    .optional()
    .or(z.literal("")),
  houseNumber: z.string().optional(),
  lastModifiedAt: z.string().optional(),
  maritalStatus: z.string().optional(),
  occupation: z.string().optional(),
  profilePictureUrl: z.string().optional(),
  state: z.string().optional(),
  streetName: z.string().optional(),
  userId: z.string().optional(),
  zipCode: z.string().optional(),
});

export type ApplicantPayload = z.infer<typeof applicantPayloadSchema>;

export const contactPayloadSchema = z.object({
  name: z.string().min(1, { message: "Campo obrigatório." }).max(200, { message: "Máximo de 200 caracteres." }),
  phoneNumber: z
    .string()
    .min(1, { message: "Campo obrigatório." })
    .regex(/^\(?[1-9]{2}\)? ?(?:[2-8]|9[0-9])[0-9]{3}-?[0-9]{4}$/, {
      message: "Número inválido",
    }),
});

export type ContactPayload = z.infer<typeof contactPayloadSchema>;
