import { alpha, Box, Collapse, IconButton, useTheme } from "@mui/material";
import React, { ReactNode, useState } from "react";
import {
  ExpandLessRounded as ExpandLessRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
} from "@mui/icons-material";

interface ExpandableCardProps {
  header: string | ReactNode;
  children: ReactNode;
}

export const ExpandableCard = ({ header, children }: ExpandableCardProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "6px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          px: 2,
          cursor: "pointer",
        }}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {header}

        <IconButton>
          <ExpandIcon fontSize="medium" sx={{ color: alpha(theme.palette.common.black, 0.38) }} />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box
          sx={{
            padding: 2,
            paddingTop: 0,
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
