import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import React from "react";
import { LexZapChat } from "@/hooks/lexZap/types";
import { formatPhoneNumber } from "@/utils/lexZap/formatPhoneNumber";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
interface ChatHeaderProps {
  chat?: LexZapChat;
  isLoading?: boolean;
  showOpenTicketDetails: boolean;
  handleOpenTicketDetails: () => void;
}

export const ChatHeader = ({ chat, isLoading, showOpenTicketDetails, handleOpenTicketDetails }: ChatHeaderProps) => {
  if (isLoading || !chat) {
    return <ChatHeaderSkeleton />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "grey.100",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopRightRadius: "24px",
      }}
    >
      <Box>
        <Typography
          variant="body"
          color="#09132C"
          sx={{
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          {chat.applicantName || formatPhoneNumber(chat.endClientNumber)}
        </Typography>

        <Typography
          variant="body"
          color="grey.700"
          sx={{
            fontSize: "18px",
            fontWeight: 400,
          }}
        >
          {formatPhoneNumber(chat.endClientNumber)}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {showOpenTicketDetails && (
          <IconButton onClick={handleOpenTicketDetails}>
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const ChatHeaderSkeleton = () => {
  return (
    <Box
      sx={{
        backgroundColor: "grey.100",
        gap: 2,
        paddingX: 3,
        paddingRight: 6,
        paddingY: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTopRightRadius: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Skeleton variant="text" width={200} />

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          <Skeleton variant="circular" height={8} width={8} />
          <Skeleton variant="text" width={50} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      ></Box>
    </Box>
  );
};
