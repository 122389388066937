import { useQuery } from "@tanstack/react-query";

import { LexZapService } from "@/services/lexZap";
import { isAxiosError } from "axios";
import { useAuthContext } from "@/contexts/AuthContext";

export const getTicketsQueryKey = () => ["lexZapGetTickets"];

export const useLexZapGetTickets = ({ status }: { status?: "ACTIVE" | "CLOSED" } = {}) => {
  const { user } = useAuthContext();
  return useQuery({
    queryKey: getTicketsQueryKey(),
    queryFn: async () => {
      try {
        const response = await LexZapService.getTickets();

        const active = response.filter((ticket) => ticket.status === "ACTIVE");
        const closed = response.filter((ticket) => ticket.status === "CLOSED");

        switch (status) {
          case "ACTIVE":
            return active;
          case "CLOSED":
            return closed;
          default:
            return response.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        }
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          return null;
        }
        throw error;
      }
    },
    enabled: !!user?.companyId,
  });
};
