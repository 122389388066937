import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

export const HideMenusPageLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  const toSmallHeight = useMediaQuery("(max-height: 740px)");
  let height = "100%";
  if (toSmallHeight) {
    height = "120%";
  }
  return (
    <Box
      sx={{
        [theme.breakpoints.up("md")]: {
          display: "grid",
          gridTemplateColumns: "1fr 1.25fr",
          "& > div": {
            textAlign: "center",
            border: "1px solid grey",
            borderColor: "grey.200",
          },
          width: "100vw",
          height: "100%",
          // Adicionar versionamento de temas
          "#svg-path-brand-color": {
            fill: "#FF7548",
          },
        },

        [theme.breakpoints.up("xl")]: {
          gridTemplateColumns: "700px 1.25fr",
        },

        [theme.breakpoints.down("md")]: {
          width: "100vw",
          display: "flex",
          height: height,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      {children}
    </Box>
  );
};
