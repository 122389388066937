import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useState } from "react";

import { useMessagesContext } from "@/contexts/MessagesContext";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { useEditorUI } from "@/hooks/useEditorUI";
import { formattedDateTimeFromISO } from "@/utils/dates/formattedDateTimeFromISO";
import { ArrowOutward, DeleteForever, Edit } from "@mui/icons-material";
import { OrderRecordsBy, RecordType, ThreadRecord } from "@/hooks/records/types";
import { ArchiveDocumentModal } from "../ArchiveDocumentModal";
import { RenameDocumentModal } from "../RenameDocumentModal";
import { PrecedentContent } from "./PrecedentContent";
import { orderRecordsOptions, useRecords } from "@/hooks/records/useRecords";
import { useThreadContext } from "@/contexts/ThreadContext";
import { useNavigate } from "react-router-dom";
import { getSkillGroupPath } from "@/routes/routePaths";

type DocumentsProps = {
  goBack: () => void;
};

export const Documents = ({ goBack }: DocumentsProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentThreadId } = useThreadContext();

  const { handleOpenDocument, openNewDocumentTab } = useWebEditorContext();
  const { waitingForResponse } = useMessagesContext();
  const [isOpenRenameModal, setIsOpenRenameModal] = React.useState(false);
  const [isOpenArchiveModal, setIsOpenArchiveModal] = React.useState(false);
  const [currentEditingRecord, setCurrentEditingRecord] = React.useState<ThreadRecord | null>(null);
  const { openModal } = useEditorUI();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [currentListOrder, setCurrentListOrder] = React.useState<OrderRecordsBy>("CREATED_AT");
  const [currentQuerySearch, setCurrentQuerySearch] = useState<string>("");
  const [query, setQuery] = React.useState<string>(currentQuerySearch);

  const { data: paginatedRecords, isLoading } = useRecords({
    pagination: { ...paginationModel, page: paginationModel.page + 1 },
    search: query,
    order: currentListOrder,
    threadId: currentThreadId,
  });

  const handleUpdateOrder = (order: OrderRecordsBy) => {
    setCurrentListOrder(order);
  };

  const searchQuery = async (text: string = query) => {
    if (text === currentQuerySearch) return;
    setCurrentQuerySearch(text);
  };

  const clearQuery = () => {
    setQuery("");
    searchQuery("");
  };

  const handleOpenRenameModal = (record: ThreadRecord) => {
    setCurrentEditingRecord(record);
    setIsOpenRenameModal(true);
  };

  const handleCloseRenameModal = () => {
    setIsOpenRenameModal(false);
    setCurrentEditingRecord(null);
  };

  const handleOpenArchiveModal = (record: ThreadRecord) => {
    setCurrentEditingRecord(record);
    setIsOpenArchiveModal(true);
  };

  const handleCloseArchiveModal = () => {
    setIsOpenArchiveModal(false);
    setCurrentEditingRecord(null);
  };

  const handleOpenRecord = async (record: ThreadRecord) => {
    switch (record.type) {
      case RecordType.DOCUMENT: {
        handleOpenDocument(record.id);
        goBack();
        break;
      }
      case RecordType.PRECEDENT: {
        openModal(<PrecedentContent precedent={record} />);
        break;
      }
    }
  };

  const openNewDocument = () => {
    openNewDocumentTab();
    goBack();
  };

  const handleCreateNewPiece = () => {
    goBack();

    if (!waitingForResponse) {
      navigate(getSkillGroupPath({ skillGroupId: "create_new_document" }));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        padding: 3,
        pb: 1,
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontSize: "20px" }}>
          Documentos
        </Typography>
        <GoBackButton goBack={goBack} />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 2,
          }}
        >
          <Button onClick={openNewDocument} variant="contained" endIcon={<AddIcon />} sx={{ flex: 1, flexGrow: 1 }}>
            Novo documento
          </Button>
          <FormControl sx={{ flex: 1, flexGrow: 1 }}>
            <InputLabel shrink={true} id="select-state-label">
              Ordernar por
            </InputLabel>
            <Select
              labelId="select-state-label"
              value={currentListOrder}
              size="small"
              onChange={(e) => handleUpdateOrder(e.target.value as OrderRecordsBy)}
              label="Ordernar por"
            >
              {Object.entries(orderRecordsOptions).map(([key, value], i) => (
                <MenuItem key={i} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <TextField
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: Boolean(query) ? (
              <InputAdornment position="end">
                <IconButton onClick={clearQuery} size="small" sx={{ color: "primary.dark" }}>
                  <CloseIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
          value={query}
          size="small"
          label="Buscar"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchQuery();
            }
          }}
          onBlur={() => {
            searchQuery();
          }}
        />
      </Box>
      <Divider />
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        {!isLoading &&
        !(Array.isArray(paginatedRecords) ? paginatedRecords[0]?.data.length : paginatedRecords?.data.length) ? (
          <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography variant="multiLineBody" sx={{ textAlign: "center" }}>
              Você ainda não possui documentos salvos.<br></br>
              <Link
                onClick={handleCreateNewPiece}
                sx={{
                  fontFamily: `${theme.typography.fontFamily} !important`,
                  fontWeight: "700",
                  ":hover": { textDecoration: "none" },
                }}
              >
                Clique aqui
              </Link>{" "}
              para começar a criar sua primeira peça.
            </Typography>
          </Box>
        ) : (
          <DataGrid
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            getRowId={(row) => `${row.type}-${row.id}`}
            loading={isLoading}
            localeText={{
              ...ptBR.components.MuiDataGrid.defaultProps.localeText,
            }}
            onRowClick={(params) => {
              if (params.row) {
                handleOpenRecord(params.row);
              }
            }}
            columns={[
              {
                field: "document",
                headerName: "Documento",
                flex: 1,
                renderCell: ({ row: threadRecord }) => <Typography variant={"body"}>{threadRecord.name}</Typography>,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
              },
              {
                field: "createdAt",
                headerName: "Data de criação",
                flex: 1,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: ({ row: threadRecord }) => (
                  <Typography variant={"body"}>{formattedDateTimeFromISO(threadRecord.createdAt)}</Typography>
                ),
              },
              {
                field: "type",
                headerName: "Tipo",
                flex: 1,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell: ({ row: threadRecord }) => (
                  <Typography variant={"body"}>{recordTypeToLabel(threadRecord.type)}</Typography>
                ),
              },
              {
                field: "actions",
                type: "actions",
                align: "center",
                getActions: ({ row: threadRecord }) => [
                  <GridActionsCellItem
                    key={1}
                    divider
                    icon={<ArrowOutward />}
                    onClick={() => handleOpenRecord(threadRecord)}
                    label="Abrir documento"
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={2}
                    divider
                    icon={<Edit />}
                    onClick={() => handleOpenRenameModal(threadRecord)}
                    label="Renomear"
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={3}
                    icon={<DeleteForever />}
                    onClick={() => handleOpenArchiveModal(threadRecord)}
                    label="Deletar"
                    showInMenu
                  />,
                ],
              },
            ]}
            rows={Array.isArray(paginatedRecords) ? paginatedRecords[0]?.data || [] : paginatedRecords?.data || []}
            rowSelection={false}
            rowCount={
              Array.isArray(paginatedRecords)
                ? paginatedRecords[0]?.totalResults || 0
                : paginatedRecords?.totalResults || 0
            }
            hideFooterPagination={isLoading}
          />
        )}
      </Box>
      <RenameDocumentModal
        isOpen={isOpenRenameModal}
        currentEditingRecord={currentEditingRecord}
        onClose={handleCloseRenameModal}
        threadId={currentThreadId}
      />
      <ArchiveDocumentModal
        isOpen={isOpenArchiveModal}
        currentEditingRecord={currentEditingRecord}
        onClose={handleCloseArchiveModal}
        documentName={currentEditingRecord?.name || ""}
        threadId={currentThreadId}
      />
    </Box>
  );
};

const recordTypeToLabel = (type: RecordType) => {
  switch (type) {
    case RecordType.DOCUMENT:
      return "Documento";
    case RecordType.PRECEDENT:
      return "Acórdão";
  }
};

const GoBackButton = ({ goBack }: { goBack: () => void }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        onClick={goBack}
        startIcon={<ArrowBackIcon />}
        sx={{
          color: "text.primary",
          alignSelf: "center",
        }}
      >
        Voltar
      </Button>
    </Box>
  );
};
