import { env } from "@/constants/environment";
import { CompleteProfilePayload, GetCompanyDetailsResponse } from "@/hooks/useApi/types";
import axios from "axios";

const lexterApi = axios.create({
  baseURL: env.API_HOST,
  headers: {
    "x-lexter-origin": "copilot-app",
  },
});

export const LexterApiService = {
  getCompanyDetailsById: async (companyId: string, token: string) => {
    const customHeader = { Authorization: `bearer ${token}` };
    return lexterApi
      .get<GetCompanyDetailsResponse>(`/companies/${companyId}`, {
        headers: { ...customHeader, "x-company-id": companyId },
      })
      .then((res) => res.data);
  },
  completeProfile: async ({ userId, companyId, token, ...payload }: CompleteProfilePayload) => {
    const customHeader = { Authorization: `bearer ${token}` };
    return lexterApi
      .put(
        `/users/${userId}/completeProfile`,
        {
          ...payload,
        },
        {
          headers: { ...customHeader, "x-company-id": companyId },
        }
      )
      .then((res) => res.data);
  },
};
