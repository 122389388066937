import { SvgIconComponent } from "@mui/icons-material";
import { CircularProgress, LinearProgress, Button as MuiButton, ButtonProps as MuiButtonProps } from "@mui/material";
import React from "react";

export interface ButtonProps extends Omit<MuiButtonProps, "startIcon" | "endIcon"> {
  startIcon?: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
  endIcon?: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
  isLoading?: boolean;
  bgcolor?: string;
  loadingColor?: string;
  loaderStyle?: React.CSSProperties;
  borderVariant?: "rounded";
  textAlign?: "center";
  variant?: "contained" | "outlined" | "text";
}

export const Button = ({
  children,
  bgcolor,
  loadingColor,
  isLoading,
  startIcon: StartIcon,
  endIcon: EndIcon,
  loaderStyle,
  borderVariant,
  textAlign,
  size,
  variant = "text",
  ...rest
}: ButtonProps) => {
  if (isLoading && variant !== "text") {
    return (
      <LinearProgress
        sx={{
          width: "100%",
          height: "40px",
          borderRadius: "5px",
          ...loaderStyle,
          bgcolor: loadingColor,
          "& .MuiLinearProgress-bar": {
            bgcolor,
          },
        }}
      />
    );
  }

  const styles =
    size === "small"
      ? {
          minHeight: "32px !important",
          height: "32px !important",
          "& .MuiButton-root": {
            minHeight: "32px !important",
            height: "32px !important",
          },
          "& .MuiButton-startIcon": {
            marginRight: "4px !important",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "18px !important",
          },
          fontSize: "13px",
          padding: "4px 12px !important",
        }
      : {};

  return (
    <MuiButton
      {...rest}
      sx={{
        position: "relative",
        px: (StartIcon || EndIcon) && textAlign === "center" ? "44px" : undefined,
        borderRadius: borderVariant === "rounded" ? "20px" : undefined,
        bgcolor,
        ":hover": {
          bgcolor,
        },
        ...styles,
        ...rest.sx,
      }}
      startIcon={StartIcon ? <StartIcon /> : undefined}
      endIcon={EndIcon ? <EndIcon /> : undefined}
      variant={variant}
    >
      {isLoading && variant === "text" ? <CircularProgress size={20} /> : children}
    </MuiButton>
  );
};

interface LinkButtonProps extends ButtonProps {
  capitalize?: boolean;
}

export const LinkButton = ({
  children,
  loadingColor,
  isLoading,
  startIcon: StartIcon,
  endIcon: EndIcon,
  loaderStyle,
  capitalize,
  sx,
  ...rest
}: LinkButtonProps) => {
  return (
    <Button
      bgcolor="none"
      loadingColor={loadingColor}
      isLoading={isLoading}
      startIcon={StartIcon}
      endIcon={EndIcon}
      loaderStyle={loaderStyle}
      {...rest}
      sx={{
        color: "common.shade",
        textDecoration: "underline",
        textUnderlineOffset: "2px",
        textTransform: capitalize ? "capitalize" : "uppercase",
        ":hover": {
          textTransform: capitalize ? "capitalize" : "uppercase",
          bgcolor: "unset",
          textDecoration: "underline",
          textUnderlineOffset: "2px",
        },
        "&:focus": {
          boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

export const UnderlinedButton = ({ children, sx, ...rest }: ButtonProps) => {
  return (
    <Button
      {...rest}
      sx={{
        textDecoration: "underline",
        textUnderlineOffset: "2px",
        width: "fit-content",
        m: 0,
        p: 0,
        minHeight: "14px",
        "&:hover": {
          textDecoration: "underline",
          textUnderlineOffset: "2px",
          bgcolor: "transparent",
          fontWeight: "bold !important",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};
