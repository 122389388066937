import React, { useState } from "react";
import { Box, ListItem, List, Typography, useMediaQuery, ListItemIcon } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import WomanInSuitSmiling from "@/assets/images/womanInSuitSmiling.jpg";
import Calendar from "@/assets/svgs/calendar.svg";
import ConversaCliente from "@/assets/svgs/conversa-cliente.svg";
import ConversaUsuario from "@/assets/svgs/conversa-usuario.svg";
import Notificacoes from "@/assets/svgs/notificacoes.svg";
import { Button } from "@/components/Button";
import { LandingPageCard } from "./components/LandingPageCard";
import WhatsAppAssistantFormModal from "./components/WhatsAppAssistantFormModal";
import { Circle as CircleIcon } from "@mui/icons-material";

export const WhatsappAssistantLanding = () => {
  const isMobile = useMediaQuery("(max-width:1200px)");
  const isShort = useMediaQuery("(max-height:850px)");

  const [isOpenWhatsAppAssistantFormModal, setIsOpenWhatsAppAssistantFormModal] = useState(false);

  const handleOpenWhatsAppAssistantFormModal = () => {
    setIsOpenWhatsAppAssistantFormModal(true);
  };

  const handleCloseWhatsAppAssistantFormModal = () => {
    setIsOpenWhatsAppAssistantFormModal(false);
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
      }}
      contentSx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: isMobile ? "flex-start" : "center",
        alignItems: isShort && !isMobile ? "flex-start" : "center",
        overflow: "auto",
        width: "100%",
        height: "100%",
        px: 6,
        py: 10,
        backgroundColor: "common.white",
      }}
    >
      <Box
        sx={{
          display: isMobile ? "flex" : "grid",
          height: isMobile ? "auto" : "100%",
          gridTemplateColumns: isMobile ? undefined : "1fr 1fr",
          flexDirection: isMobile ? "column" : undefined,
          width: "100%",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            height: isMobile ? "auto" : "100%",
            width: isMobile ? "100%" : "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              width: isMobile ? "100%" : "auto",
              overflowX: "hidden",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <Box
              component="img"
              src={WomanInSuitSmiling}
              sx={{
                width: "434px",
                height: "512px",
                borderRadius: "16px",
                objectFit: "cover",
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "flex-start", width: "100%" }}>
              <Typography
                sx={{
                  width: isMobile ? "100%" : "433px",
                }}
                variant="h6"
              >
                Seus clientes estão esperando. Seu escritório pode ser mais eficiente.
              </Typography>
              <Button onClick={handleOpenWhatsAppAssistantFormModal} variant="contained">
                Contratar a Lia
              </Button>
            </Box>
            <Box sx={{ paddingBottom: 6 }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            height: "100%",
            overflowY: "hidden",
            overflowX: "hidden",
            paddingBottom: 6,
          }}
        >
          <Box>
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              Lia,
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 400 }}>
              Especialista digital
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-track": {
                borderRadius: "10px",
                backgroundColor: "common.white",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "grey.400",
              },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%", maxWidth: "568px" }}>
              <Typography variant="h6" sx={{ fontWeight: 400, fontSize: "16px" }}>
                Oi, eu sou a <b>Lia</b>, sua agente virtual de relacionamento. Cuido de todo o contato externo com os
                clientes por e-mail e WhatsApp, garantindo que o atendimento do seu escritório seja ágil, organizado e
                profissional.
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: 700, fontSize: "16px" }}>
                Comigo, seus clientes ficam sempre bem atendidos e informados, enquanto você foca no que importa.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4, alignItems: isMobile ? "center" : undefined }}>
              <LandingPageCard
                imageUrl={Calendar}
                title="Esqueça horário de atendimento"
                description="Comigo, a comunicação com os clientes nunca para. Estou sempre à disposição para atender vários clientes simultaneamente, garantindo que não vamos perder clientes ou oportunidades importantes."
                openFormModal={handleOpenWhatsAppAssistantFormModal}
              />
              <LandingPageCard
                imageUrl={Notificacoes}
                title="Você segue no controle"
                description="Comigo, você tem total transparência e autonomia. Acompanhe o andamento do atendimento a qualquer momento e intervenha sempre que necessário e o histórico é sempre seu. O controle está sempre em suas mãos."
                openFormModal={handleOpenWhatsAppAssistantFormModal}
              />
              <LandingPageCard
                imageUrl={ConversaCliente}
                title="Você sempre vai estar sabendo de tudo"
                description="Todas as atualizações e status do meu trabalho estão a um clique de distância. Envio relatórios organizados e informações detalhadas para que você tenha total controle e clareza sobre as demandas do dia a dia."
                openFormModal={handleOpenWhatsAppAssistantFormModal}
              />
              <LandingPageCard
                imageUrl={ConversaUsuario}
                title="Pare de perder leads"
                description="Não perca nenhum potencial cliente! Cuido do primeiro contato, envio informações relevantes e mantenho o acompanhamento necessário para transformar oportunidades em resultados concretos."
                openFormModal={handleOpenWhatsAppAssistantFormModal}
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: "568px",
                  backgroundColor: "orange.light.5",
                  borderRadius: "16px",
                  p: 2,
                  gap: 2,
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  Habilidades
                </Typography>
                <List
                  sx={{
                    "& .MuiListItemIcon-root": {
                      minWidth: "20px",
                    },
                    width: "100%",
                  }}
                >
                  <ListItemStyled>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                      Atendimento simultâneo a múltiplos clientes, <TextBoldInline>sem atrasos</TextBoldInline>;
                    </Typography>
                  </ListItemStyled>
                  <ListItemStyled>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                      <TextBoldInline>Disponibilidade 24/7</TextBoldInline> para responder mensagens e acompanhar
                      processos;
                    </Typography>
                  </ListItemStyled>
                  <ListItemStyled>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                      Elaboração e envio de <TextBoldInline>relatórios personalizados</TextBoldInline> e atualizações em
                      tempo real;
                    </Typography>
                  </ListItemStyled>
                  <ListItemStyled>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                      Gestão de leads, garantindo acompanhamento e{" "}
                      <TextBoldInline>aquecimento de potenciais clientes.</TextBoldInline>
                    </Typography>
                  </ListItemStyled>
                </List>

                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  Idiomas
                </Typography>
                <List
                  sx={{
                    "& .MuiListItemIcon-root": {
                      minWidth: "20px",
                    },
                    width: "100%",
                  }}
                >
                  <ListItemStyled>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "16px" }}>
                      Português
                    </Typography>
                  </ListItemStyled>
                  <ListItemStyled>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                      Inglês
                    </Typography>
                  </ListItemStyled>
                  <ListItemStyled>
                    <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                      Espanhol
                    </Typography>
                  </ListItemStyled>
                </List>

                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  Objetivo
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 400, fontSize: "14px" }}>
                  Facilitar o dia a dia do seu escritório jurídico, otimizando o tempo da equipe e garantindo um
                  atendimento ágil e profissional aos seus clientes.
                </Typography>

                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  Contato
                </Typography>
                <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                  <Button
                    sx={{
                      backgroundColor: "secondary.main",
                      "&:hover": {
                        backgroundColor: "orange.light.30",
                      },
                    }}
                    onClick={handleOpenWhatsAppAssistantFormModal}
                    variant="contained"
                  >
                    Contratar a Lia
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <WhatsAppAssistantFormModal
        open={isOpenWhatsAppAssistantFormModal}
        onClose={handleCloseWhatsAppAssistantFormModal}
      />
    </PageLayout>
  );
};

const ListItemStyled = ({ children }: { children: React.ReactNode }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <CircleIcon
          sx={{
            width: "10px",
            color: "common.black",
          }}
        />
      </ListItemIcon>
      {children}
    </ListItem>
  );
};

const TextBoldInline = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography variant="body1" sx={{ fontWeight: 700, fontSize: "14px", color: "primary.main", display: "inline" }}>
      {children}
    </Typography>
  );
};
