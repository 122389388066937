import { SkillGroup } from "@/core/skillGroups/types";
import { CreditPerSkill } from "@/core/skills/types";

export const CreateANewDocumentSkillGroup: SkillGroup = {
  id: "create_new_document",
  name: "Escrever novo documento",
  subtitle: "Selecione o tipo de peça:",
  credits: CreditPerSkill.ADVANCED_SKILL,
  skillShortcuts: [
    {
      label: "Petição inicial",
      skillId: "create_initial_petition",
      flag: "skillInitialPetitionV1Enabled",
      category: "Peças judiciais",
    },
    {
      label: "Petição inicial",
      skillId: "create_initial_petition_v2",
      flag: "skillInitialPetitionV2Enabled",
      category: "Peças judiciais",
    },
    { label: "Reclamação trabalhista", skillId: "create_labor_complaint", category: "Peças judiciais" },
    { label: "Contestação", skillId: "create_contestation", category: "Peças judiciais" },
    { label: "Réplica", skillId: "create_reply", category: "Peças judiciais" },
    { label: "Petição intercorrente", skillId: "intercurrent_motion", category: "Peças judiciais" },
    { label: "Apelação", skillId: "create_appeal", category: "Peças judiciais" },
    { label: "Recurso ordinário trabalhista", skillId: "create_ordinary_appeal", category: "Peças judiciais" },
    { label: "Outro tipo de peça", skillId: "create_other_type_of_piece", category: "Peças judiciais" },
    { label: "Contrato", skillId: "create_contract", category: "Contratos" },
    { label: "Proposta de acordo", skillId: "settlement_offer", category: "Contratos" },
    { label: "Contrato de honorários", skillId: "fee_agreement", category: "Outros tipos de documentos" },
  ],
  tags: ["recommend", "litigation"],
};
