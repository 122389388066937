import { exportHtmlToDocx } from "@/contexts/WebEditorContext/plugins";
import { Editor as TinyMCEEditor } from "tinymce";
import { v4 as uuid } from "uuid";
import { Editor, EditorEvent, OpenFormattedDocumentParams, Paragraph } from "../types";
import { formattedTextToHtml } from "./formattedTextToHtml";
import { paragraphsToHtml } from "./paragraphsToHtml";

export class WebEditor implements Editor {
  private editor: TinyMCEEditor;

  constructor(editor: TinyMCEEditor) {
    this.editor = editor;
  }

  async getSelection() {
    if (!this.editor || !this.editor.selection) {
      return "";
    }
    return this.editor.selection.getContent({ format: "text" });
  }

  async getHtmlSelection() {
    if (!this.editor || !this.editor.selection) {
      return "";
    }
    return this.editor.selection.getContent({ format: "html" });
  }

  async clearSelection() {
    this.editor.selection.collapse();
  }

  async getBody() {
    return this.editor.getContent({ format: "text" });
  }

  async getContent() {
    return this.editor.getContent({ format: "html" });
  }

  async openFormattedText({ document: { id, name, formattedText } }: OpenFormattedDocumentParams) {
    if (id) {
      await this.editor.tabs.openDocument(id);
    } else {
      const html = formattedTextToHtml(formattedText);

      this.editor.tabs.addTab({
        id: uuid(),
        documentId: id,
        label: name || "Novo Documento",
        content: html,
      });
    }

    return {};
  }

  async replaceBody(text: string) {
    this.setContent(text);
    return {};
  }

  async replaceSelection(text: string) {
    this.editor.selection.setContent(text);
    this.editor.undoManager.add();
    return {};
  }

  async replaceHtmlSelection(html: string) {
    const isValidHtml = (html: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
    };

    if (isValidHtml(html)) {
      this.editor.selection.setContent(html);
    } else {
      this.editor.selection.setContent(this.editor.dom.encode(html));
    }

    this.editor.undoManager.add();
    return {};
  }

  async checkIfDocumentIsReadOnly(): Promise<boolean> {
    return false;
  }

  async exportToDocx() {
    await exportHtmlToDocx(this.editor.getContent());
  }

  async insertParagraphs(paragraphs: Paragraph[]) {
    const html = paragraphsToHtml({ paragraphs });
    this.editor.dom.insertAfter(this.editor.dom.createFragment(html), this.editor.selection.getEnd());
    this.editor.undoManager.add();
  }

  private setContent(content: string) {
    this.editor.setContent(content);
    this.editor.undoManager.add();
  }

  addEventListener(event: EditorEvent, listener: () => void) {
    switch (event) {
      case EditorEvent.SELECTION_CHANGE: {
        this.editor.on("nodeChange", listener);
        break;
      }
      default: {
        throw new Error(`Event not supported: ${event}`);
      }
    }
  }
}
