import React from "react";
import { Box, List, ListItem, Typography, Link } from "@mui/material";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { CouponButton } from "./CouponButton";
import { PaymentResume } from "./PaymentResume";
import { SelectPlan } from "./SelectPlan";
import { useNavigate } from "react-router-dom";
import { Check } from "@mui/icons-material";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { PerksItem } from "../../PlansPageWithUnlimited/NewPlanCard";

export const PlanInformation = () => {
  const { removeCoupon } = usePaymentContext();
  const { onlyUnlimitedPlanEnabled } = useFeatureFlags();

  const navigate = useNavigate();

  const handleViewAllPlans = () => {
    removeCoupon();
    navigate(ROUTE_PATHS.PLANS);
  };

  return (
    <Box sx={{ py: 3, minWidth: "42%", maxWidth: "42%" }}>
      <Box
        sx={{
          bgcolor: "common.white",
          border: "1px solid black",
          borderColor: "common.lightShade",
          borderRadius: "5px",
          px: 4,
          py: 3,
          mb: 3,
          gap: 5,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SelectPlan />
        <PlanResume />
        <CouponButton />
        <PaymentResume />
      </Box>
      {!onlyUnlimitedPlanEnabled && (
        <Link
          sx={{
            textAlign: "center",
            textDecoration: "underline",
            cursor: "pointer",
            display: "block",
          }}
          onClick={handleViewAllPlans}
        >
          Visualizar todos os planos
        </Link>
      )}
    </Box>
  );
};

const PlanResume = () => {
  const { selectedPlan } = usePaymentContext();
  const flags = useFeatureFlags();

  const showCredits = !flags.planScreenV2Enabled;

  return (
    <Box>
      <Typography mb={1.5} variant="body">
        <strong>Inclui: </strong>
      </Typography>
      <List
        sx={{
          "& .MuiListItem-root": {
            pl: 0,
          },
        }}
      >
        {showCredits && (
          <ListItem>
            <Check sx={{ mr: 0.5 }} fontSize="inherit" />
            <Typography variant="body2" fontWeight={700}>
              <> Créditos ilimitados mensais</>
            </Typography>
          </ListItem>
        )}

        {(selectedPlan.perksDescription ?? []).map((perk, index) => (
          <PerksItem key={index}>{perk}</PerksItem>
        ))}
        {(selectedPlan.withoutPerksList ?? []).map((perk, index) => (
          <PerksItem key={index} notIncludePerk>
            {perk}
          </PerksItem>
        ))}
      </List>
    </Box>
  );
};
