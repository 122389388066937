import { useMutation } from "@tanstack/react-query";
import { logger } from "@/core/logger";
import { LexZapService } from "@/services/lexZap";

export const useDeactivateAi = () => {
  return useMutation({
    mutationKey: ["deactivateAi"],
    mutationFn: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
      return await LexZapService.deactivateAi({ applicantPhoneNumber });
    },
    onError: (error) => {
      logger.error("Error in useDeactivateAi:", error);
    },
  });
};
