import { Box, Collapse } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { useLexZapMessages } from "@/hooks/lexZap/useLexZapMessages";
import { ChatMode, LexZapChat } from "@/hooks/lexZap/types";
import { ChatList } from "./components/ChatList";
import { Chat } from "./components/Chat";
import { useSocket } from "@/contexts/WebSocketContext";
import { WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import MessageReceivedSound from "@/assets/sounds/whatsapp-message-received.mp3";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { useLexZapChats } from "@/hooks/lexZap/useLexZapChats";
import { TicketDetails } from "./components/TicketDetails";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useResizable } from "@/components/EditorPageLayout/useResizable";
import { Resizer } from "@/components/EditorPageLayout/components/Resizer";
import { useDebouncedCallback } from "@/hooks/useDebouncedCallback";

const minChatListWidth = 300;
const minChatWidth = 500;
const minTicketDetailsWidth = 200;

export const ChatView = ({
  chats,
  isLoadingChats,
  setupCollapseHeader,
}: {
  chats: LexZapChat[] | undefined;
  isLoadingChats: boolean;
  setupCollapseHeader: (ref: React.MutableRefObject<HTMLElement | undefined>) => void;
}) => {
  const { whatsappTicketsEnabled } = useFeatureFlags();
  const [isTicketDetailsOpen, setIsTicketDetailsOpen] = useState(false);
  const { data: allChats } = useLexZapChats();
  const { activeChat } = useLexZapActiveChat();
  const activeChatIsNewChat = useMemo(() => {
    if (!activeChat) return false;

    const activeChatAlreadyExists = allChats?.some((chat) => chat.endClientNumber === activeChat.endClientNumber);

    return !activeChatAlreadyExists;
  }, [activeChat, allChats]);

  const isTicketDetailsModalOpen = isTicketDetailsOpen && !!activeChat?.lastTicket;

  const { data: messages, isFetching: isLoadingMessagesQuery } = useLexZapMessages({
    applicantPhoneNumber: activeChat?.endClientNumber,
    disabled: activeChatIsNewChat,
  });

  const handleOpenTicketDetails = () => {
    setIsTicketDetailsOpen(true);
  };

  const handleCloseTicketDetails = () => {
    setIsTicketDetailsOpen(false);
  };

  const isLoadingMessages = isLoadingChats || isLoadingMessagesQuery;

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED && !update.message.fromLawyer) {
        const chat = allChats?.find((chat) => chat.endClientNumber === update.message.applicantPhoneNumber);

        if (chat && chat.chatMode !== ChatMode.AI) {
          const audio = new Audio(MessageReceivedSound);
          void audio.play();
        }
      }
    },
  });

  const [ticketDetailsWidth, setTicketDetailsWidth] = useState(minTicketDetailsWidth);
  const debouncedSetTicketDetailsWidth = useDebouncedCallback(setTicketDetailsWidth, 1000);
  const ticketDetailsWidthRef = useRef<HTMLDivElement>(null);
  const {
    containerRef: chatContainerRef,
    handleMouseDown: handleTicketDetailsResize,
    isResizing: isResizingTicketDetails,
  } = useResizable({
    defaultWidth: 264,
    minWidth: minTicketDetailsWidth,
    minOppositeWidth: minChatWidth,
    direction: "right",
    onWidthChange: (width) => {
      if (ticketDetailsWidthRef.current) {
        ticketDetailsWidthRef.current.style.width = `${width}px`;
      }
      debouncedSetTicketDetailsWidth(width);
    },
  });

  const [chatListWidth, setChatListWidth] = useState(minChatListWidth);
  const debouncedSetChatListWidth = useDebouncedCallback(setChatListWidth, 1000);
  const {
    containerRef: mainContainerRef,
    handleMouseDown: handleChatListResize,
    isResizing: isResizingChatList,
  } = useResizable({
    defaultWidth: { value: 30, unit: "%" },
    minWidth: minChatListWidth,
    minOppositeWidth: minChatWidth + ticketDetailsWidth,
    direction: "left",
    onWidthChange: (width) => {
      if (mainContainerRef.current) {
        mainContainerRef.current.style.gridTemplateColumns = `${width}px minmax(0, 1fr)`;
      }
      debouncedSetChatListWidth(width);
    },
  });

  return (
    <Box
      ref={mainContainerRef}
      sx={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateColumns: `${chatListWidth}px minmax(0, 1fr)`,
        gridTemplateRows: "100%",
      }}
    >
      <Box sx={{ position: "relative", display: "flex" }}>
        <ChatList chats={chats} isLoading={isLoadingChats} setupCollapseHeader={setupCollapseHeader} />
        <Resizer onMouseDown={handleChatListResize} isResizing={isResizingChatList} />
      </Box>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          height: "100%",
        }}
        ref={chatContainerRef}
      >
        <Box sx={{ position: "relative", flex: 1, overflow: "hidden", minWidth: minChatWidth }}>
          <Chat
            messages={messages}
            isLoadingChats={isLoadingChats}
            isLoadingMessages={isLoadingMessages}
            showOpenTicketDetails={whatsappTicketsEnabled && !isTicketDetailsOpen && !!activeChat?.lastTicket}
            handleOpenTicketDetails={handleOpenTicketDetails}
            setupCollapseHeader={setupCollapseHeader}
          />
          {isTicketDetailsModalOpen && (
            <Resizer onMouseDown={handleTicketDetailsResize} isResizing={isResizingTicketDetails} />
          )}
        </Box>

        <Collapse
          in={isTicketDetailsModalOpen}
          orientation="horizontal"
          easing={{
            enter: "linear",
            exit: "linear",
          }}
        >
          <Box
            ref={ticketDetailsWidthRef}
            sx={{
              width: ticketDetailsWidth,
              height: "100%",
            }}
          >
            {activeChat && (
              <TicketDetails
                applicantName={activeChat?.applicantName}
                handleCloseTicketDetails={handleCloseTicketDetails}
                cases={activeChat?.cases}
                applicantPhoneNumber={activeChat?.endClientNumber}
              />
            )}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};
