import React from "react";
import { Box } from "@mui/material";
import { LegalProceedingsAttached } from "./components/LegalProceedingsAttached";
import { CaseSummary } from "./components/CaseSummary";
import { Case } from "@/hooks/lexZap/types";
import { ApplicantModal } from "@/components/Applicants";
import { EditCaseModal } from "@/components/Cases";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { CaseDetailsChat } from "@/components/Cases/CaseDetailsChat";
import { CaseCard } from "./components/CaseCard";
import { Button } from "@/components/Button";
import { CaseSuggestionSkills } from "@/components/Cases/CaseSuggestionSkills";
import { CaseFiles } from "@/components/Cases/CaseFiles";

interface CaseDetailsProps {
  caseData?: Case;
  caseId: string;
  showNextSteps: boolean;
  loadingCase: boolean;
  onDeleteCase: () => void;
  onArchiveCase: () => void;
  onActivateCase: () => void;
  onUnarchiveCase: () => void;
}

export const CaseDetails = ({
  caseData,
  loadingCase,
  onDeleteCase,
  onArchiveCase,
  onActivateCase,
  onUnarchiveCase,
}: CaseDetailsProps) => {
  const { legalProceedingsPageEnabled, caseSuggestionsEnabled } = useFeatureFlags();
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] = React.useState(false);
  const [isEditCaseModalOpen, setIsEditCaseModalOpen] = React.useState(false);

  const openEditApplicantModal = () => {
    setIsEditApplicantModalOpen(true);
  };

  const closeEditApplicantModal = () => {
    setIsEditApplicantModalOpen(false);
  };

  const openEditCaseModal = () => {
    setIsEditCaseModalOpen(true);
  };

  const closeEditCaseModal = () => {
    setIsEditCaseModalOpen(false);
  };

  const isCaseInactive = caseData?.status === "INACTIVE";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {isCaseInactive && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            px: 4,
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onActivateCase}
            disabled={loadingCase}
            sx={{
              textTransform: "none",
              mt: 1,
              height: "50px",
            }}
          >
            Ativar caso
          </Button>
        </Box>
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1.5fr 1fr",
          gridTemplateAreas: `"main sidebar"`,
          gap: 3,
          alignItems: "start",
          p: 4,
          pt: 2,
        }}
      >
        {caseData && (
          <ApplicantModal
            applicantId={caseData.applicantId}
            isOpen={isEditApplicantModalOpen}
            onClose={closeEditApplicantModal}
          />
        )}

        {caseData?.id && isEditCaseModalOpen && (
          <EditCaseModal caseId={caseData.id} isOpen={isEditCaseModalOpen} onClose={closeEditCaseModal} />
        )}

        <Box sx={{ gridArea: "main", display: "flex", flexDirection: "column", gap: 3 }}>
          <CaseCard
            caseData={caseData}
            loadingCase={loadingCase}
            openEditCaseModal={openEditCaseModal}
            openEditApplicantModal={openEditApplicantModal}
            onDelete={onDeleteCase}
            onArchive={onArchiveCase}
            onUnarchive={onUnarchiveCase}
            archived={caseData?.status === "ARCHIVED"}
          />
          <CaseSummary caseData={caseData} loadingCase={loadingCase} />
          {legalProceedingsPageEnabled && caseData?.id && <LegalProceedingsAttached caseData={caseData} />}
        </Box>

        <Box sx={{ gridArea: "sidebar", display: "flex", flexDirection: "column", gap: 3 }}>
          {/* {showCaseSuggestions && <CaseNextSteps caseId={caseData!.id} onStepClick={handleStepClick} />} */}
          {caseSuggestionsEnabled && <CaseSuggestionSkills caseData={caseData} loadingCase={loadingCase} />}
          <CaseDetailsChat
            caseData={caseData}
            loadingCase={loadingCase}
            archived={caseData?.status === "ARCHIVED"}
            handleEditApplicantModal={openEditApplicantModal}
          />
          {caseData?.id && <CaseFiles caseData={caseData} />}
        </Box>
      </Box>
    </Box>
  );
};
