import { alpha, Box, IconButton, MenuItem, Popover, Typography, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import { formatChatMessage } from "format-chat-message";
import parseStringToHtml from "html-react-parser";
import {
  CaseMessageType,
  CaseStep,
  ChatMode,
  ChatStatus,
  LexZapChat,
  TicketStatus,
  TicketStep,
} from "@/hooks/lexZap/types";
import { dayFromTimestamp } from "../../../../utils/dayFromTimestamp";
import { MessageStatus } from "@/components/LexZap/MessageStatus";
import { formatPhoneNumber } from "@/utils/lexZap/formatPhoneNumber";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { useDeactivateAi } from "@/hooks/lexZap/useDeactivateAi";
import { useActivateAi } from "@/hooks/lexZap/useActivateAi";
import { DeactivateAiModal } from "../DeactivateAiModal";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { getSnippetLabel } from "./utils/getSnippetLabel";
import { TicketDetailsModal } from "../TicketDetailsModal";
import { useUpdateTicket } from "@/hooks/lexZap/useUpdateTicket";
import { useAuthContext } from "@/contexts/AuthContext";
import { ArchiveUnarchiveChatModal } from "../ArchiveUnarchiveChatModal";
import { useArchiveChat } from "@/hooks/lexZap/useArchiveChat";
import { useUnarchiveChat } from "@/hooks/lexZap/useUnarchiveChat";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { ActivateAiModal } from "../ActivateAiModal";

interface ChatItemProps {
  isActive: boolean;
  onClick?: () => void;
  chat: LexZapChat;
}

const CaseTagSelector = {
  IA: {
    title: "Atendimento IA",
    textColor: "common.white",
    bgColor: "#B09B94",
  },
  SUMMARY_GENERATED: {
    title: "Resumo gerado",
    textColor: "common.white",
    bgColor: "success.light",
  },
  REDIRECTED_TO_HUMAN: {
    title: "Direcionado humano",
    textColor: "common.black",
    bgColor: "warning.light",
  },
  INACTIVE: {
    title: "IA inativa",
    textColor: "common.white",
    bgColor: "common.black",
  },
  ARCHIVED: {
    title: "Arquivada",
    textColor: "common.black",
    bgColor: "grey.200",
  },
};

export const ChatItem = ({ isActive, chat, onClick }: ChatItemProps) => {
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeactivateAiModalOpen, setIsDeactivateAiModalOpen] = useState(false);
  const [isActivateAiModalOpen, setIsActivateAiModalOpen] = useState(false);
  const [isTicketDetailsModalOpen, setIsTicketDetailsModalOpen] = useState(false);
  const [archiveUnarchiveModal, setArchiveUnarchiveModal] = useState<"archive" | "unarchive" | null>(null);
  const { mutateAsync: deactivateAi, isPending: isDeactivatingAi } = useDeactivateAi();
  const { mutateAsync: activateAi, isPending: isActivatingAi } = useActivateAi();
  const { mutateAsync: archiveChat, isPending: isArchivingChat } = useArchiveChat();
  const { mutateAsync: unarchiveChat, isPending: isUnarchivingChat } = useUnarchiveChat();
  const { data: responsibleUser } = useCompanyMember({ memberId: chat.lastTicket?.responsibleUserId });
  const { mutateAsync: updateTicket } = useUpdateTicket();
  const { user } = useAuthContext();

  const theme = useTheme();
  const bgColor = theme.palette.orange?.light[30];

  const lastMessageBody = useMemo(() => {
    if (!chat.lastMessage) return "";

    switch (chat.lastMessage.type) {
      case CaseMessageType.AUDIO:
        return "Áudio";
      case CaseMessageType.IMAGE:
        return "Foto";
      case CaseMessageType.DOCUMENT:
        return "Documento";
      case CaseMessageType.SNIPPET:
        return getSnippetLabel(chat.lastMessage.snippetInfo);
      case CaseMessageType.TEXT:
        return chat.lastMessage.body;
      case CaseMessageType.TEMPLATE:
        return chat.lastMessage.body;
      default:
        return chat.lastMessage.type;
    }
  }, [chat.lastMessage]);

  const tag = useMemo(() => {
    const getTagForManualCurrentTicket = () => {
      if (!chat.lastCase && !chat.lastTicket) return null;

      if (chat?.lastTicket?.step) {
        return getTagForTicketStep(chat.lastTicket.step);
      }

      // TODO: REMOVER TODA LÓGICA COM CASEDATA E MANTER APENAS A LÓGICA COM LASTTICKET
      if (chat.lastTicket?.status === TicketStatus.ACTIVE && chat.lastCase?.step) {
        return getTagForCaseStep(chat.lastCase.step);
      }

      return null;
    };

    const getTagForTicketStep = (step: TicketStep) => {
      switch (step) {
        case TicketStep.SUMMARY_GENERATED:
          return CaseTagSelector.SUMMARY_GENERATED;
        case TicketStep.LAWYER_INVOKED_BY_APPLICANT:
        case TicketStep.EXCEEDED_PROGRESS_LIMIT:
        case TicketStep.TICKET_FAILED:
          return CaseTagSelector.REDIRECTED_TO_HUMAN;
        default:
          return null;
      }
    };

    const getTagForCaseStep = (step: CaseStep) => {
      switch (step) {
        case CaseStep.LAWYER_INVOKED_BY_APPLICANT:
        case CaseStep.CASE_FAILED:
        case CaseStep.EXCEEDED_PROGRESS_LIMIT:
          return CaseTagSelector.REDIRECTED_TO_HUMAN;
        case CaseStep.SUMMARY_GENERATED:
          return CaseTagSelector.SUMMARY_GENERATED;
        default:
          return null;
      }
    };

    if (chat.status === ChatStatus.ARCHIVED) {
      return CaseTagSelector.ARCHIVED;
    }

    switch (chat.chatMode) {
      case ChatMode.AI:
        return CaseTagSelector.IA;
      case ChatMode.MANUAL:
        return CaseTagSelector.INACTIVE;
      case ChatMode.AI_PAUSED_FOR_CURRENT_TICKET:
        return getTagForManualCurrentTicket();
      default:
        return null;
    }
  }, [chat]);

  const openOptionsMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOptionsMenuAnchorEl(event.currentTarget);
  };

  const closeOptionsMenu = () => {
    setOptionsMenuAnchorEl(null);
  };

  const handleDeactivateAi = async () => {
    try {
      await deactivateAi({ applicantPhoneNumber: chat.endClientNumber });
      closeOptionsMenu();
      closeDeactivateAiModal();
      WebToast.success("Assistente IA desativado com sucesso");
    } catch (error) {
      closeDeactivateAiModal();
      closeOptionsMenu();
      logger.error("Error in handleDeactivateAi:", error);
      WebToast.error("Erro ao desativar assistente IA");
    }
  };

  const handleActivateAi = async () => {
    try {
      await activateAi({ applicantPhoneNumber: chat.endClientNumber });
      WebToast.success("Assistente IA reativado com sucesso");
    } catch (error) {
      logger.error("Error in handleActivateAi:", error);
      WebToast.error("Erro ao reativar assistente IA");
    }

    closeOptionsMenu();
    closeActivateAiModal();
  };

  const closeActivateAiModal = () => {
    setIsActivateAiModalOpen(false);
  };

  const handleOpenDeactivateAiModal = async () => {
    setIsDeactivateAiModalOpen(true);
  };

  const handleOpenActivateAiModal = async () => {
    setIsActivateAiModalOpen(true);
  };

  const closeDeactivateAiModal = () => {
    setIsDeactivateAiModalOpen(false);
    setOptionsMenuAnchorEl(null);
  };

  const handleOpenTicketDetailsModal = () => {
    setIsTicketDetailsModalOpen(true);
  };

  const handleCloseTicketDetailsModal = () => {
    setIsTicketDetailsModalOpen(false);
    setOptionsMenuAnchorEl(null);
  };

  const handleTakeOverTicket = async () => {
    const ticket = chat.lastTicket;
    if (!ticket || !user) return;

    try {
      await updateTicket({
        ticketId: ticket.id,
        applicantPhoneNumber: ticket.applicantPhoneNumber,
        updateFields: {
          responsibleUserId: user.userId,
        },
      });

      return WebToast.success("Atendimento assumido com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao assumir atendimento");
    }
  };

  const handleOpenArchiveChatModal = (mode: "archive" | "unarchive") => {
    setArchiveUnarchiveModal(mode);
  };

  const handleCloseArchiveChatModal = () => {
    setArchiveUnarchiveModal(null);
    setOptionsMenuAnchorEl(null);
  };

  const handleArchiveChat = async () => {
    try {
      await archiveChat({ applicantPhoneNumber: chat.endClientNumber });
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.success("Conversa arquivada com sucesso");
    } catch (error) {
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.error("Erro ao arquivar conversa");
    }
  };

  const handleUnarchiveChat = async () => {
    try {
      await unarchiveChat({ applicantPhoneNumber: chat.endClientNumber });
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.success("Conversa desarquivada com sucesso");
    } catch (error) {
      closeOptionsMenu();
      handleCloseArchiveChatModal();
      WebToast.error("Erro ao desarquivar conversa");
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        onClick={onClick}
        sx={{
          display: "grid",
          gridTemplateColumns: "min-content minmax(0, 1fr) min-content",
          gap: 1,
          backgroundColor: isActive ? bgColor : undefined,
          paddingX: 1.5,
          paddingY: 1,
          borderRadius: "8px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: bgColor ? alpha(bgColor, 0.4) : undefined,
            "& .waiting-response": {
              display: "none",
            },
            "& .expand-icon": {
              display: "inline-flex",
            },
          },
          "&:active": {
            backgroundColor: bgColor ? alpha(bgColor, 0.7) : undefined,
          },
        }}
      >
        <Box />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography
            variant="body"
            color="primary.dark"
            sx={{
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {responsibleUser?.name || "-"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Typography
              variant="body"
              color="#09132C"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {chat.applicantName || formatPhoneNumber(chat.endClientNumber)}
            </Typography>
            {!!tag && (
              <Box
                sx={{
                  backgroundColor: tag.bgColor,
                  paddingX: 1,
                  paddingY: 0.25,
                  borderRadius: "4px",
                }}
              >
                <Typography
                  variant="body"
                  color={tag.textColor}
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {tag.title}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            {!!chat.lastMessage && <MessageStatus message={chat.lastMessage} />}

            <Typography variant="body">
              {parseStringToHtml(
                formatChatMessage({
                  message: lastMessageBody,
                  shouldFormat: {
                    breakline: false,
                    url: false,
                    phone: false,
                  },
                })
              )}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box>
            {!!chat.lastMessage && (
              <Typography variant="body">
                {dayFromTimestamp(chat.lastMessage.timestamp, { displayTodayAsTime: true })}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "32px",
              height: "32px",
            }}
          >
            {!!chat.unansweredMessagesCount && (
              <Typography
                className="waiting-response"
                variant="body2"
                sx={{
                  width: "24px",
                  height: "24px",
                  backgroundColor: "success.light",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  color: "common.white",
                }}
              >
                {chat.unansweredMessagesCount}
              </Typography>
            )}

            <IconButton
              onClick={(e) => {
                openOptionsMenu(e);
              }}
              className="expand-icon"
              sx={{
                m: -1,
                display: chat.unansweredMessagesCount ? "none" : "inline-flex",
              }}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Popover
        open={Boolean(optionsMenuAnchorEl)}
        onClose={closeOptionsMenu}
        anchorEl={optionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <MenuItem onClick={handleOpenTicketDetailsModal}>
          <Typography variant="body1">Gerenciar conversa</Typography>
        </MenuItem>
        {chat.chatMode === ChatMode.MANUAL ? (
          <MenuItem onClick={handleOpenActivateAiModal}>
            <Typography variant="body1">Reativar assistente IA</Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleOpenDeactivateAiModal}>
            <Typography variant="body1">Desativar assistente IA</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleTakeOverTicket} disabled={chat.lastTicket?.responsibleUserId === user?.userId}>
          <Typography variant="body1">Assumir conversa</Typography>
        </MenuItem>
        {chat.status === ChatStatus.ARCHIVED ? (
          <MenuItem onClick={() => handleOpenArchiveChatModal("unarchive")}>
            <Typography variant="body1">Desarquivar conversa</Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={() => handleOpenArchiveChatModal("archive")}>
            <Typography variant="body1">Arquivar conversa</Typography>
          </MenuItem>
        )}
      </Popover>

      {isDeactivateAiModalOpen && (
        <DeactivateAiModal
          isOpen={true}
          onConfirm={handleDeactivateAi}
          onCancel={closeDeactivateAiModal}
          isLoading={isDeactivatingAi}
        />
      )}
      {isActivateAiModalOpen && (
        <ActivateAiModal
          isOpen={true}
          onConfirm={handleActivateAi}
          onCancel={closeActivateAiModal}
          isLoading={isActivatingAi}
        />
      )}
      {isTicketDetailsModalOpen && chat.lastTicket && (
        <TicketDetailsModal onClose={handleCloseTicketDetailsModal} ticket={chat.lastTicket} />
      )}
      {archiveUnarchiveModal && (
        <ArchiveUnarchiveChatModal
          onCancel={handleCloseArchiveChatModal}
          onConfirm={archiveUnarchiveModal === "archive" ? handleArchiveChat : handleUnarchiveChat}
          isOpen={true}
          isLoading={isArchivingChat || isUnarchivingChat}
          action={archiveUnarchiveModal}
        />
      )}
    </Box>
  );
};
