import React, { useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";

import { ArrowForwardIos as ArrowForwardIosIcon } from "@mui/icons-material";
import { TicketCard } from "./components/TicketCard";
import { CaseCard } from "./components/CaseCard";
import { Case, Ticket, TicketStatus } from "@/hooks/lexZap/types";
import { useLexZapGetTickets } from "@/hooks/lexZap/useLexZapGetTickets";
import { CloseTicketModal } from "../ChatList/components/CloseTicketModal";
import { useCloseTicket } from "@/hooks/lexZap/useCloseTicket";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";

interface TicketDetailsProps {
  handleCloseTicketDetails: () => void;
  cases?: Case[];
  applicantName?: string;
  applicantPhoneNumber?: string;
}

export const TicketDetails = ({
  handleCloseTicketDetails,
  cases,
  applicantName,
  applicantPhoneNumber,
}: TicketDetailsProps) => {
  const [isOpenCloseTicketModal, setIsOpenCloseTicketModal] = useState(false);
  const { mutateAsync: closeTicket, isPending: isClosingTicket } = useCloseTicket();
  const [currentTicketId, setCurrentTicketId] = useState<string | null>(null);
  const [currentApplicantPhoneNumber, setCurrentApplicantPhoneNumber] = useState<string | null>(null);
  const { data: tickets } = useLexZapGetTickets();

  const getTicketHaveCase = (ticketId: string) => {
    return cases?.find((caseData) => caseData.ticketId === ticketId);
  };

  const clientTickets = useMemo(() => {
    return tickets?.filter((ticket) => ticket.applicantPhoneNumber === applicantPhoneNumber);
  }, [applicantPhoneNumber, tickets]);

  const casesAndTickets = useMemo(() => {
    return [
      ...(cases?.map((caseData) => ({ ...caseData, type: "CASE" })) || []),
      ...(clientTickets?.map((ticket) => ({ ...ticket, type: "TICKET" })) || []),
    ];
  }, [cases, clientTickets]);

  const sortedCasesAndTickets = casesAndTickets.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  const handleOpenCloseTicketModal = (ticketId: string, applicantPhoneNumber: string) => {
    setIsOpenCloseTicketModal(true);
    setCurrentTicketId(ticketId);
    setCurrentApplicantPhoneNumber(applicantPhoneNumber);
  };

  const handleCloseTicketModal = () => {
    setIsOpenCloseTicketModal(false);
    setCurrentTicketId(null);
    setCurrentApplicantPhoneNumber(null);
  };

  const handleCloseTicket = async () => {
    if (!currentTicketId || !currentApplicantPhoneNumber) return;
    try {
      await closeTicket({ ticketId: currentTicketId, applicantPhoneNumber: currentApplicantPhoneNumber });
      WebToast.success("Atendimento encerrado com sucesso");
      setIsOpenCloseTicketModal(false);
    } catch (error) {
      setIsOpenCloseTicketModal(false);
      WebToast.error("Erro ao encerrar atendimento");
      logger.error("Error closing ticket", { error });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "common.white",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          paddingTop: "20px",
          paddingLeft: "12px",
        }}
      >
        <IconButton onClick={handleCloseTicketDetails}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingX: "16px",
          gap: "16px",
          paddingBottom: "16px",
          height: "100%",
          overflowY: "auto",
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "grey.100",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "grey.300",
          },
        }}
      >
        <Typography variant="pageTitle">{applicantName}</Typography>
        {sortedCasesAndTickets.map((item) =>
          item.type === "CASE" ? (
            <CaseCard key={item.id} caseData={item as Case} openCloseTicketModal={handleOpenCloseTicketModal} />
          ) : getTicketHaveCase(item.id) && item.status === TicketStatus.ACTIVE ? null : (
            <TicketCard key={item.id} ticket={item as Ticket} openCloseTicketModal={handleOpenCloseTicketModal} />
          )
        )}
      </Box>
      <CloseTicketModal
        onConfirm={handleCloseTicket}
        isOpen={isOpenCloseTicketModal}
        onCancel={handleCloseTicketModal}
        isLoading={isClosingTicket}
      />
    </Box>
  );
};
