import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";

import { CaseMessage } from "@/hooks/lexZap/types";
import { Message } from "./components/Message";
import { MessageSkeleton } from "./components/Message/skeleton";

interface ChatMessagesProps {
  messages?: CaseMessage[];
  isLoading?: boolean;
  messagesContainerRef: React.RefObject<HTMLDivElement>;
  scrollToBottom: () => void;
}

export const ChatMessages = ({ messages, isLoading, messagesContainerRef, scrollToBottom }: ChatMessagesProps) => {
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  return (
    <Box
      ref={messagesContainerRef}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingX: 5,
        paddingTop: 3,
        paddingBottom: 2,
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          marginTop: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {isLoading ? (
          <ChatMessagesSkeleton />
        ) : (
          messages?.map((message, index) => <Message key={index} message={message} />)
        )}
      </Box>
    </Box>
  );
};

const ChatMessagesSkeleton = () => {
  const SkeletonMessages = useMemo(() => Array.from({ length: 20 }, (_, index) => <MessageSkeleton key={index} />), []);

  return <>{SkeletonMessages}</>;
};
