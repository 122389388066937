import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getApplicantsQueryKey } from "../useApplicants";
import { ApplicantService } from "@/services/applicant";
import { Applicant, ApplicantPayload } from "@/services/applicant/types";

export const useCreateApplicants = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createApplicant"],
    mutationFn: async ({ data }: { data: ApplicantPayload[] }) => {
      const response = await ApplicantService.createApplicants({ data });
      return { data, response };
    },
    onSuccess: async ({ response }, { data }) => {
      await queryClient.cancelQueries({ queryKey: getApplicantsQueryKey() });
      queryClient.setQueryData(getApplicantsQueryKey(), (old?: Applicant[]) => {
        return [
          ...(old || []),
          ...data.map((applicant) => ({
            ...applicant,
            id: response.find((r) => r.phoneNumber === applicant.phoneNumber)?.id,
          })),
        ];
      });
    },
  });
};
