import { Case } from "@/hooks/lexZap/types";
import { Edit, MoreVert } from "@mui/icons-material";
import { Box, Chip, IconButton, Link, Menu, MenuItem, Skeleton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Card } from "../Card";
import { LexterDate } from "@/core/LexterDate";
import { bindMenu } from "material-ui-popup-state";
import { bindTrigger } from "material-ui-popup-state";
import PopupState from "material-ui-popup-state";

interface CaseCardProps {
  caseData?: Case;
  loadingCase: boolean;
  openEditCaseModal: () => void;
  openEditApplicantModal: () => void;
  onArchive: () => void;
  onUnarchive: () => void;
  onDelete: () => void;
  archived: boolean;
}

export const CaseCard = ({
  caseData,
  loadingCase,
  openEditCaseModal,
  openEditApplicantModal,
  onArchive,
  onUnarchive,
  onDelete,
  archived,
}: CaseCardProps) => {
  if (loadingCase) {
    return <CaseCardSkeleton />;
  }

  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: 2 }}>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Typography variant="h6" mb={1}>
              {caseData?.name}
            </Typography>
            {caseData?.legalArea && (
              <Tooltip placement="top" title={caseData?.legalArea}>
                <Chip
                  sx={{
                    backgroundColor: "orange.light.30",
                    textOverflow: "ellipsis",
                  }}
                  label={caseData?.legalArea}
                  variant="filled"
                />
              </Tooltip>
            )}
          </Box>

          {caseData && caseData.timeline.length > 0 && (
            <Typography variant="subtitle2">
              Última movimentação:{" "}
              <Typography component="span" color="primary.main">
                {formatDate(caseData.lastModifiedAt ?? caseData.createdAt)}
              </Typography>
            </Typography>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            sx={{
              color: "common.shade",
            }}
            onClick={openEditCaseModal}
          >
            <Edit fontSize="small" />
          </IconButton>
          <PopupState variant="popover">
            {(popupState) => (
              <React.Fragment>
                <IconButton size="small" sx={{ color: "common.shade" }} {...bindTrigger(popupState)}>
                  <MoreVert />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      archived ? onUnarchive() : onArchive();
                    }}
                  >
                    {archived ? "Desarquivar Caso" : "Arquivar Caso"}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      onDelete();
                    }}
                  >
                    Excluir Caso
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Box>
      </Box>

      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Cliente
          </Typography>
          <Link
            onClick={caseData?.applicantId ? openEditApplicantModal : openEditCaseModal}
            sx={{
              color: "primary.main",
            }}
          >
            {caseData?.applicantName ?? "Vincular cliente"}
          </Link>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Contato
          </Typography>
          {caseData?.applicantPhoneNumber ? (
            <Typography variant="body1">{formatPhoneNumber(caseData.applicantPhoneNumber)}</Typography>
          ) : (
            <Link
              onClick={caseData?.applicantId ? openEditApplicantModal : openEditCaseModal}
              sx={{
                width: "fit-content",
                textDecoration: "underline",
                color: "primary.main",
              }}
            >
              Adicionar número
            </Link>
          )}
        </Box>
        {/* 
        TODO: Implementar responsável/responsável do processo
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Responsável
          </Typography>
          <Typography variant="body1">Lógica de Responsável</Typography>
        </Box>
        */}
      </Box>

      {/* 
      TODO: Implementar ver mais, quando tivermos mais informações
      <Divider sx={{ mx: -3, mb: -2 }} />
      <Box sx={{ alignSelf: "flex-end" }}>
        <Button endIcon={KeyboardArrowDown}>Ver mais</Button>
      </Box> */}
    </Card>
  );
};

const formatDate = (date: string) => {
  const dateOrError = LexterDate.fromCortexString(date);
  if (dateOrError.isFailure) {
    return "";
  }

  return dateOrError.getValue().toLongString();
};

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.slice(2).replace(/(\d{2})(9?\d{4})(\d{4})/, "($1) $2-$3");
};

const CaseCardSkeleton = () => {
  return (
    <Card>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Box>
            <Skeleton variant="rectangular" width={200} height={24} sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" width={160} height={20} />
          </Box>
          <Skeleton variant="circular" width={24} height={24} />
        </Box>

        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Skeleton variant="rectangular" width={80} height={16} />
            <Skeleton variant="rectangular" width={120} height={20} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Skeleton variant="rectangular" width={80} height={16} />
            <Skeleton variant="rectangular" width={120} height={20} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Skeleton variant="rectangular" width={80} height={16} />
            <Skeleton variant="rectangular" width={120} height={20} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
