import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCasesQueryKey } from "../useLexZapCases";
import { Case } from "../types";
import { DateTime } from "luxon";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { isAxiosError } from "axios";

export const useCreateCase = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["createCase"],
    mutationFn: async ({
      name,
      applicantId,
      applicantName,
      applicantPhoneNumber,
      ticketId,
      summary,
    }: {
      name: string;
      applicantId: string | null;
      applicantName: string | null;
      applicantPhoneNumber: string | null;
      ticketId?: string;
      summary?: string;
    }) => {
      return {
        ...(await api.createCase({ name, applicantId: applicantId ?? undefined, ticketId, summary })),
        applicantName,
        applicantPhoneNumber,
      };
    },
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: getCasesQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getCasesQueryKey());
      queryClient.setQueryData(getCasesQueryKey(), (old: Case[]) => [
        ...old,
        {
          ...data,
          timeline: [{ status: "MANUALLY_CREATED", createdAt: DateTime.now().toISO() }],
          applicantName: data.applicantName,
          applicantPhoneNumber: data.applicantPhoneNumber,
        },
      ]);
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      handleErrorCreatingCase(_error);
      queryClient.setQueryData(getCasesQueryKey(), context);
    },
  });
};

const handleErrorCreatingCase = (error: unknown) => {
  logger.error("handleErrorCreatingCase", error);
  if (isAxiosError(error) && error.response?.status === 402) {
    WebToast.error("Você atingiu o limite máximo de casos");
  } else {
    WebToast.error("Erro ao criar o caso");
  }
};
