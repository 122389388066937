import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LexZapService } from "@/services/lexZap";
import { Ticket } from "../types";
import { getTicketsQueryKey } from "../useLexZapGetTickets";

export const useUpdateTicket = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateTicket"],
    mutationFn: async ({
      ticketId,
      applicantPhoneNumber,
      updateFields,
    }: {
      ticketId: string;
      applicantPhoneNumber: string;
      updateFields: Partial<Ticket>;
    }) => await LexZapService.updateTicket({ ticketId, applicantPhoneNumber, updateFields }),
    onMutate: async ({ ticketId, updateFields }) => {
      await queryClient.cancelQueries({ queryKey: getTicketsQueryKey() });
      const previousTickets = queryClient.getQueryData(getTicketsQueryKey());

      queryClient.setQueryData(getTicketsQueryKey(), (old: Ticket[] | undefined) => {
        if (!old) return [];
        return old.map((ticket) =>
          ticket.id === ticketId
            ? {
                ...ticket,
                ...updateFields,
              }
            : ticket
        );
      });

      return { previousTickets };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(getTicketsQueryKey(), context?.previousTickets);
    },
  });
};
