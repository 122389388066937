import { Box, Skeleton, SxProps, Theme } from "@mui/material";
import React from "react";

interface CardProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  loadingSize?: string;
}

export const Card = ({ children, sx, loadingSize }: CardProps) => {
  if (loadingSize) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: 2,
        }}
        width="100%"
        height={loadingSize}
      />
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "common.white",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "divider",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: 3,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
