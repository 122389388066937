import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { ChatHeader } from "./components/ChatHeader";
import { EmptyChat } from "./components/EmptyChat";
import { CaseMessage } from "@/hooks/lexZap/types";
import { ChatFilesPreview } from "./components/ChatFilesPreview";
import { useDropzone } from "react-dropzone";
import { ChatMessages } from "./components/ChatMessages";
import { ChatInputs } from "./components/ChatInputs";
import { WebToast } from "@//components/core/Toast";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";

interface ChatProps {
  messages?: CaseMessage[];
  isLoadingChats?: boolean;
  isLoadingMessages?: boolean;
  showOpenTicketDetails: boolean;
  handleOpenTicketDetails: () => void;
  setupCollapseHeader: (ref: React.MutableRefObject<HTMLElement | undefined>) => void;
}

interface FileProp {
  url: string;
  blob: Blob;
  name: string;
  type: string;
  mimeType: string;
}

export const Chat = ({
  messages,
  isLoadingChats,
  isLoadingMessages,
  showOpenTicketDetails,
  handleOpenTicketDetails,
  setupCollapseHeader,
}: ChatProps) => {
  const { activeChat } = useLexZapActiveChat();
  const [showDropMessage, setShowDropMessage] = React.useState(false);
  const [files, setFiles] = React.useState<FileProp[]>([]);
  const [fileSelector, setFileSelector] = React.useState<"document" | "image&video" | null>(null);
  const { templateToUse } = useLexZapChatInfo({ chat: activeChat });
  const isDropDisabled = !!templateToUse;

  const headerRef = useRef<HTMLElement>();

  useEffect(() => {
    setupCollapseHeader(headerRef);
  }, [headerRef]);

  const onDrop = React.useCallback(
    async (acceptedFiles: File[]) => {
      setShowDropMessage(false);
      if (isDropDisabled) {
        WebToast.error("O envio de arquivos será habilitado novamente após uma resposta do seu cliente");
        return;
      }

      acceptedFiles?.forEach(async (file) => {
        const fileType = file.type.split("/")[0];
        if (fileType === "document" && file.size > 100000000) {
          WebToast.error("O documento não pode ser maior que 100MB");
          return;
        }
        if (fileType === "audio" && file.size > 16000000) {
          WebToast.error("A audio não pode ser maior que 16MB");
          return;
        }
        if (fileType === "video" && file.size > 16000000) {
          WebToast.error("O vídeo não pode ser maior que 16MB");
          return;
        }
        if (fileType === "image" && file.size > 5000000) {
          WebToast.error("O imagem não pode ser maior que 5MB");
          return;
        }
        const fileToBlob = async (file: File) =>
          new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
        const blob = await fileToBlob(file);
        const url = URL.createObjectURL(blob);
        setFiles((prev) => [
          ...prev,
          {
            url,
            blob,
            name: file.name,
            type: fileType,
            mimeType: file.type,
          },
        ]);
      });
    },
    [isDropDisabled]
  );

  const handleRemoveFile = (file: FileProp) => {
    setFiles((prev) => prev.filter((f) => f.url !== file.url));
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    onDragEnter: () => {
      setShowDropMessage(true);
    },
    onDragLeave: () => {
      setShowDropMessage(false);
    },
    accept: {
      "video/3gp": [],
      "video/mp4": [],
      "image/jpeg": [],
      "image/png": [],
      "audio/mpeg": [],
      "audio/mp4": [],
      "audio/aac": [],
      "audio/amr": [],
      "application/pdf": [],
      "application/vnd.ms-powerpoint": [],
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "text/plain": [],
    },
    onDropRejected(fileRejections) {
      if (fileRejections.length) {
        WebToast.error(
          fileRejections.length === 1
            ? `1 arquivo que você tentou adicionar não é compatível`
            : `${fileRejections.length} arquivos que você tentou adicionar não são compatíveis`
        );
      }
    },
  });

  const onCloseFilesPreview = () => {
    setFiles([]);
  };

  const handleOpenDocumentSelector = () => {
    setFileSelector("document");
    open();
  };

  const handleOpenImageVideoSelector = () => {
    setFileSelector("image&video");
    open();
  };

  React.useEffect(() => {
    setFiles([]);
  }, []);

  if (!isLoadingChats && !isLoadingMessages && !activeChat) {
    return <EmptyChat />;
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "min-content minmax(0, 1fr) min-content",
        backgroundColor: "#F9F9F9",
        height: "100%",
      }}
    >
      <Box ref={headerRef}>
        <ChatHeader
          chat={activeChat}
          isLoading={isLoadingChats}
          showOpenTicketDetails={showOpenTicketDetails}
          handleOpenTicketDetails={handleOpenTicketDetails}
        />
      </Box>

      {files.length ? (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
            overflowX: "hidden",
          }}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            style={{
              position: "absolute",
              backgroundColor: "red",
              height: "100%",
              width: "100%",
              opacity: 0,
              zIndex: -1,
            }}
          />
          <ChatFilesPreview
            files={files}
            applicantPhoneNumber={activeChat?.endClientNumber}
            openFilesSelector={open}
            removeFile={handleRemoveFile}
            onClose={onCloseFilesPreview}
            fileSelector={fileSelector}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            width: "100%",
            overflowX: "hidden",
          }}
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            style={{
              position: "absolute",
              backgroundColor: "red",
              height: "100%",
              width: "100%",
              opacity: 0,
              zIndex: -1,
            }}
          />
          <DropZoneMessage showDropMessage={showDropMessage} />
          <ChatMessages messages={messages} isLoading={isLoadingMessages} chat={activeChat} />

          <ChatInputs
            openDocumentSelector={handleOpenDocumentSelector}
            openImageVideoSelector={handleOpenImageVideoSelector}
          />
        </Box>
      )}
    </Box>
  );
};

const DropZoneMessage = ({ showDropMessage }: { showDropMessage: boolean }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        padding: 2,
        visibility: showDropMessage ? "visible" : "hidden",
        position: "absolute",
        backgroundColor: "grey.50",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          borderWidth: 2,
          borderStyle: "dashed",
          borderColor: "grey.400",
          borderRadius: 2,
          display: "flex",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "grey.400",
          }}
        >
          Arraste e solte o arquivo aqui
        </Typography>
      </Box>
    </Box>
  );
};
