import React from "react";
import { Box, Divider, Paper, Skeleton } from "@mui/material";
import { Typography } from "@mui/material";
import { AutoFixHigh } from "@mui/icons-material";
import { ExpandableCard } from "@/components/ExpandableCard";
import { Case } from "@/hooks/lexZap/types";
import { dateTimeFromString } from "@/utils/dates/dateTimeFromString";
import { Card } from "../Card";
import { useLexZapTicket } from "@/hooks/lexZap/useLexZapTicket";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

interface CaseSummaryProps {
  caseData?: Case;
  loadingCase: boolean;
}

export const CaseSummary = ({ caseData, loadingCase }: CaseSummaryProps) => {
  const { ticket } = useLexZapTicket({ ticketId: caseData?.ticketId });
  const { legalProceedingsPageEnabled } = useFeatureFlags();
  if (loadingCase) {
    return <CaseSummarySkeleton />;
  }

  if (!caseData?.facts && !caseData?.summary && !ticket?.summary && legalProceedingsPageEnabled) {
    return null;
  }

  return (
    <Card>
      <Box>
        <Typography variant="subtitle1" fontWeight={700}>
          Resumo jurídico
        </Typography>
        {caseData && (
          <Typography variant="body2" color="text.secondary">
            Última atualização em:{" "}
            {dateTimeFromString(caseData.lastModifiedAt || caseData.createdAt).toFormat("dd/MM/yyyy 'às' HH:mm")}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          bgcolor: caseData?.facts ? "orange.light.5" : "grey.50",
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          justifyContent: "center",
        }}
      >
        {caseData?.facts || ticket?.summary ? (
          <>
            <Typography variant="subtitle1" color="primary.main" sx={{ display: "flex", alignItems: "start", gap: 2 }}>
              Resumo gerado por IA <AutoFixHigh sx={{ fontSize: "22px" }} />
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography
                variant="body"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 5,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  textWrap: "wrap",
                  overflow: "hidden",
                  "&:hover": {
                    WebkitLineClamp: "unset",
                  },
                }}
              >
                {caseData?.facts
                  ? caseData?.facts?.split("\n").map((paragraph, index) => (
                      <React.Fragment key={index}>
                        {paragraph}
                        <br />
                      </React.Fragment>
                    ))
                  : ticket?.summary}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant="subtitle1" textAlign="center" p={2}>
            Nenhum resumo gerado ainda
          </Typography>
        )}
      </Box>

      {caseData?.documentationAndEvidence ||
      caseData?.legalQuestions ||
      caseData?.conclusion ||
      caseData?.recommendations ? (
        <Paper>
          {caseData?.documentationAndEvidence && (
            <SummaryItemCard title="Documentações e provas" content={caseData.documentationAndEvidence} />
          )}
          <Divider sx={{ backgroundColor: "grey.50" }} />
          {caseData?.legalQuestions && <SummaryItemCard title="Questões jurídicas" content={caseData.legalQuestions} />}
          <Divider sx={{ backgroundColor: "grey.50" }} />
          {/* {caseData?.recommendations && <SummaryItemCard title="Recomendações" content={caseData.recommendations} />} */}
          {/* <Divider /> */}
          {caseData?.conclusion && <SummaryItemCard title="Conclusão Lexter" content={caseData.conclusion} />}
        </Paper>
      ) : null}
    </Card>
  );
};

const SummaryItemCard = ({ title, content }: { title: string; content: string }) => (
  <>
    <ExpandableCard header={<Typography variant="subtitle1">{title}</Typography>}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, p: 2 }}>
        {content.split("\n").map((paragraph, index) => (
          <Typography variant="multiLineBody" fontSize="14px" key={index}>
            {paragraph}
          </Typography>
        ))}
      </Box>
    </ExpandableCard>
  </>
);

const CaseSummarySkeleton = () => {
  return (
    <Card>
      <Box>
        <Typography variant="subtitle1" fontWeight={700}>
          Resumo jurídico
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          Última atualização em: <Skeleton width={150} height={28} />
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: "grey.50",
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Skeleton width={150} height={28} />
          <Skeleton width={24} height={24} variant="circular" />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} width="80%" />
        </Box>
      </Box>
    </Card>
  );
};
