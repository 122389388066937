import React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { CheckBox, FolderOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { Button } from "@/components/Button";

interface LegalProceedingSearchResultDialogProps {
  open: boolean;
  onClose: () => void;
  success: boolean;
}

export const LegalProceedingSearchResultDialog = ({
  open,
  onClose,
  success,
}: LegalProceedingSearchResultDialogProps) => {
  const navigate = useNavigate();

  const handleGoToManagement = () => {
    onClose();
    navigate(ROUTE_PATHS.LEGAL_PROCEEDINGS_MONITORING);
  };

  if (!success) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        sx={{
          ".MuiPaper-root": {
            pr: 1,
            pb: 1,
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="div" fontWeight={400} color="error">
            Erro durante a busca
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography variant="subtitle1">
            Infelizmente não conseguimos completar a busca, tente novamente em instantes.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small" onClick={handleGoToManagement}>
            Ir para gestão de processos
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      sx={{
        ".MuiPaper-root": {
          p: 1,
          transition: "opacity 600ms ease-in-out",
          opacity: open ? 1 : 0,
        },
      }}
    >
      <DialogTitle sx={{ mb: 3 }}>
        <Typography variant="h6" component="div" fontWeight={400}>
          Busca realizada com sucesso
        </Typography>
        <Typography variant="subtitle2" fontWeight={500}>
          Veja os próximos passos abaixo:
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ bgcolor: "orange.light.5", p: 1.5, borderRadius: 3, display: "flex", alignItems: "center" }}>
            <CheckBox color="primary" fontSize="small" />
          </Box>
          Selecione, na tabela, os processos desejados utilizando a caixa de seleção.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ bgcolor: "orange.light.5", p: 1.5, borderRadius: 3, display: "flex", alignItems: "center" }}>
            <FolderOutlined color="primary" fontSize="small" />
          </Box>
          Em seguida, faça a gestão do seu caso dentro da Lexter.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Começar</Button>
      </DialogActions>
    </Dialog>
  );
};
