import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Balance } from "@mui/icons-material";
import { Button } from "@/components/Button";
import LexterLogo from "@/assets/svgs/lexter-logo.svg?react";
import { RoundedIcon } from "@/components/RoundedIcon";
import AuthorizeCourtCredentialWithBlock from "@/assets/svgs/authorizeCourtCredentialWithBlock.svg?react";
import { CourtCredentialType } from "@/services/legalProceeding/types";
import { WebToast } from "@/components/core/Toast";
import { useAuthContext } from "@/contexts/AuthContext";
import { useRevokeCourtCredential } from "@/hooks/courtCredentials/useRevokeCourtCredential";

type RevokeAccessModalProps = {
  open: boolean;
  onClose: () => void;
  row: CourtCredentialType | null;
};

export const RevokeAccessModal = ({ open, onClose, row }: RevokeAccessModalProps) => {
  const { user } = useAuthContext();
  const { mutateAsync: revokeCourtCredential, isPending: isRevokingCourtCredential } = useRevokeCourtCredential();

  const handleRevoke = async () => {
    try {
      if (!row) {
        throw new Error("Credencial não encontrada");
      }

      await revokeCourtCredential({ userId: user?.userId ?? "", credentialId: row.id });

      onClose();

      WebToast.success("Acesso revogado com sucesso");
    } catch (error) {
      WebToast.error("Erro ao revogar acesso");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography variant="h6" component="div" fontWeight={400}>
          Revogar acesso
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            bgcolor: "orange.light.5",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: 3,
            gap: 2,
            borderRadius: 2,
            mb: 2,
          }}
        >
          <RoundedIcon icon={<LexterLogo width="24px" />} bgcolor="primary.main" />
          <AuthorizeCourtCredentialWithBlock />
          <RoundedIcon icon={<Balance />} />
        </Box>

        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          Ao revogar o acesso da Lexter ao órgão <strong>{row?.tj}</strong> você perderá acesso ao inteiro teor dos
          processos ativos na plataforma.
        </Typography>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Button size="small" onClick={onClose} sx={{ flex: 1 }}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="small"
            sx={{ flex: 1 }}
            onClick={handleRevoke}
            isLoading={isRevokingCourtCredential}
            loaderStyle={{ width: "194px" }}
          >
            Revogar acesso
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
