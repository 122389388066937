import React, { useState } from "react";

import { Avatar, Box, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { AutoAwesomeOutlined, EditOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import WriteDocumentsActions from "@/pages/Home/components/WriteDocumentsActions";
import OtherTasksActions from "@/pages/Home/components/OtherTasksActions";
import { Case } from "@/hooks/lexZap/types";

import LauraImage from "@/assets/images/Laura.jpg";
import { Card } from "../Card";

export const CaseSuggestionSkills = ({ caseData, loadingCase }: { caseData?: Case; loadingCase?: boolean }) => {
  const [isWriteDocumentsActionsOpen, setIsWriteDocumentsActionsOpen] = useState(false);
  const [isOtherTasksActionsOpen, setIsOtherTasksActionsOpen] = useState(false);

  const handleOpenWriteDocumentsActions = () => {
    setIsWriteDocumentsActionsOpen(true);
  };

  const handleCloseWriteDocumentsActions = () => {
    setIsWriteDocumentsActionsOpen(false);
  };

  const handleOpenOtherTasksActions = () => {
    setIsOtherTasksActionsOpen(true);
  };

  const handleCloseOtherTasksActions = () => {
    setIsOtherTasksActionsOpen(false);
  };

  const isCaseInactive = !caseData || caseData?.status === "INACTIVE";

  return (
    <>
      <WriteDocumentsActions
        isOpen={isWriteDocumentsActionsOpen}
        onClose={handleCloseWriteDocumentsActions}
        caseData={caseData}
      />
      <OtherTasksActions isOpen={isOtherTasksActionsOpen} onClose={handleCloseOtherTasksActions} caseData={caseData} />
      <Card sx={{ gap: 1.5 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <Avatar src={LauraImage} sx={{ width: "40px", height: "40px" }} />
          <Typography variant="h6" fontWeight={700}>
            Conte com a ajuda da Laura
          </Typography>
        </Box>
        <Typography variant="body1">
          Utilize nossa Assistente Jurídica para executar as tarefas necessárias para o andamento do seu caso.
        </Typography>
        <Tooltip title={isCaseInactive ? "Ative esse caso para gerar peças" : ""}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Button
              variant="outlined"
              sx={{
                color: "common.black",
                borderColor: "common.lightShade",
                borderRadius: "10px 0px 0px 10px",
                borderRight: "none",
                flex: 1,
                "&:hover": {
                  borderColor: "common.lightShade",
                  backgroundColor: "common.dorian",
                  borderRight: "none",
                },
              }}
              startIcon={EditOutlined}
              onClick={handleOpenWriteDocumentsActions}
              disabled={loadingCase || isCaseInactive}
            >
              Escrever documento
            </Button>
            <Button
              sx={{
                color: "common.black",
                borderColor: "common.lightShade",
                borderRadius: "0px 10px 10px 0px",
                flex: 1,
                "&:hover": {
                  borderColor: "common.lightShade",
                  backgroundColor: "common.dorian",
                },
              }}
              variant="outlined"
              startIcon={AutoAwesomeOutlined}
              onClick={handleOpenOtherTasksActions}
              disabled={loadingCase || isCaseInactive}
            >
              Outras tarefas
            </Button>
          </Box>
        </Tooltip>
      </Card>
    </>
  );
};
