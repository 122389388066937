import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { getMessagesQueryKey } from "../useLexZapMessages";
import { CaseMessage, CaseMessageType, LexZapChat } from "../types";
import { SendLexZapMessageParams } from "@/hooks/useApi/types";
import { useAuthContext } from "@/contexts/AuthContext";
import { v4 as uuidV4 } from "uuid";
import { getChatsQueryKey } from "../useLexZapChats";
import { getTemplateText } from "./templates";

export const useSendLexZapMessage = () => {
  const queryClient = useQueryClient();
  const { sendLexZapMessage } = useApi();
  const { user } = useAuthContext();

  const { mutate, ...mutation } = useMutation({
    mutationKey: ["sendLexZapMessage"],
    mutationFn: async (params: SendLexZapMessageParams) => {
      return sendLexZapMessage(params);
    },
    onMutate: async ({ applicantPhoneNumber, message, requestId }) => {
      await queryClient.cancelQueries({ queryKey: getMessagesQueryKey({ applicantPhoneNumber }) });

      const newMessage: CaseMessage = {
        chatId: "",
        from: "",
        fromAssistant: false,
        lawyerId: user?.userId,
        fromLawyer: true,
        id: requestId,
        to: "",
        body:
          message.type === "template"
            ? getTemplateText({
                templateMessage: message,
              })
            : message.body,
        media: message.type === "template" ? undefined : message.media,
        type: message.type as CaseMessageType,
        isForwarded: false,
        timestamp: Date.now(),
        isSending: true,
        statusUpdateTimestamp: {},
        applicantPhoneNumber,
        requestId,
      };

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) => {
        return [...oldMessages, newMessage];
      });

      queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []): LexZapChat[] => {
        const chatExists = oldChats.some((chat) => chat.endClientNumber === applicantPhoneNumber);

        if (!chatExists) {
          return [
            ...oldChats,
            {
              endClientNumber: applicantPhoneNumber,
              lastMessage: newMessage,
            },
          ];
        }

        return oldChats.map((chat) =>
          chat.endClientNumber === applicantPhoneNumber
            ? { ...chat, lastMessage: newMessage, unansweredMessagesCount: 0 }
            : chat
        );
      });

      return { newMessage };
    },
    onError: (_error, { applicantPhoneNumber }, context) => {
      if (context?.newMessage) {
        const { newMessage } = context;
        newMessage.statusUpdateTimestamp = {
          failed: Date.now(),
        };
        newMessage.isSending = false;

        queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) =>
          oldMessages.map((oldMessage) => (oldMessage.id === newMessage.id ? newMessage : oldMessage))
        );

        queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) =>
          oldChats.map((chat) =>
            chat.endClientNumber === applicantPhoneNumber ? { ...chat, lastMessage: newMessage } : chat
          )
        );
      }
    },
    onSuccess: async (data, { applicantPhoneNumber }, { newMessage }) => {
      if (!data) return;

      newMessage.id = data.message.id;
      newMessage.isSending = false;

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) =>
        oldMessages.map((message) => (message.id === newMessage.id ? newMessage : message))
      );

      queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) =>
        oldChats.map((chat) =>
          chat.endClientNumber === applicantPhoneNumber ? { ...chat, lastMessage: newMessage } : chat
        )
      );
    },
  });

  return {
    ...mutation,
    mutate: (params: Omit<SendLexZapMessageParams, "requestId">) => mutate({ ...params, requestId: uuidV4() }),
  };
};
