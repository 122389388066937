import { useMutationState, useQuery } from "@tanstack/react-query";
import { LegalProceedingService } from "@/services/legalProceeding";
import { getCaseQueryKey, useLexZapCase } from "@/hooks/lexZap/useLexZapCase";

export const getCaseLegalProceedingsQueryKey = (caseId?: string) => [...getCaseQueryKey(caseId), "legalProceedings"];

export const useCaseLegalProceedingsQuery = ({ caseId }: { caseId?: string }) => {
  const mutationState = useMutationState({
    filters: { mutationKey: ["attachLegalProceedingToCase"] },
    select: (mutation) => mutation.state.data,
  });

  const { caseData } = useLexZapCase({ caseId });

  return useQuery({
    queryKey: getCaseLegalProceedingsQueryKey(caseId),
    queryFn: async () => {
      if (!caseId) {
        return [];
      }
      const response = await LegalProceedingService.getLegalProceedingsAttachedInCase(caseId);
      return response.legalProceedings;
    },
    refetchInterval: (query) => {
      if (caseData?.cnjs && caseData?.cnjs.length > 0 && query.state.data?.length === 0) {
        return 10000;
      }
      if ((query.state.data?.length && query.state.data?.length > 0) || mutationState.length === 0) {
        return false;
      }

      return 5000;
    },
    refetchOnWindowFocus: false,
  });
};
