import { buildSkillStep } from "@/core/skillForms/builder";

export const createDefenseSkillStep3 = buildSkillStep({
  title: "Detalhes do caso",
  description: "Preencha a lista abaixo para fornecer o contexto do seu caso à IA:",
  onboarding: {
    templateButton: "details",
  },
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Nome do cliente",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo.",
        placeholder:
          "Maria da Silva, conforme RG nº 1234567 e CPF 321.654.987-00, registrada nos documentos do processo.",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Exposição dos fatos",
        description:
          "Descreva os fatos relevantes, do ponto de vista do réu, que fundamentam a defesa contra os argumentos do autor.",
        placeholder:
          "O autor não cumpriu suas obrigações contratuais, conforme e-mails anexos que demonstram a recusa em entregar os produtos.",
      },
    },
    {
      name: "preliminary_objections",
      type: "string",
      required: false,
      ui: {
        label: "Preliminares",
        description:
          "Indique eventuais questões preliminares que possam impedir ou prejudicar a análise do mérito da ação.",
        placeholder:
          "Argui-se a incompetência territorial do juízo, conforme art. 64 do CPC, uma vez que o foro adequado é o do domicílio do réu.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumentos jurídicos",
        description:
          "Elabore os argumentos jurídicos que sustentam a contestação, citando leis, normas e jurisprudências aplicáveis.",
        placeholder:
          "O réu exerceu o direito de retenção, suspenso até o cumprimento da contraprestação pelo autor, com base no CC.",
      },
    },
    {
      name: "evidence_text",
      type: "string",
      required: false,
      ui: {
        label: "Descrever provas",
        description:
          "Descreva provas que sustentam a contestação, como registros, documentos ou testemunhos relevantes.",
        placeholder:
          "Anexamos cópias dos contratos, e-mails e recibos que comprovam o cumprimento das obrigações pelo réu e inadimplência do autor.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Liste os pedidos a serem formulados ao juiz, detalhando as providências ou decisões que espera obter",
        placeholder:
          "Requer a total improcedência da ação, com condenação do autor em custas e honorários advocatícios, conforme art. 85 do CPC.",
      },
    },
  ],
  submitButtonText: "Criar Contestação",
});
