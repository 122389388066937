export const jtrcodeToTj = {
  "100": "STF",
  "200": "CNJ",
  "300": "STJ",
  "490": "CJF",
  "401": "TRF1",
  "402": "TRF2",
  "403": "TRF3",
  "404": "TRF4",
  "405": "TRF5",
  "500": "TST",
  "590": "CSJT",
  "501": "TRT1",
  "502": "TRT2",
  "503": "TRT3",
  "504": "TRT4",
  "505": "TRT5",
  "506": "TRT6",
  "507": "TRT7",
  "508": "TRT8",
  "509": "TRT9",
  "510": "TRT10",
  "511": "TRT11",
  "512": "TRT12",
  "513": "TRT13",
  "514": "TRT14",
  "515": "TRT15",
  "516": "TRT16",
  "517": "TRT17",
  "518": "TRT18",
  "519": "TRT19",
  "520": "TRT20",
  "521": "TRT21",
  "522": "TRT22",
  "523": "TRT23",
  "524": "TRT24",
  "600": "TSE",
  "601": "TRE-AC",
  "602": "TRE-AL",
  "603": "TRE-AP",
  "604": "TRE-AM",
  "605": "TRE-BA",
  "606": "TRE-CE",
  "607": "TRE-DF",
  "608": "TRE-ES",
  "609": "TRE-GO",
  "610": "TRE-MA",
  "611": "TRE-MT",
  "612": "TRE-MS",
  "613": "TRE-MG",
  "614": "TRE-PA",
  "615": "TRE-PB",
  "616": "TRE-PR",
  "617": "TRE-PE",
  "618": "TRE-PI",
  "619": "TRE-RJ",
  "620": "TRE-RN",
  "621": "TRE-RS",
  "622": "TRE-RO",
  "623": "TRE-RR",
  "624": "TRE-SC",
  "625": "TRE-SE",
  "626": "TRE-SP",
  "627": "TRE-TO",
  "700": "STM",
  "701": "CJM1",
  "702": "CJM2",
  "703": "CJM3",
  "704": "CJM4",
  "705": "CJM5",
  "706": "CJM6",
  "707": "CJM7",
  "708": "CJM8",
  "709": "CJM9",
  "710": "CJM10",
  "711": "CJM11",
  "712": "CJM12",
  "801": "TJAC",
  "802": "TJAL",
  "803": "TJAP",
  "804": "TJAM",
  "805": "TJBA",
  "806": "TJCE",
  "807": "TJDF",
  "808": "TJES",
  "809": "TJGO",
  "810": "TJMA",
  "811": "TJMT",
  "812": "TJMS",
  "813": "TJMG",
  "814": "TJPA",
  "815": "TJPB",
  "816": "TJPR",
  "817": "TJPE",
  "818": "TJPI",
  "819": "TJRJ",
  "820": "TJRN",
  "821": "TJRS",
  "822": "TJRO",
  "823": "TJRR",
  "824": "TJSC",
  "825": "TJSE",
  "826": "TJSP",
  "913": "TJM-MG",
  "921": "TJM-RS",
  "926": "TJM-SP",
};
