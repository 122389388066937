import { PaginationPayload } from "@/core/api/types";
import { LegalProceedingService } from "@/services/legalProceeding";
import { useQuery } from "@tanstack/react-query";

export const oabsQueryKey = ["oabs"];

export const useOabs = (userId: string, pagination?: PaginationPayload) => {
  return useQuery({
    queryKey: oabsQueryKey,
    queryFn: async () => await LegalProceedingService.listOabsByUserId(userId, pagination),
  });
};
