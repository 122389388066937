import React from "react";
import { Box } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import LoadingRing from "@/assets/svgs/loading-ring.svg?react";

import { WhatsappAssistantPage } from "../WhatsappAssistantPage";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";
import { useImpersonation } from "@/hooks/useImpersonation";
import { WhatsappAssistantLanding } from "../WhatsappAssistantLanding";

export const WhatsappAssistantLayout = () => {
  const { data: connectionData, isLoading: isLoadingConnectionData } = useLexZapCompany();
  const { impersonatedUser } = useImpersonation();

  const hasWhatsappAccess = connectionData?.phoneNumber;

  const isLoading = isLoadingConnectionData;

  if (isLoading) {
    return <LoadingWhatsAppAssistant />;
  }

  if (hasWhatsappAccess || impersonatedUser) {
    return <WhatsappAssistantPage />;
  } else {
    return <WhatsappAssistantLanding />;
  }
};

const LoadingWhatsAppAssistant = () => {
  return (
    <PageLayout
      contentSx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          alignSelf: "center",
        }}
      >
        <LoadingRing width="200px" />
      </Box>
    </PageLayout>
  );
};
