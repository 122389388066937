import { Box, Divider, IconButton, Modal, SxProps, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { ArrowBack } from "@mui/icons-material";

interface ModalRightProps {
  open: boolean;
  onClose: () => void;
  goBack?: () => void;
  title?: string;
  subtitle?: string;
  footer?: React.ReactNode;
  children: React.ReactElement;
  size?: "SMALL" | "LARGE" | "EXLARGE";
  subHeader?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export const ModalRight = ({
  open,
  onClose,
  goBack,
  title,
  subtitle,
  footer,
  children,
  size,
  subHeader,
  ...rest
}: ModalRightProps) => {
  let width: string;

  switch (size) {
    case "SMALL":
      width = "380px";
      break;
    case "LARGE":
      width = "640px";
      break;
    case "EXLARGE":
      width = "900px";
      break;
    default:
      width = "380px";
  }

  const backgroundColor = goBack
    ? {
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
      }
    : undefined;

  return (
    <Modal {...rest} open={open} onClose={onClose} sx={{ ...backgroundColor, ...rest.sx }}>
      <Box
        sx={{
          width: width,
          backgroundColor: "background.paper",
          right: 0,
          position: "fixed",
          height: "100vh",
          display: "grid",
          cursor: "default",
          gridTemplateColumns: "1fr",
          paddingTop: "20px",
          gridTemplateRows: `${subtitle ? "88px 1px" : "48px 1px" + (subHeader ? " 60px" : " 0px") + " 1fr" + (footer ? " 96px" : " 0px")}`,
          gridTemplateAreas: footer
            ? `'lateral-modal-header' 'divider' 'lateral-modal-sub-header' 'lateral-modal-content' 'lateral-modal-footer'`
            : `'lateral-modal-header' 'divider' 'lateral-modal-sub-header' 'lateral-modal-content'`,
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px 16px 16px",
            gridArea: "lateral-modal-header",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {goBack && (
                <IconButton
                  onClick={goBack}
                  sx={{
                    flexShrink: 0,
                  }}
                >
                  <ArrowBack />
                </IconButton>
              )}
              <Typography variant="h3" sx={{ fontWeight: "600", color: "legalBlack" }}>
                {title}
              </Typography>
            </Box>
            <Typography variant="subtitle" sx={{ fontWeight: "400" }}>
              {subtitle}
            </Typography>
          </Box>
          <CloseIcon sx={{ width: "32px", height: "32px", cursor: "pointer" }} onClick={onClose} />
        </Box>

        <Divider sx={{ gridArea: "divider" }} />

        {subHeader && (
          <Box sx={{ gridArea: "lateral-modal-sub-header", display: "flex", alignItems: "center" }}>{subHeader}</Box>
        )}

        <Box
          sx={{
            gridArea: "lateral-modal-content",
            padding: "16px 0 0 0 ",
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "6px" },
            "&::-webkit-scrollbar-track": {
              borderRadius: "10px",
              backgroundColor: "common.white",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: "text.secondary",
            },
            paddingRight: "8px",
            marginLeft: "16px",
            height: footer ? undefined : "100%",
          }}
        >
          {children}
        </Box>
        {footer && (
          <Box
            sx={{
              gridArea: "lateral-modal-footer",
              padding: "0 20px",
              margin: 0,
              alignContent: "center",
            }}
          >
            {footer}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
