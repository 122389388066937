import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CompanyService } from "@/services/company";
import { getCompanyTeamQueryKey } from "../useCompanyTeam";
import { ActivityArea, CompanyMember } from "../types";
import { isAxiosError } from "axios";
import { WebToast } from "@/components/core/Toast";

export const useCreateCompanyMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createTeamMember"],
    mutationFn: async (data: {
      email: string;
      department: string;
      activityAreas?: ActivityArea[];
      isAdmin?: boolean;
    }) => {
      const { id } = await CompanyService.createTeamMember(data);
      return { id, ...data };
    },
    onSuccess: async (data) => {
      await queryClient.cancelQueries({ queryKey: getCompanyTeamQueryKey() });

      queryClient.setQueryData(getCompanyTeamQueryKey(), (old: CompanyMember[]) => [
        ...old,
        {
          userId: data.id,
          email: data.email,
          name: "Convite Pendente",
          department: data.department,
          activityAreas: data.activityAreas,
          isAdmin: data.isAdmin,
        },
      ]);
    },
    onError: (error) => {
      handleMemberCreationError(error);
    },
  });
};

const handleMemberCreationError = (error: unknown) => {
  const errorsMap = {
    409: "E-mail já cadastrado",
    402: "Você atingiu o limite máximo de colaboradores",
  };

  const fallbackError = "Falha ao convidar colaborador";
  if (isAxiosError(error)) {
    WebToast.error(errorsMap[error.status as keyof typeof errorsMap] || fallbackError);
  } else {
    WebToast.error(fallbackError);
  }
};
