import React from "react";
import { FilterList } from "@mui/icons-material";
import { Box, CircularProgress, LinearProgress, Typography } from "@mui/material";
import { GridToolbarQuickFilter, GridToolbarFilterButton, GridColumnMenuProps, GridColumnMenu } from "@mui/x-data-grid";

export const CustomToolbar = ({ disabled, onSearch }: { disabled: boolean; onSearch: (text: string) => void }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        py: 1,
      }}
    >
      <GridToolbarQuickFilter
        sx={{
          flexGrow: 2,
          maxWidth: "500px",
        }}
        placeholder="Pesquisar por nome do cliente ou número do processo"
        fullWidth
        variant="outlined"
        label="Pesquisar"
        disabled={disabled}
        quickFilterParser={(searchText: string) => {
          onSearch(searchText);
          return [searchText];
        }}
        debounceMs={500}
      />
      <GridToolbarFilterButton
        slotProps={{
          button: {
            startIcon: null,
            endIcon: <FilterList />,
            size: "small",
            disabled: disabled,
          },
        }}
      />
    </Box>
  );
};

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
};

export const CustomLoadingOverlay = () => {
  return (
    <Box height="100%" bgcolor="white">
      <LinearProgress />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          height: "100%",
        }}
      >
        <CircularProgress size="16px" color="primary" />
        <Typography variant="body1" component="span">
          Procurando processos vinculados as suas inscrições OAB
        </Typography>
      </Box>
    </Box>
  );
};
