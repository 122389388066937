import React from "react";
import { Box, Skeleton } from "@mui/material";
import { Typography } from "@mui/material";
import { AutoFixHigh } from "@mui/icons-material";

import { Ticket } from "@/hooks/lexZap/types";
import { dateTimeFromTimestamp } from "@/utils/dates/dateTimeFromString";
import { Card } from "../Card";

interface TicketSummaryProps {
  ticketData?: Ticket;
  loadingTicket: boolean;
}

export const TicketSummary = ({ ticketData, loadingTicket }: TicketSummaryProps) => {
  if (loadingTicket || !ticketData) {
    return <TicketSummarySkeleton />;
  }

  return (
    <Card>
      <Box>
        <Typography variant="subtitle1" fontWeight={700}>
          Resumo
        </Typography>
        {ticketData && (
          <Typography variant="body2" color="text.secondary">
            Última atualização em:{" "}
            {dateTimeFromTimestamp(ticketData.updatedAt || ticketData.createdAt).toFormat("dd/MM/yyyy 'às' HH:mm")}
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          bgcolor: ticketData?.summary ? "orange.light.5" : "grey.50",
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
          justifyContent: "center",
        }}
      >
        {ticketData?.summary ? (
          <>
            <Typography variant="subtitle1" color="primary.main" sx={{ display: "flex", alignItems: "start", gap: 2 }}>
              Resumo gerado por IA <AutoFixHigh sx={{ fontSize: "22px" }} />
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Typography
                variant="body"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 5,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  textWrap: "wrap",
                  overflow: "hidden",
                  "&:hover": {
                    WebkitLineClamp: "unset",
                  },
                }}
              >
                {ticketData?.summary}
              </Typography>
            </Box>
          </>
        ) : (
          <Typography variant="subtitle1" textAlign="center" p={2}>
            Nenhum resumo gerado ainda
          </Typography>
        )}
      </Box>
    </Card>
  );
};

const TicketSummarySkeleton = () => {
  return (
    <Card>
      <Box>
        <Typography variant="subtitle1" fontWeight={700}>
          Resumo jurídico
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          Última atualização em: <Skeleton width={150} height={28} />
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: "grey.50",
          p: 3,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Skeleton width={150} height={28} />
          <Skeleton width={24} height={24} variant="circular" />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Skeleton height={20} width="80%" />
        </Box>
      </Box>
    </Card>
  );
};
