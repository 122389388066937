import React, { useMemo } from "react";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import { Add, KeyboardArrowDown } from "@mui/icons-material";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ImportSingleOabModal } from "../ImportSingleOabModal";
import { useOabs } from "@/hooks/oabs/useOabs";
import { useAuthContext } from "@/contexts/AuthContext";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useNavigate } from "react-router-dom";

interface AddCaseButtonProps {
  onCreateCase: () => void;
}

export const AddCaseButton = ({ onCreateCase }: AddCaseButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openImportOAB, setOpenImportOAB] = React.useState(false);
  const { legalProceedingsPageEnabled } = useFeatureFlags();
  const { user } = useAuthContext();
  const { data: oabs, isLoading: isLoadingOabs } = useOabs(user!.userId);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseImportOAB = () => {
    setOpenImportOAB(false);
  };

  const handleOpenImportingByOAB = () => {
    navigate(ROUTE_PATHS.IMPORT_LEGAL_PROCEEDINGS_BY_OAB);
  };

  const importByOabDisabled = useMemo(() => {
    return (oabs?.data.length === 0 || isLoadingOabs) && legalProceedingsPageEnabled;
  }, [oabs, isLoadingOabs, legalProceedingsPageEnabled]);

  return (
    <>
      <ImportSingleOabModal
        open={openImportOAB}
        onClose={handleCloseImportOAB}
        handleOpenImportingByOAB={handleOpenImportingByOAB}
      />
      <Button variant="contained" onClick={handleClick} startIcon={<Add />} endIcon={<KeyboardArrowDown />}>
        <Typography fontWeight={600}>Adicionar caso</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: 1,
        }}
      >
        <MenuItem
          onClick={() => {
            onCreateCase();
            handleClose();
          }}
          sx={{
            borderBottom: "1px solid",
            borderColor: "common.lightShade",
          }}
        >
          Manualmente
        </MenuItem>
        {importByOabDisabled && (
          <MenuItem
            onClick={() => {
              setOpenImportOAB(true);
              handleClose();
            }}
          >
            Importar processo por OAB
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
