import { SnippetInfo, SnippetTypes } from "@/hooks/lexZap/types";

export const getSnippetLabel = (snippetInfo: SnippetInfo | undefined) => {
  if (!snippetInfo) return "";

  switch (snippetInfo.type) {
    case SnippetTypes.TICKET_CLOSED: {
      return `Atendimento encerrado`;
    }
    default: {
      return "";
    }
  }
};
