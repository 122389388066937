import { Phone } from "@/core/Phone";

export const formatPhoneNumber = (phoneNumber: string) => {
  const phone = Phone.new(phoneNumber.substring(2));

  if (phone.isFailure) {
    return phoneNumber;
  }

  return phone.getValue().toFormatSpaced();
};
