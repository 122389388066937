import { logger } from "@/core/logger";
import JSZip from "jszip";

import { asBlob } from "html-docx-js-typescript";
import { getFormattedDate } from "@/utils/formattedDate";
import { saveFileAs } from "@/utils/saveFileAs";
import { Result } from "@/core/Result";

interface DocxOptions {
  orientation: "portrait" | "landscape";
  creator: string;
  lang: string;
  font: string;
  fontSize: number;
}

interface ExportHtmlToDocxOptions {
  /*
   * If true, the document will be split into multiple
   * A zip file will be created and each tab will be a separate DOCX file inside it
   * Default: false
   */
  splitTabs?: boolean;
}

interface DocumentSection {
  title: string;
  content: string;
}

export async function exportHtmlToDocx(html: string, options?: ExportHtmlToDocxOptions): Promise<Result<void>> {
  const { splitTabs = false } = options ?? {};

  try {
    const htmlWithCharset = ensureUtf8Charset(html);
    const processedHtmlDocument = await transformAllHtmlImgToBase64(htmlWithCharset);
    const processedHtmlString = processedHtmlDocument.documentElement.outerHTML.replace(/&nbsp;/g, "");

    const tabBreaks = Array.from(processedHtmlDocument.querySelectorAll("#doc-tab-break"));

    const singleDocument = tabBreaks.length === 0 || !splitTabs;
    if (singleDocument) {
      await exportSingleDocument(processedHtmlString);
    } else {
      await exportMultipleDocuments(processedHtmlDocument, tabBreaks);
    }

    return Result.ok();
  } catch (error) {
    logger.error("Failed to export document to DOCX", error);
    return Result.fail("Failed to export document to DOCX");
  }
}

function ensureUtf8Charset(htmlContent: string): Document {
  const doc = new DOMParser().parseFromString(htmlContent, "text/html");

  if (!doc.querySelector('meta[charset="UTF-8"]')) {
    const meta = doc.createElement("meta");
    meta.setAttribute("charset", "UTF-8");
    doc.head.appendChild(meta);
  }

  return doc;
}

async function exportSingleDocument(html: string): Promise<void> {
  const docx = await convertToDocx(html);

  if (docx.isFailure) {
    throw docx.getError();
  }

  saveFileAs(docx.getValue(), `Documento_${getFormattedDate()}.docx`);
}

async function exportMultipleDocuments(doc: Document, tabBreaks: Element[]): Promise<void> {
  const sections = extractDocumentSections(doc, tabBreaks);
  const files = await createDocxFiles(sections);

  if (files.length === 1) {
    saveFileAs(files[0].content, files[0].name);
  } else {
    await createAndSaveZipFile(files);
  }
}

async function transformAllHtmlImgToBase64(htmlDocument: Document): Promise<Document> {
  const images = Array.from(htmlDocument.querySelectorAll("img"));

  if (!images.length) {
    return htmlDocument;
  }

  try {
    await Promise.all(images.map(convertImageToBase64));
  } catch (e) {
    logger.error("Failed to convert image to base64", e);
  }

  return htmlDocument;
}

async function convertImageToBase64(image: HTMLImageElement): Promise<void> {
  try {
    const base64 = await fetchImageAsBase64(image.src);
    image.src = base64;
    image.srcset = base64;
  } catch (error) {
    logger.error("Failed to convert image to base64", error);
  }
}

async function fetchImageAsBase64(url: string): Promise<string> {
  const response = await fetch(url);
  const blob = await response.blob();
  const pngBlob = new Blob([blob], { type: "image/png" });

  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(pngBlob);
  });
}

function extractDocumentSections(doc: Document, tabBreaks: Element[]): DocumentSection[] {
  const sections: DocumentSection[] = [];
  const childNodes = Array.from(doc.body.childNodes);

  // Helper function to get content between indexes
  const getContentBetweenIndexes = (startIdx: number, endIdx: number) => {
    return childNodes
      .slice(startIdx, endIdx)
      .map((node) => (node as Element).outerHTML || node.textContent || "")
      .join("");
  };

  // Find all tab break indexes
  const tabBreakIndexes = childNodes.reduce<number[]>((acc, node, index) => {
    if (tabBreaks.includes(node as Element)) {
      acc.push(index);
    }
    return acc;
  }, []);

  // Add first section
  if (tabBreakIndexes.length > 0) {
    sections.push({
      title: `Documento_${getFormattedDate()}`,
      content: getContentBetweenIndexes(0, tabBreakIndexes[0]),
    });
  }

  // Process each tab break section
  tabBreakIndexes.forEach((breakIndex, i) => {
    const tabBreak = childNodes[breakIndex] as Element;
    const nextBreakIndex = tabBreakIndexes[i + 1];
    const sectionEndIndex = nextBreakIndex || childNodes.length;

    const title = generateUniqueTitle(tabBreak.getAttribute("data-lexter-title") || `Documento_${i + 1}`, sections);

    sections.push({
      title,
      content: getContentBetweenIndexes(breakIndex + 1, sectionEndIndex),
    });
  });

  return sections;
}

function generateUniqueTitle(baseTitle: string, existingSections: DocumentSection[]): string {
  let count = 1;
  let uniqueTitle = baseTitle;

  while (existingSections.some((s) => s.title === uniqueTitle)) {
    uniqueTitle = `${baseTitle} (${count})`;
    count++;
  }

  return uniqueTitle;
}

async function createDocxFiles(sections: DocumentSection[]) {
  return Promise.all(
    sections.map(async (section) => {
      const docx = await convertToDocx(section.content);

      if (docx.isFailure) {
        throw new Error(docx.getError());
      }

      return {
        name: `${section.title}.docx`,
        content: docx.getValue(),
      };
    })
  );
}

async function createAndSaveZipFile(files: Array<{ name: string; content: Blob | string }>): Promise<void> {
  const zip = new JSZip();
  files.forEach((file) => {
    zip.file(file.name, file.content);
  });

  const zipContent = await zip.generateAsync({ type: "blob" });
  saveFileAs(zipContent, `Documentos_${getFormattedDate()}.zip`);
}

async function convertToDocx(html: string): Promise<Result<Blob | string>> {
  const options: DocxOptions = {
    orientation: "portrait",
    creator: "Lexter",
    lang: "pt_BR",
    font: "Arial",
    fontSize: 24, // 12pt
  };

  if (!html) {
    return Result.ok("");
  }

  try {
    const docx = await asBlob(html, options);
    return Result.ok(docx as Blob);
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    return Result.fail(`Failed to convert html to docx: ${errorMessage}`);
  }
}
