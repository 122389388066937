import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCompanyTeamQueryKey } from "../useCompanyTeam";
import { CompanyMember } from "../types";
import { CompanyService } from "@/services/company";

export const useDeleteCompanyMember = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["useDeleteCompanyMember"],
    mutationFn: async ({ memberId }: { memberId: string }) => {
      await CompanyService.deleteTeamMember({ memberId });
    },
    onMutate: async ({ memberId }) => {
      await queryClient.cancelQueries({ queryKey: getCompanyTeamQueryKey() });
      const previousCases = queryClient.getQueryData<string[]>(getCompanyTeamQueryKey());
      queryClient.setQueryData(getCompanyTeamQueryKey(), (old: CompanyMember[]) => [
        ...old.filter((c) => c.userId !== memberId),
      ]);
      return { previousCases };
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData(getCompanyTeamQueryKey(), context?.previousCases);
    },
  });
};
