import { isAxiosError } from "axios";

import { LegalProceedingService } from "@/services/legalProceeding";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LegalProceedingToCasePayload } from "@/services/legalProceeding/types";
import { Case } from "@/hooks/lexZap/types";
import { DateTime } from "luxon";
import { getCasesQueryKey } from "@/hooks/lexZap/useLexZapCases";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { legalProceedingsQueryKey } from "@/hooks/legalProceedings/usePaginatedLegalProceedingsQuery";

export const useLegalProceedingToCaseMutation = () => {
  const api = LegalProceedingService;
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["importLegalProceedingToCase"],
    mutationFn: async (payload: LegalProceedingToCasePayload) => await api.transformLegalProceedingsToCase(payload),
    onSuccess: async (data) => {
      if (data.cases.length > 0) {
        await queryClient.cancelQueries({ queryKey: getCasesQueryKey() });
        queryClient.setQueryData(getCasesQueryKey(), (old: Case[]) => {
          return [
            ...old,
            ...data.cases.map((c: Case) => ({
              ...c,
              timeline: [{ status: "MANUALLY_CREATED", createdAt: DateTime.now().toISO() }],
            })),
          ];
        });
        void queryClient.invalidateQueries({ queryKey: legalProceedingsQueryKey });
      }
    },
    onError: (error) => {
      handleErrorImportingLegalProceedings(error);
    },
  });
};

const handleErrorImportingLegalProceedings = (error: unknown) => {
  logger.error("handleErrorImportingLegalProceedings", error);
  if (isAxiosError(error) && error.response?.status === 402) {
    WebToast.error("Você atingiu o limite máximo de casos");
  } else {
    WebToast.error("Erro ao importar processos");
  }
};
