import { CompanyRole, useAuthContext } from "@/contexts/AuthContext";
import { LexZapService } from "@/services/lexZap";
import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";

export const getLexZapUserQueryKey = () => ["lexZapUser"];

export const useLexZapUser = () => {
  const { user } = useAuthContext();
  const isCompanyAdmin = user?.companyRole === CompanyRole.ADMIN;

  return useQuery({
    queryKey: getLexZapUserQueryKey(),
    queryFn: async () => {
      try {
        return await LexZapService.getMe();
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          return null;
        }
        throw error;
      }
    },
    enabled: isCompanyAdmin,
  });
};
