import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { CircularProgress, Box, Typography } from "@mui/material";

interface DeleteTicketModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const CloseTicketModal = ({ isOpen, onConfirm, onCancel, isLoading }: DeleteTicketModalProps) => {
  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title="Encerrar atendimento?"
      description={
        isLoading ? (
          <Box display="flex" color="secondary" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: "text.primary" }}>
            Tem certeza que deseja encerrar este atendimento?
          </Typography>
        )
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "Manter aberto",
          onClick: isLoading ? undefined : onCancel,
          sx: {
            color: "text.primary",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          label: "Encerrar",
          onClick: onConfirm,
          color: "error",
          disabled: isLoading,
        },
      ]}
      variant="error"
    />
  );
};
