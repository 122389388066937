import { TypographyOptions } from "@mui/material/styles/createTypography";

const fontFamily = ["lato", "kommon grotesk extended", "sans-serif"].join(",");
const headerFontFamily = ["Lexend Variable", fontFamily].join(",");

export const typography: TypographyOptions = {
  fontFamily,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  htmlFontSize: 14,
  h1: {
    fontFamily: headerFontFamily,
    fontWeight: "bold",
    fontSize: "64px",
    letterSpacing: "-2%",
  },
  h2: {
    fontFamily: headerFontFamily,
    fontWeight: "bold",
    fontSize: "40px",
    letterSpacing: "-2%",
  },
  h3: {
    fontFamily: headerFontFamily,
    fontWeight: "bold",
    fontSize: "24px",
    letterSpacing: "-2%",
  },
  h4: {
    fontFamily: headerFontFamily,
    fontWeight: 400,
    fontSize: "34px",
    lineHeight: "42px",
    letterSpacing: "0.25px",
  },
  h5: {
    fontFamily: headerFontFamily,
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "32px",
  },
  h6: {
    fontFamily: headerFontFamily,
    fontWeight: "bold",
    fontSize: "20px",
    letterSpacing: "-2%",
  },
  button: {
    fontFamily,
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "3%",
    lineHeight: "17px",
  },
  subtitle: {
    fontFamily,
    fontWeight: 400,
    fontSize: "24px",
    letterSpacing: "-2%",
  },
  subtitle1: {
    fontFamily,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    fontFamily,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0.1px",
  },
  body: {
    fontFamily,
    fontWeight: 400,
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  body1: {
    fontFamily,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  },
  body2: {
    fontFamily,
    fontWeight: 400,
    fontSize: "12px",
  },
  body3: {
    fontFamily,
    fontWeight: 400,
    fontSize: "14px",
  },
  label: {
    fontFamily,
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  multiLineBody: {
    fontFamily,
    fontWeight: 400,
    fontSize: "14px",
    whiteSpace: "normal",
  },
  bold: {
    fontFamily,
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "140%",
  },
  pageTitle: {
    fontFamily,
    fontWeight: "700",
    fontSize: "24px",
  },
  title: {
    fontFamily,
    fontWeight: "700",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
  },
  preTitle: {
    fontFamily,
    fontWeight: "700",
    fontSize: "12px",
    letterSpacing: "0.03em",
    lineHeight: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
  },
  multiLinePreTitle: {
    fontFamily,
    fontWeight: "700",
    fontSize: "12px",
    letterSpacing: "0.03em",
    lineHeight: "140%",
    whiteSpace: "normal",
  },
  link: {
    fontFamily,
    cursor: "pointer",
    fontWeight: "400",
    fontSize: "14px",
    textDecoration: "underline",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    "&:hover": {
      fontWeight: "700",
    },
  },
};
