import React from "react";
import { Box } from "@mui/material";

import { Ticket } from "@/hooks/lexZap/types";
import { Applicant } from "@/services/applicant/types";
import { Button } from "@/components/Button";
import { ApplicantModal } from "@/components/Applicants";
import { CaseSuggestionSkills } from "@/components/Cases/CaseSuggestionSkills";
import { CaseDetailsChat } from "@/components/Cases/CaseDetailsChat";
import { CaseFiles } from "@/components/Cases/CaseFiles";

import { TicketCard } from "./components/TicketCard";
import { TicketSummary } from "./components/TicketSummary";

interface TicketDetailsProps {
  ticketData?: Ticket;
  applicant?: Applicant;
  loadingTicket: boolean;
  onCreateCase: () => void;
}

export const TicketDetails = ({ ticketData, loadingTicket, applicant, onCreateCase }: TicketDetailsProps) => {
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] = React.useState(false);

  if (!ticketData) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          px: 4,
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onCreateCase}
          disabled={loadingTicket}
          sx={{
            textTransform: "none",
            mt: 1,
            height: "50px",
          }}
        >
          Ativar Caso
        </Button>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1.5fr 1fr",
          gridTemplateAreas: `"main sidebar"`,
          gap: 3,
          alignItems: "start",
          p: 4,
          pt: 2,
        }}
      >
        {applicant && (
          <ApplicantModal
            applicantId={applicant.id}
            isOpen={isEditApplicantModalOpen}
            onClose={() => setIsEditApplicantModalOpen(false)}
          />
        )}

        <Box sx={{ gridArea: "main", display: "flex", flexDirection: "column", gap: 3 }}>
          <TicketCard
            ticketData={ticketData}
            loadingTicket={loadingTicket}
            openEditApplicantModal={() => setIsEditApplicantModalOpen(true)}
            applicant={applicant}
          />
          <TicketSummary ticketData={ticketData} loadingTicket={loadingTicket} />
        </Box>

        <Box sx={{ gridArea: "sidebar", display: "flex", flexDirection: "column", gap: 3 }}>
          <CaseSuggestionSkills caseData={undefined} loadingCase={false} />

          <CaseDetailsChat
            applicant={applicant}
            loadingCase={loadingTicket}
            archived={false}
            handleEditApplicantModal={() => setIsEditApplicantModalOpen(true)}
          />
          <CaseFiles applicant={applicant} />
        </Box>
      </Box>
    </Box>
  );
};
