import { getCaseDocumentPath } from "@/routes/routePaths";
import { Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "@/utils/formattedDate";
import { saveFileAs } from "@/utils/saveFileAs";
import juice from "juice";
import { useApi } from "@/hooks/useApi";
// @ts-expect-error No types available
import htmlToDocx from "html-to-docx";
import { DateTime } from "luxon";
import axios from "axios";
import { useDeleteRecord } from "@/hooks/records/useDeleteRecord";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { MoreVert } from "@mui/icons-material";
import { RemoveDocumentCaseModal } from "../RemoveDocumenteCaseModal";
import { useDownloadCaseLegalProceedingsFiles } from "@/hooks/legalProceedings/useDownloadCaseLegalProceedingsFiles";
import { CaseFileItem } from "@/core/CaseFile";
import { useAuthContext } from "@/contexts/AuthContext";

interface CaseFileProps {
  caseId?: string;
  data: CaseFileItem;
  threadId?: string;
}

export const CaseFile = ({ data, caseId, threadId }: CaseFileProps) => {
  const navigate = useNavigate();
  const { getDocumentById } = useApi();
  const { user } = useAuthContext();
  const [isRemoveDocumentModalOpen, setIsRemoveDocumentModalOpen] = React.useState(false);
  const { mutateAsync: deleteRecord, isPending: isDeleting } = useDeleteRecord({ threadId });
  const { mutateAsync: downloadCaseLegalProceedingsFiles, isPending: isDownloading } =
    useDownloadCaseLegalProceedingsFiles();

  const openDocument = () => {
    if (!caseId) return;
    navigate(getCaseDocumentPath({ caseId, documentId: data.id.toString() }));
  };

  const exportDocumentContent = async () => {
    if (!data.isRecord()) return;
    const document = await getDocumentById(data.id as number);
    // Código necessario para negrito ser exportado corretamente pro word
    const htmlWithInlineContent = juice.inlineContent(document.content, "p:has(strong) { font-weight: bold;}");
    const docx = await htmlToDocx(htmlWithInlineContent, "", {
      orientation: "portrait",
      creator: "Lexter",
      lang: "pt_BR",
      font: "Arial",
      fontSize: 24, // 12pt
    });

    saveFileAs(docx, `${document.name}_${getFormattedDate()}.docx`);
  };

  const exportFileContent = async () => {
    if (data.isLegalProceedingAttachedFile() && caseId) {
      return await downloadCaseLegalProceedingsFiles({ caseId, file: data });
    }

    if (!data.isMedia()) return;

    const response = await axios.get(data.url!, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": data.mimeType,
      },
    });

    const blob = new Blob([response.data], {
      type: data.mimeType!,
    });

    const url = URL.createObjectURL(blob);

    saveFileAs(url, data.name);
  };

  const openRemoveDocument = () => {
    setIsRemoveDocumentModalOpen(true);
  };

  const handleRemove = async () => {
    if (!caseId || !data.isRecord() || !user) return;

    await deleteRecord({
      id: Number(data.id),
      type: data.recordType!,
      name: data.name,
      createdAt: DateTime.fromMillis(data.createdAt).toISO()!,
      threadId: data.threadId,
      userId: user.userId,
    });
    setIsRemoveDocumentModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 1,
        pb: 1.5,
        borderBottomColor: "common.lightShade",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        marginX: -3,
        paddingX: 3,
      }}
    >
      <Box>
        <Typography
          variant="body1"
          mb={0.5}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            "&:hover": {
              overflow: "visible",
              WebkitLineClamp: "unset",
            },
          }}
        >
          {data.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" fontSize="14px">
          {DateTime.fromMillis(data.createdAt).toFormat("dd/MM/yyyy")}
        </Typography>
      </Box>

      <PopupState variant="popover">
        {(popupState) => (
          <React.Fragment>
            <IconButton {...bindTrigger(popupState)}>
              <MoreVert />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              {data.type === "RECORD" ? (
                <DocumentButtons
                  openDocument={openDocument}
                  downloadDocument={exportDocumentContent}
                  removeDocument={openRemoveDocument}
                  onClose={popupState.close}
                />
              ) : (
                <FileButtons downloadFile={exportFileContent} onClose={popupState.close} loading={isDownloading} />
              )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <RemoveDocumentCaseModal
        isOpen={isRemoveDocumentModalOpen}
        onCancel={() => setIsRemoveDocumentModalOpen(false)}
        onConfirm={handleRemove}
        isLoading={isDeleting}
        name={data.name}
      />
    </Box>
  );
};

const DocumentButtons = ({
  removeDocument,
  openDocument,
  downloadDocument,
  onClose,
}: {
  removeDocument: () => void;
  openDocument: () => void;
  downloadDocument: () => void;
  onClose: () => void;
}) => {
  const handleClick = (callback: () => void) => {
    callback();
    onClose();
  };

  return (
    <>
      <MenuItem onClick={() => handleClick(openDocument)}>Acessar</MenuItem>
      <MenuItem onClick={() => handleClick(downloadDocument)}>Baixar</MenuItem>
      <MenuItem onClick={() => handleClick(removeDocument)}>Remover</MenuItem>
    </>
  );
};

const FileButtons = ({
  downloadFile,
  onClose,
  loading,
}: {
  downloadFile: () => Promise<void>;
  onClose: () => void;
  loading: boolean;
}) => {
  const handleDownload = async () => {
    await downloadFile();
    onClose();
  };

  return (
    <MenuItem onClick={handleDownload} disabled={loading}>
      {loading ? <CircularProgress size={22} color="primary" /> : "Baixar"}
    </MenuItem>
  );
};
