import { AxiosResponse } from "axios";
import { Paginated } from "../../types";
import { LegalProceedingRequest } from "@/services/legalProceeding/types";

export const responseFromPaginationHeaders = <T>(response: AxiosResponse): Paginated<T> => {
  return {
    data: response.data,
    page: Number(response.headers["x-pagination-page"]),
    pageSize: Number(response.headers["x-pagination-page-size"]),
    totalResults: Number(response.headers["x-pagination-total-results"]),
  };
};

export const legalProceedingFromPaginationHeaders = (response: AxiosResponse): LegalProceedingRequest => {
  const data = response.data as LegalProceedingRequest;
  return {
    data: data!.data ?? [],
    status: data!.status ?? "IMPORTING",
    page: Number(response.headers["x-pagination-page"]),
    pageSize: Number(response.headers["x-pagination-page-size"]),
    totalResults: Number(response.headers["x-pagination-total-results"]),
  };
};
