import React from "react";
import { Box, SxProps, Theme } from "@mui/material";

interface RoundedIconProps {
  icon: React.ReactNode;
  bgcolor?: string;
  size?: number;
  sx?: SxProps<Theme>;
}

export const RoundedIcon = ({ icon, bgcolor = "common.black", size = 40, sx }: RoundedIconProps) => {
  return (
    <Box
      sx={{
        bgcolor,
        width: size,
        height: size,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "common.white",
        ...sx,
      }}
    >
      {icon}
    </Box>
  );
};
