import { Ticket } from "../types";
import { useLexZapGetTickets } from "../useLexZapGetTickets";

export const getCaseQueryKey = (caseId?: string) => ["case", caseId];

interface UseLexZapTicketProps {
  ticketId?: string;
}

interface UseLexZapTicketReturn {
  ticket?: Ticket;
  isLoading: boolean;
  isError: boolean;
}

export const useLexZapTicket = ({ ticketId }: UseLexZapTicketProps): UseLexZapTicketReturn => {
  const { data: tickets, isLoading, isError } = useLexZapGetTickets();

  return {
    ticket: tickets?.find((ticketItem) => ticketItem.id === ticketId),
    isLoading,
    isError,
  };
};
