export const getBlobTypeByFileType = (fileType: string) => {
  const contentTypeMap: Record<string, string> = {
    htm: "text/html",
    txt: "text/plain",
    pdf: "application/pdf",
    html: "application/pdf",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    svg: "image/svg+xml",
    mp3: "audio/mpeg",
    mp4: "video/mp4",
    wav: "audio/wav",
    zip: "application/zip",
    rar: "application/x-rar-compressed",
    xml: "application/xml",
    json: "application/json",
    csv: "text/csv",
    rtf: "application/rtf",
  };

  return contentTypeMap[fileType.toLowerCase()] || `application/${fileType}`;
};
