import React, { useMemo } from "react";
import {
  actionNameVariants,
  ActionNameVariantsType,
  mapperActionToFields,
} from "@/hooks/skills/useActionsSearch/actionNameVariants";
import { SearchOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  TextField,
  InputAdornment,
  createFilterOptions,
  MenuItem,
  FilterOptionsState,
} from "@mui/material";
import { SkillId } from "@/core/skills/types";

type Option = {
  label: string;
  value: ActionNameVariantsType;
};

const filter = createFilterOptions<Option>();
const PIECE_NOT_FOUND = {
  label: "Não encontrei a peça que eu queria",
  value: "create_other_type_of_piece" as ActionNameVariantsType,
};

type SearchSkillsBarProps = {
  onSelectSkill: (props: { skillId: SkillId; customTitle?: string; defaultData?: Record<string, string> }) => void;
};

export const SearchSkillsBar = ({ onSelectSkill }: SearchSkillsBarProps) => {
  const [value, setValue] = React.useState<Option | null>(null);

  const options = useMemo(() => {
    return Object.entries(actionNameVariants)
      .flatMap(([actionType, items]) =>
        items.map((item) => ({
          label: item,
          value: actionType as ActionNameVariantsType,
        }))
      )
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  const isDidNotFindWhatHeWanted = (option: Option) => {
    return option.value === PIECE_NOT_FOUND.value;
  };

  const handleChange = (option: Option | null) => {
    if (!option) return;

    const skillData = mapperActionToFields[option.value] ?? {};
    onSelectSkill({
      skillId: "skillId" in skillData ? skillData.skillId : "create_other_type_of_piece",
      customTitle: isDidNotFindWhatHeWanted(option) ? "peça" : option.label,
      defaultData: {
        document_name: isDidNotFindWhatHeWanted(option) ? "" : option.label,
        ...skillData,
      },
    });
  };
  const filteredOptions = (options: Option[], params: FilterOptionsState<Option>) => {
    const filtered = filter(options, params);
    filtered.push(PIECE_NOT_FOUND);
    return filtered;
  };

  return (
    <Autocomplete<Option>
      sx={{ mb: 2.5 }}
      options={options}
      size="medium"
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
        handleChange(newValue);
      }}
      filterOptions={filteredOptions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined fontSize="small" />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input": {
              pl: "0!important",
            },
          }}
          placeholder="Digite o nome da peça"
          hiddenLabel
          fullWidth
        />
      )}
      renderOption={(props, option, state) => (
        <MenuItem
          sx={{
            borderTop: isDidNotFindWhatHeWanted(option) ? "1px solid #E0E0E0" : undefined,
            my: 0.5,
          }}
          {...props}
          key={option.label}
          selected={state.selected}
        >
          {option.label}
        </MenuItem>
      )}
    />
  );
};
