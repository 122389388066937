import React from "react";
import ExportContacts from "@/assets/images/ExportContacts.jpg";
import { Box } from "@mui/material";

export const ExportContactsGuide = () => {
  return (
    <Box
      sx={{
        width: "100%",
        overflowY: "hidden",
      }}
    >
      <img
        src={ExportContacts}
        style={{
          width: "100%",
          objectFit: "cover",
          overflowY: "auto",
          overflowX: "hidden",
          height: "100%",
        }}
      />
    </Box>
  );
};
