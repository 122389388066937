import { authenticatedCopilotServer } from "@/core/api";
import { logger } from "@/core/logger";
import { getPaginationHeaders } from "@/core/api/utils/getPaginationHeaders";
import {
  AddCourtCredentialPayload,
  CourtCredentialPayload,
  CourtCredentialType,
  GetLegalProceedingsPayload,
  ImportLegalProceedingFromOABPayload,
  LegalProceedingHistory,
  LegalProceedingRequest,
  LegalProceedingToCasePayload,
  LegalProceedingType,
  OABType,
  UntrackLegalProceedingPayload,
} from "./types";
import { PaginationPayload } from "@/core/api/types";
import {
  legalProceedingFromPaginationHeaders,
  responseFromPaginationHeaders,
} from "@/core/api/utils/fromPaginationHeaders";

export const LegalProceedingService = {
  getLegalProceedings: async ({ pagination }: GetLegalProceedingsPayload) => {
    try {
      const response = await authenticatedCopilotServer.get<LegalProceedingRequest>(`/legalProceedings`, {
        headers: getPaginationHeaders(pagination),
      });

      return legalProceedingFromPaginationHeaders(response);
    } catch (e) {
      logger.error("getLegalProceedings", e);
      throw e;
    }
  },

  importLegalProceedingFromOAB: async (payload: ImportLegalProceedingFromOABPayload) => {
    try {
      const response = await authenticatedCopilotServer.post<LegalProceedingRequest>(
        `/users/${payload.userId}/oab`,
        payload.oabs
      );
      return response.data;
    } catch (e) {
      logger.error("importLegalProceedingFromOAB", e);
      throw e;
    }
  },

  attachLegalProceedingToCase: async ({ cnj, caseId }: { cnj: string; caseId?: string }) => {
    try {
      const response = await authenticatedCopilotServer.put(`/cases/${caseId}/legalProceedings`, {
        cnj,
      });
      return response.data;
    } catch (e) {
      logger.error("attachLegalProceedingToCase", e);
      throw e;
    }
  },

  transformLegalProceedingsToCase: async (payload: LegalProceedingToCasePayload) => {
    try {
      const response = await authenticatedCopilotServer.post(`/cases/cnj`, payload);
      return response.data;
    } catch (e) {
      logger.error("transformLegalProceedingsToCase", e);
      throw e;
    }
  },

  untrackLegalProceeding: async (payload: UntrackLegalProceedingPayload) => {
    try {
      const response = await authenticatedCopilotServer.delete(`/legalProceedings/${payload.cnj}/track`);
      return response.data;
    } catch (e) {
      logger.error("untrackLegalProceeding", e);
      throw e;
    }
  },

  getLegalProceedingsToCase: async () => {
    try {
      const response = await authenticatedCopilotServer.get(`/legalProceedings/toCase`);
      return response.data;
    } catch (e) {
      logger.error("getLegalProceedingsToCase", e);
      throw e;
    }
  },

  getCourtCredentials: async (userId: string) => {
    try {
      const response = await authenticatedCopilotServer.get<CourtCredentialType[]>(`/users/${userId}/courtCredentials`);
      return response.data;
    } catch (e) {
      logger.error("getCourtCredentials", e);
      throw e;
    }
  },

  addCourtCredentials: async (userId: string, payload: AddCourtCredentialPayload) => {
    try {
      const response = await authenticatedCopilotServer.post(`/users/${userId}/courtCredentials`, payload);
      return response.data;
    } catch (e) {
      logger.error("addCourtCredentials", e);
      throw e;
    }
  },

  revokeCourtCredentials: async (userId: string, id: string) => {
    try {
      const response = await authenticatedCopilotServer.delete(`/users/${userId}/courtCredentials/${id}`);
      return response.data;
    } catch (e) {
      logger.error("revokeCourtCredentials", e);
      throw e;
    }
  },

  updateCourtCredentials: async (userId: string, id: string, payload: CourtCredentialPayload) => {
    try {
      const response = await authenticatedCopilotServer.put(`/users/${userId}/courtCredentials/${id}`, payload);
      return response.data;
    } catch (e) {
      logger.error("updateCourtCredentials", e);
      throw e;
    }
  },

  listOabsByUserId: async (userId: string, pagination?: PaginationPayload) => {
    try {
      const response = await authenticatedCopilotServer.get(`/users/${userId}/oab`, {
        headers: getPaginationHeaders(pagination),
      });

      return responseFromPaginationHeaders<OABType>(response);
    } catch (e) {
      logger.error("listOabsByUserId", e);
      throw e;
    }
  },

  deleteOab: async (userId: string, oabId: string) => {
    try {
      const response = await authenticatedCopilotServer.delete(`/users/${userId}/oab/${oabId}`);
      return response.data;
    } catch (e) {
      logger.error("deleteOab", e);
      throw e;
    }
  },

  getLegalProceedingsAttachedInCase: async (caseId: string) => {
    try {
      const response = await authenticatedCopilotServer.get<{
        legalProceedings: LegalProceedingType[];
      }>(`/cases/${caseId}/legalProceedings`);
      return response.data;
    } catch (e) {
      logger.error("getLegalProceedingsAttachedInCase", e);
      throw e;
    }
  },

  getLegalProceedingHistory: async (cnj: string, instance: string) => {
    try {
      const response = await authenticatedCopilotServer.get<LegalProceedingHistory>(
        `/legalProceedings/${cnj}/${instance}/history`
      );
      return response.data;
    } catch (e) {
      logger.error("getLegalProceedingHistory", e);
      throw e;
    }
  },

  clearLegalProceedings: async ({ userId, companyId }: { userId: string; companyId: string }) => {
    try {
      const response = await authenticatedCopilotServer.post(`/admin/legal-proceedings/clear`, {
        userId,
        companyId,
      });
      return response.data;
    } catch (e) {
      logger.error("clearLegalProceedings", e);
      throw e;
    }
  },

  clearJuditTrackings: async () => {
    try {
      const response = await authenticatedCopilotServer.delete<{ message: string }>(`/admin/judit/trackings`);
      return response.data;
    } catch (e) {
      logger.error("clearJuditTrackings", e);
      throw e;
    }
  },
};
