export const normalizeOAB = (oab: string) => {
  return {
    oab: oab.split("/")[0],
    uf: oab.split("/")[1],
  };
};

export const normalizedOABToDisplay = (oab: string, uf: string) => {
  return `${oab}/${uf.toUpperCase()}`;
};
