import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Lock, ArrowOutwardOutlined } from "@mui/icons-material";

import { ROUTE_PATHS } from "@/routes/routePaths";

import { useCredits } from "@/hooks/credits/useCredits";
import { useNavigate } from "react-router-dom";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { PlanType } from "@/hooks/credits/types";
import { useMemo } from "react";
import { useNotificationsQuery } from "@/hooks/notifications/useNotificationsQuery";
import { useLexZapGetTickets } from "@/hooks/lexZap/useLexZapGetTickets";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

export const InfoBox = ({
  type,
  backgroundColor = "grey.50",
  iconInTheBox = true,
}: {
  type: "reception" | "cases_with_updates" | "active_cases";
  backgroundColor?: string;
  iconInTheBox?: boolean;
}) => {
  const navigate = useNavigate();
  const { shouldValidateCasesQuota } = useFeatureFlags();
  const title = {
    reception: "Atendimentos",
    cases_with_updates: "Casos com atualizações",
    active_cases: "Casos ativos",
  }[type];
  const { data: credits } = useCredits();
  const { data: cases } = useLexZapCases();
  const { data: tickets } = useLexZapGetTickets({ status: "ACTIVE" });
  const { data: notifications } = useNotificationsQuery();
  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  const isEssential = credits?.companyPlan?.plan === PlanType.UNLIMITED;
  const isLocked = (isTrial || isEssential) && type === "reception";

  const activeCases = useMemo(() => {
    if (!cases) return 0;
    return cases.filter((c) => c.status === "ACTIVE").length;
  }, [cases]);

  const updatedCases = useMemo(
    () =>
      cases?.filter((c) =>
        notifications?.some(
          (n) =>
            n.notificationType === "CASE_UPDATED" && !n.visualizedAt && c.id === n.data.caseId && c.status === "ACTIVE"
        )
      ).length,
    [notifications, cases]
  );

  const activeTickets = useMemo(() => {
    if (!Array.isArray(tickets)) return 0;
    return tickets.filter((t: { status: string }) => t.status === "ACTIVE").length;
  }, [tickets]);

  const casesQuota = credits?.companyPlan?.limitQuotaCases;
  const shouldShowCasesQuota =
    shouldValidateCasesQuota && typeof credits?.companyPlan?.limitQuotaCases === "number"
      ? credits?.companyPlan?.limitQuotaCases > 0
      : false;

  const boxConfig = {
    reception: {
      title: "Atendimentos",
      value: <Typography variant="h5">{activeTickets}</Typography>,
      route: ROUTE_PATHS.WHATSAPP_ASSISTANT,
    },
    cases_with_updates: {
      title: "Casos com atualizações",
      value: <Typography variant="h5">{updatedCases}</Typography>,
      route: ROUTE_PATHS.CASES,
    },
    active_cases: {
      title: "Casos ativos",
      value: (
        <Typography variant="h5">
          {activeCases}
          {shouldShowCasesQuota && ` de ${casesQuota}`}
        </Typography>
      ),
      route: ROUTE_PATHS.ACTIVE_CASES,
    },
  }[type];

  const handleClick = () => {
    if (boxConfig.route) {
      navigate(boxConfig.route);
    }
  };

  return (
    <ButtonBase
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        minHeight: "125px",
        backgroundColor: backgroundColor,
        p: 2,
        borderRadius: 2,
        transition: "background-color 0.3s ease",
        "&:hover": {
          backgroundColor: "grey.200",
        },
      }}
      onClick={handleClick}
    >
      <Typography variant="subtitle1" sx={{ textAlign: "left", width: "100%" }}>
        {title}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Typography variant="body1">
          {isLocked ? (
            <Typography
              variant="body1"
              sx={{ display: "flex", width: "100%", alignItems: "center", gap: 1, fontWeight: 700, fontSize: "1.2rem" }}
            >
              Contrate agora
            </Typography>
          ) : (
            <>{boxConfig.value}</>
          )}
        </Typography>
        {isLocked && iconInTheBox ? <Lock sx={{ color: "action.active" }} /> : <ArrowOutwardOutlined />}
      </Box>
    </ButtonBase>
  );
};
