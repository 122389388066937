import { ApplicantService } from "@/services/applicant";
import { useAuthStore } from "@/stores/useAuthStore";
import { useQuery } from "@tanstack/react-query";

export const getApplicantsQueryKey = () => ["applicants"];

export const useApplicants = () => {
  const { isLoggedIn } = useAuthStore();

  return useQuery({
    queryKey: getApplicantsQueryKey(),
    queryFn: async () => {
      const applicants = await ApplicantService.getApplicants();

      return applicants.sort((a, b) => a.name.localeCompare(b.name));
    },
    enabled: isLoggedIn,
    staleTime: 1000 * 60,
  });
};
