import { authenticatedCopilotServer } from "@/core/api";
import { LexZapDlqEvent } from "./types";
import { Case, CaseMessage, LexZapChat, Ticket } from "@/hooks/lexZap/types";
import { LexZapUser } from "@/hooks/lexZap/types";
import { ActivityArea, Department } from "@/hooks/company/types";

export const LexZapService = {
  getDlqEvents: async () => {
    const response = await authenticatedCopilotServer.get<LexZapDlqEvent[]>(`/dlqEvents`);
    return response.data;
  },
  reprocessDlqEvents: async ({ ids }: { ids: string[] }) => {
    await authenticatedCopilotServer.post(`/dlqEvents/reprocess`, { ids });
  },
  deleteDlqEvent: async ({ id }: { id: string }) => {
    await authenticatedCopilotServer.delete(`/dlqEvents/${id}`);
  },
  getMe: async () => {
    const response = await authenticatedCopilotServer.get<LexZapUser>(`whatsapp-assistant/users/me`);
    return response.data;
  },
  updateMe: async (data: { notificationNumber: string; department: Department; activityAreas?: ActivityArea[] }) => {
    await authenticatedCopilotServer.post<LexZapUser>(`whatsapp-assistant/users/me`, data);
  },
  getLexZapChats: async () => {
    const response = await authenticatedCopilotServer.get<LexZapChat[]>(`/whatsapp-assistant/chats`);
    return response.data;
  },
  deactivateAi: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/deactivateAi`, {
      applicantPhoneNumber,
    });
  },
  activateAi: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/activateAi`, {
      applicantPhoneNumber,
    });
  },
  markChatAsAnswered: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/markAsAnswered`, {
      applicantPhoneNumber,
    });
  },
  getTickets: async (params?: { status?: "ACTIVE" | "CLOSED" }) => {
    const response = await authenticatedCopilotServer.get<Ticket[]>(`/whatsapp-assistant/tickets`, {
      params,
    });
    return response.data;
  },
  updateTicket: async ({
    ticketId,
    applicantPhoneNumber,
    updateFields,
  }: {
    ticketId: string;
    applicantPhoneNumber: string;
    updateFields: Partial<Ticket>;
  }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/tickets/${ticketId}`, {
      applicantPhoneNumber,
      updateFields,
    });
  },
  closeTicket: async ({ ticketId, applicantPhoneNumber }: { ticketId: string; applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/tickets/${ticketId}/close`, {
      applicantPhoneNumber,
    });
  },
  archiveChat: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/archive`, {
      applicantPhoneNumber,
    });
  },
  unarchiveChat: async ({ applicantPhoneNumber }: { applicantPhoneNumber: string }) => {
    await authenticatedCopilotServer.post(`/whatsapp-assistant/chats/unarchive`, {
      applicantPhoneNumber,
    });
  },
};

export const AdminLexZapService = {
  getLexZapCases: async ({ companyId }: { companyId: string }) => {
    const response = await authenticatedCopilotServer.get<Case[]>(`/admin/cases`, {
      params: {
        companyId,
      },
    });
    return response.data;
  },
  getLexZapMessages: async ({
    companyId,
    applicantPhoneNumber,
  }: {
    companyId: string;
    applicantPhoneNumber: string;
  }) => {
    const response = await authenticatedCopilotServer.get<{ messages: CaseMessage[] }>(`/admin/cases/chats/messages`, {
      params: { companyId, applicantPhoneNumber },
    });
    return response.data;
  },
  getLexZapChats: async ({ companyId }: { companyId: string }) => {
    const response = await authenticatedCopilotServer.get<LexZapChat[]>(`/admin/whatsapp-assistant/chats`, {
      params: { companyId },
    });
    return response.data;
  },
};
