import { useApi } from "@/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Case } from "../types";
import { legalProceedingsQueryKey } from "@/hooks/legalProceedings/usePaginatedLegalProceedingsQuery";

const CASES_QUERY_KEY = "lexZapCases";

export const useDeleteCases = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationKey: ["deleteCases"],
    mutationFn: ({ casesIds }: { casesIds: string[] }) =>
      Promise.all(casesIds.map((caseId) => api.deleteCase({ caseId }))),
    onMutate: async ({ casesIds }) => {
      await queryClient.cancelQueries({ queryKey: [CASES_QUERY_KEY] });
      const previousCases = queryClient.getQueryData<string[]>([CASES_QUERY_KEY]);
      queryClient.setQueryData([CASES_QUERY_KEY], (old: Case[]) => [
        ...old.map((c) => (casesIds.includes(c.id) ? { ...c, status: "DELETED" } : c)),
      ]);
      return { previousCases };
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: legalProceedingsQueryKey });
    },
    onError: (_error, _newCase, context) => {
      queryClient.setQueryData([CASES_QUERY_KEY], context?.previousCases);
    },
  });
};
