import { LegalProceedingService } from "@/services/legalProceeding";
import { useQuery } from "@tanstack/react-query";

export const courtCredentialsQueryKey = ["courtCredentials"];

export const useCourtCredentials = ({ userId }: { userId?: string }) => {
  return useQuery({
    queryKey: [...courtCredentialsQueryKey, userId],
    queryFn: async () => {
      if (!userId) {
        return [];
      }
      return await LegalProceedingService.getCourtCredentials(userId);
    },
    enabled: !!userId,
  });
};
