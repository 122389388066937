import { Skeleton, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { CaseMessage } from "@/hooks/lexZap/types";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";

export const Sender = ({ message }: { message: CaseMessage }) => {
  const { data: companyUsers, isLoading: isLoadingCompanyUsers } = useCompanyTeam();
  const { data: company } = useLexZapCompany();

  const senderUser = useMemo(() => {
    if (!companyUsers) return null;

    return companyUsers.find((user) => user.userId === message.lawyerId);
  }, [companyUsers, message.lawyerId]);

  const senderIdentification = useMemo(() => {
    if (message.fromAssistant) {
      const assistantName = company?.assistantName || "Lia";
      return `${assistantName}, especialista virtual`;
    } else if (message.lawyerId) {
      return senderUser?.name || `usuário ${message.lawyerId}`;
    } else {
      return "usuário desconhecido";
    }
  }, [company?.assistantName, message.fromAssistant, message.lawyerId, senderUser]);

  if (!message.fromLawyer) {
    return null;
  }

  if (isLoadingCompanyUsers) {
    return (
      <Skeleton
        variant="text"
        width={200}
        sx={{
          mb: 1,
        }}
      />
    );
  }

  return (
    <Typography
      variant="body2"
      sx={{
        color: "text.secondary",
        width: "fit-content",
      }}
      mb={1}
    >
      Enviado por {senderIdentification}
    </Typography>
  );
};
