import { decodeJwt } from "@/utils/decodeJwt";
import * as logger from "@/core/logger";

// All times in minutes
const SESSION_EXPIRATION_TIME = 10;
const SESSION_TIME = 60;
const SESSION_REFRESH_TIME_IN_MS = (SESSION_TIME - SESSION_EXPIRATION_TIME) * 60 * 1000;

export const isTokenExpired = (token: string) => {
  try {
    const decodedToken = decodeJwt(token);
    if (!decodedToken || !decodedToken.exp) {
      return true;
    }

    const currentTime = Date.now();

    return decodedToken.exp < currentTime - SESSION_REFRESH_TIME_IN_MS;
  } catch (error) {
    if (error instanceof Error) {
      logger.error(`Error decoding token: ${error.message}`, error);
    }
    return true;
  }
};
