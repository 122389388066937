import { LegalProceedingService } from "@/services/legalProceeding";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { courtCredentialsQueryKey } from "../useCourtCredentials";
import { CourtCredentialPayload, CourtCredentialType } from "@/services/legalProceeding/types";

export const useUpdateCourtCredential = () => {
  const api = LegalProceedingService;
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["updateCourtCredential"],
    mutationFn: async ({
      userId,
      credentialId,
      payload,
    }: {
      userId: string;
      credentialId: string;
      payload: CourtCredentialPayload;
    }) => await api.updateCourtCredentials(userId, credentialId, payload),
    onMutate: async ({ credentialId, payload }) => {
      await queryClient.cancelQueries({ queryKey: courtCredentialsQueryKey });
      const previousCredentials = queryClient.getQueryData(courtCredentialsQueryKey);

      queryClient.setQueryData(courtCredentialsQueryKey, (old: CourtCredentialType[] | undefined) => {
        if (!old) return [];
        return old.map((credential) =>
          credential.id === credentialId
            ? {
                ...credential,
                ...payload,
              }
            : credential
        );
      });

      return { previousCredentials };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(courtCredentialsQueryKey, context?.previousCredentials);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: courtCredentialsQueryKey });
    },
  });
};
