import React from "react";
import { Box, Typography } from "@mui/material";
import { Case, TicketStatus } from "@/hooks/lexZap/types";
import { DateTime } from "luxon";
import { Button } from "@/components/Button";
import { getCasePath } from "@/routes/routePaths";
import { useNavigate } from "react-router-dom";
import { useLexZapTicket } from "@/hooks/lexZap/useLexZapTicket";
interface CaseCardProps {
  caseData: Case;
  openCloseTicketModal: (ticketId: string, applicantPhoneNumber: string) => void;
}

export const CaseCard = ({ caseData, openCloseTicketModal }: CaseCardProps) => {
  const { ticket } = useLexZapTicket({ ticketId: caseData.ticketId });
  const navigate = useNavigate();

  const gotToCase = () => {
    navigate(getCasePath({ caseId: caseData.id }));
  };

  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "2px solid",
        borderColor: "primary.light",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        backgroundColor: "orange.light.5",
      }}
    >
      <Box>
        <Typography variant="subtitle1">{caseData.name}</Typography>
      </Box>

      {ticket?.status === TicketStatus.ACTIVE && (
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              color: "grey.800",
            }}
          >
            Status
          </Typography>
          <Typography variant="body1">Em atendimento</Typography>
        </Box>
      )}
      {/* <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "grey.800",
          }}
        >
          Responsável
        </Typography>
        <Typography variant="body1">-</Typography>
      </Box> */}
      {/* <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "grey.800",
          }}
        >
          Réu
        </Typography>
        <Typography variant="body1">{caseData.applicantName}</Typography>
      </Box> */}
      {/* <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "grey.800",
          }}
        >
          Juízo
        </Typography>
        <Typography variant="body1">-</Typography>
      </Box> */}

      {/* <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "grey.800",
          }}
        >
          Código CNJ
        </Typography>
        <Typography
          variant="body1"
          sx={
            {
              color: "primary.main",
              textDecoration: "underline",
            }
          }
        >
          -
        </Typography>
      </Box> */}

      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "grey.800",
          }}
        >
          Criado em
        </Typography>
        <Typography variant="body1">
          {DateTime.fromJSDate(new Date(caseData.createdAt)).toFormat("dd/MM/yyyy")}
        </Typography>
      </Box>

      {/* <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "grey.800",
          }}
        >
          Última movimentação
        </Typography>
        <Typography variant="body1">-</Typography>
      </Box> */}

      {ticket?.status === TicketStatus.ACTIVE && (
        <Button
          sx={{
            borderRadius: "4px",
            fontWeight: 500,
            backgroundColor: "orange.light.20",
            "&:hover": {
              backgroundColor: "orange.light.40",
            },
          }}
          variant="contained"
          color="primary"
          onClick={() => openCloseTicketModal(ticket.id, ticket.applicantPhoneNumber)}
        >
          Encerrar atendimento
        </Button>
      )}

      <Button
        sx={{
          borderRadius: "4px",
          fontWeight: 600,
        }}
        variant="contained"
        color="primary"
        onClick={gotToCase}
      >
        Acessar caso
      </Button>
    </Box>
  );
};
