import { Box, Skeleton, SxProps, Typography } from "@mui/material";
import { GetMoreCredits, NextRecharge, DaysToEndTrial } from "@/components/ConfigMenu/Credits";
import { CreditsError } from "@/components/CreditsError";
import { PlanChip } from "@/components/PlanChip";
import { PlanChipSkeleton } from "@/components/PlanChip/PlanChipSkeleton";
import React, { useEffect } from "react";
import { useCredits } from "@/hooks/credits/useCredits";
import { useOpenBilling } from "@/hooks/useOpenBilling";
import { PlanType } from "@/hooks/credits/types";
import { notShowCredits } from "@/utils/plans";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

interface CreditsCardProps {
  sx?: SxProps;
}

export const CreditsCard = ({ sx }: CreditsCardProps) => {
  const { data: credits, error, refetch: refetchCredits, isLoading: isLoadingCredits } = useCredits();
  const openBilling = useOpenBilling();
  const flags = useFeatureFlags();

  const planType = credits?.companyPlan?.plan;
  const availableCredit = credits?.availableCredit;
  const nonExpirableCredit = credits?.nonExpirableCredit;
  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  const showCredits = !notShowCredits(credits, flags.planScreenV2Enabled);

  useEffect(() => {
    refetchCredits();
  }, [refetchCredits]);

  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        color: "common.black",
        p: "16px",
        borderColor: "common.lightShade",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "8px",
        ...sx,
      }}
    >
      {error ? (
        <CreditsError />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "flex-start",
            }}
          >
            <Typography variant="body2" textTransform="uppercase" fontWeight="700">
              Seu extrato de uso
            </Typography>

            {showCredits && (
              <>
                {!isLoadingCredits ? (
                  <Typography
                    variant="preTitle"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    Créditos disponíveis do plano:
                    <Typography
                      variant="body"
                      sx={{
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      {availableCredit?.toLocaleString("pt-BR") || "--"}
                    </Typography>
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    width="90px"
                    sx={{
                      display: "inline-block",
                      bgcolor: "common.lightShade",
                    }}
                  />
                )}

                {!isLoadingCredits ? (
                  <Typography
                    variant="preTitle"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    Créditos não expiráveis:
                    <Typography
                      variant="body"
                      sx={{
                        display: "inline-block",
                      }}
                    >
                      {nonExpirableCredit?.toLocaleString("pt-BR") || "--"}
                    </Typography>
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    width="90px"
                    sx={{
                      display: "inline-block",
                      bgcolor: "common.lightShade",
                    }}
                  />
                )}
                {isTrial ? <DaysToEndTrial /> : <NextRecharge />}
              </>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {!isLoadingCredits ? (
              <PlanChip planType={planType!} nextBillingDate={credits?.companyPlan?.nextBillingDate} />
            ) : (
              <PlanChipSkeleton />
            )}

            {planType && <GetMoreCredits onOpenBilling={openBilling} />}
          </Box>
        </Box>
      )}
    </Box>
  );
};
