import React, { useState } from "react";
import { Button, TextField, Autocomplete, Typography, Box } from "@mui/material";
import { Modal } from "@/components/Modal";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { Case } from "@/hooks/lexZap/types";

type SelectCaseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (selectedCase: Case | null) => void;
  onCreateNewCase: (openCreateCaseModal: boolean) => void;
};

export const SelectCaseModal = ({ isOpen, onClose, onConfirm, onCreateNewCase }: SelectCaseModalProps) => {
  const { data: cases } = useLexZapCases();
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  return (
    <Modal open={isOpen} onClose={onClose} maxWidth={600} closeButton={false}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, px: 2 }}>
        <Typography variant="h5">Vincule um caso para uma melhor performance da tarefa</Typography>
        <Typography variant="body1">
          Ao utilizar um caso como base de uma tarefa, além de um preenchimento mais rápido dos campos, todo o contexto
          do caso é utilizado garantindo um melhor resultado.
        </Typography>

        {cases && cases.length > 0 && (
          <Autocomplete
            id="select-case-modal"
            value={selectedCase}
            onChange={(_, newValue) => setSelectedCase(newValue)}
            options={cases.filter((c) => c.status === "ACTIVE")}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                label="Selecionar caso existente"
                placeholder="Selecione o caso desejado"
              />
            )}
            fullWidth
          />
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
          <Button onClick={() => onCreateNewCase(true)} variant="outlined">
            Criar novo caso
          </Button>
          <Button onClick={() => onConfirm(selectedCase)} variant="contained" disabled={!selectedCase}>
            Utilizar caso
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
