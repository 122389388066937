import { NotificationService } from "@/services/notification";
import { useQuery } from "@tanstack/react-query";

export const getNotificationsQueryKey = () => ["notifications"] as const;

export const useNotificationsQuery = () => {
  return useQuery({
    queryKey: getNotificationsQueryKey(),
    queryFn: () => NotificationService.getNotifications(),
  });
};
