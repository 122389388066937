import { PageLayout } from "@/components/PageLayout";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChatView } from "./components/ChatView";
import { Box, CircularProgress, Collapse, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { useImpersonation } from "@/hooks/useImpersonation";
import { useLexZapChats } from "@/hooks/lexZap/useLexZapChats";
import { ContactsModal } from "@/components/Applicants/ContactsModal";

export const WhatsappAssistantPage = () => {
  const { data: chatsData, isLoading: isLoadingChats, isFetching: isFetchingChats } = useLexZapChats();
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const allChats = useMemo(() => {
    if (!chatsData) return [];

    return chatsData.sort((a, b) => (b.lastMessage?.timestamp || 0) - (a.lastMessage?.timestamp || 0));
  }, [chatsData]);

  const [isOpenCreateContacts, setIsOpenCreateContacts] = React.useState(false);
  const { impersonatedUser } = useImpersonation();

  const handleOpenCreateContacts = () => {
    setIsOpenCreateContacts(true);
  };

  const handleCloseCreateContacts = () => {
    setIsOpenCreateContacts(false);
  };

  const commonHeaderRef = useRef<HTMLElement>();

  const handleCollapseHeader = useCallback((e: WheelEvent) => {
    if (e.deltaY > 0) {
      handleCloseHeader();
    } else if (e.deltaY < 0) {
      handleShowHeader();
    }
  }, []);

  const setupCollapseHeader = useCallback(
    (ref: React.MutableRefObject<HTMLElement | undefined>) => {
      if (!ref.current) return;

      const refElement = ref?.current;

      refElement.addEventListener("wheel", handleCollapseHeader);
      return () => {
        refElement.removeEventListener("wheel", handleCollapseHeader);
      };
    },
    [handleCollapseHeader]
  );

  useEffect(() => {
    setupCollapseHeader(commonHeaderRef);
  }, [commonHeaderRef, setupCollapseHeader]);

  const handleShowHeader = () => {
    setIsHeaderVisible(true);
  };

  const handleCloseHeader = () => {
    setIsHeaderVisible(false);
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
        overflow: "visible",
      }}
      headerProps={{
        extraContent: impersonatedUser ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              paddingX: 4,
              paddingY: 2,
              backgroundColor: "info.light",
            }}
          >
            <Box>
              <Typography variant="pageTitle">{impersonatedUser.companyName || "Outra company"}</Typography>
              <Typography variant="body" color="text.primary" sx={{ fontSize: "16px" }}>
                ID: {impersonatedUser.companyId}
                {!!impersonatedUser.companyEmail && (
                  <>
                    <br />
                    Email: {impersonatedUser.companyEmail}
                  </>
                )}
                {!!impersonatedUser.whatsappNumber && (
                  <>
                    <br />
                    Whatsapp: {impersonatedUser.whatsappNumber}
                  </>
                )}
              </Typography>
            </Box>

            {isFetchingChats && (
              <CircularProgress size={20} sx={{ marginLeft: 1, color: "text.primary" }} thickness={5} />
            )}
          </Box>
        ) : (
          <Collapse
            in={isHeaderVisible}
            easing={{
              enter: "linear",
              exit: "linear",
            }}
            orientation="vertical"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: 4,
                gap: 1,
                paddingY: 2,
                backgroundColor: "common.dorian",
              }}
              ref={commonHeaderRef}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="pageTitle">Atendimento</Typography>
                  <Typography variant="body" color="#4A4A68" sx={{ fontSize: "16px" }}>
                    Aqui estão centralizados os atendimentos e casos feitos e gerenciados pela e na Lexter
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Button variant="outlined" onClick={handleOpenCreateContacts}>
                    <Typography variant="bold" color="orange.dark.85">
                      Novo contato
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "end",
                }}
              ></Box>
            </Box>
          </Collapse>
        ),
      }}
    >
      <ChatView chats={allChats} isLoadingChats={isLoadingChats} setupCollapseHeader={setupCollapseHeader} />
      {isOpenCreateContacts && <ContactsModal isOpen={isOpenCreateContacts} onClose={handleCloseCreateContacts} />}
    </PageLayout>
  );
};
