import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Pause as PauseIcon, PlayArrowRounded as PlayIcon } from "@mui/icons-material";
import { useWavesurfer } from "@wavesurfer/react";
import { mergeColors } from "@/utils/mergeColors";

import { MessageProps } from "../../types";
import { secondsToFormattedTime } from "../../secondsToFormattedTime";
import { MessageCard } from "../MessageCard";
import { AudioTranscription } from "@/components/AudioTranscription";

export const AudioMessage = ({ message }: MessageProps) => {
  const audioContainerRef = useRef(null);
  const { palette } = useTheme();
  const { wavesurfer, isPlaying, isReady } = useWavesurfer({
    container: audioContainerRef,
    height: "auto",
    waveColor: mergeColors(palette.success.light, palette.common.white, message.fromLawyer ? 0.7 : 0.8),
    progressColor: palette.success.light,
    url: message.media?.url,
    barWidth: 3,
    barGap: 3,
    barRadius: 2,
  });
  const [duration, setDuration] = useState(0);
  const formattedDuration = useMemo(() => secondsToFormattedTime(duration), [duration]);
  const [currentTime, setCurrentTime] = useState(0);
  const formattedCurrentTime = useMemo(() => secondsToFormattedTime(currentTime), [currentTime]);

  const [transcriptionOpen, setTranscriptionOpen] = useState<string | null>(null);

  useEffect(() => {
    if (!isReady || !wavesurfer) return;

    setDuration(wavesurfer.getDuration());

    const updateCurrentTime = (newTime: number) => {
      setCurrentTime(newTime);
    };

    wavesurfer.on("timeupdate", updateCurrentTime);

    return () => {
      wavesurfer.un("timeupdate", updateCurrentTime);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        alignItems: message.fromLawyer ? "end" : "start",
      }}
    >
      <MessageCard message={message}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              padding: 1,
              borderRadius: "8px",
              backgroundColor: "#51BC51",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => wavesurfer?.playPause()}
          >
            {isPlaying ? (
              <PauseIcon fontSize="medium" sx={{ color: "common.white" }} />
            ) : (
              <PlayIcon fontSize="medium" sx={{ color: "common.white" }} />
            )}
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "min-content minmax(0, 1fr)",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="body">{isPlaying ? formattedCurrentTime : formattedDuration}</Typography>
            <div
              style={{
                height: "32px",
                width: "360px",
                maxWidth: "100%",
              }}
              ref={audioContainerRef}
            />
          </Box>
        </Box>
      </MessageCard>
      <Box>
        {message?.transcription ? (
          <>
            <Typography
              variant="bold"
              sx={{
                color: "orange.main",
                cursor: "pointer",
              }}
              onClick={() => {
                setTranscriptionOpen(message.id);
              }}
            >
              Acessar transcrição
            </Typography>
            <AudioTranscription
              transcription={message.transcription}
              isOpen={transcriptionOpen === message.id}
              onClose={() => {
                setTranscriptionOpen(null);
              }}
            />
          </>
        ) : message.fromLawyer ? null : (
          <Skeleton variant="text" width={200} />
        )}
      </Box>
    </Box>
  );
};
