import { Box, Divider, ListItemIcon, MenuItem, Popover, Typography } from "@mui/material";
import React from "react";
import { CaseStep, CaseTimeline as CaseTimelineType } from "@/hooks/lexZap/types";
import { MoreVert as MoreVertIcon, Unarchive } from "@mui/icons-material";
import { CaseTimeline } from "../CaseTimeline";
import ListeningToFeedback from "@/assets/svgs/listening-to-feedback.svg?react";
import {
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
  CheckRounded as CheckRoundedIcon,
  Archive,
  DeleteForever,
  Edit,
} from "@mui/icons-material";
import { getCasePath } from "@/routes/routePaths";
import { useNavigate } from "react-router-dom";

interface CardItemProps {
  id: string;
  name: string;
  applicantName: string;
  step: CaseStep;
  selectedCases: string[];
  onSelect: (caseId: string) => void;
  summary?: string;
  timeline: CaseTimelineType[];
  archived: boolean;
  setCurrentEditingCase: (caseId: string | null) => void;
  handleOpenEditCase: () => void;
  handleOpenDeleteCase: () => void;
  handleOpenArchiveCase: () => void;
  handleOpenUnarchiveCase: () => void;
}

const CARD_SUMMARY_HEIGHT = 144;

export const CardItem = ({
  id,
  name,
  applicantName,
  summary,
  selectedCases,
  onSelect,
  timeline,
  archived,
  setCurrentEditingCase,
  handleOpenEditCase,
  handleOpenDeleteCase,
  handleOpenArchiveCase,
  handleOpenUnarchiveCase,
}: CardItemProps) => {
  const [isCheckVisible, setIsCheckVisible] = React.useState(false);
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = React.useState<null | SVGSVGElement>(null);
  const navigate = useNavigate();

  const handleSelect = () => {
    onSelect(id);
  };

  const handleEdit = () => {
    closeOptionsMenu();
    handleOpenEditCase();
  };

  const handleDelete = () => {
    closeOptionsMenu();
    handleOpenDeleteCase();
  };

  const handleArchive = () => {
    closeOptionsMenu();
    handleOpenArchiveCase();
  };

  const handleUnarchive = () => {
    closeOptionsMenu();
    handleOpenUnarchiveCase();
  };

  const openOptionsMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setOptionsMenuAnchorEl(event.currentTarget);
    setCurrentEditingCase(id);
  };

  const closeOptionsMenu = () => {
    setOptionsMenuAnchorEl(null);
  };

  const isSelected = selectedCases.includes(id);

  const OptionsMenu = () => {
    return (
      <Popover
        open={Boolean(optionsMenuAnchorEl)}
        onClose={closeOptionsMenu}
        anchorEl={optionsMenuAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiModal-backdrop": {
            backgroundColor: "transparent",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon sx={{ minWidth: "32px" }}>
            <Edit fontSize="small" />
          </ListItemIcon>
          <Typography variant="body1">Editar</Typography>
        </MenuItem>
        <Divider sx={{ margin: "0 !important" }} />

        {archived ? (
          <MenuItem onClick={handleUnarchive}>
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <Unarchive fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">Desarquivar</Typography>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleArchive}>
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <Archive fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">Arquivar</Typography>
          </MenuItem>
        )}

        <Divider sx={{ margin: "0 !important" }} />
        <MenuItem onClick={handleDelete}>
          <ListItemIcon sx={{ minWidth: "32px" }}>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <Typography variant="body1">Deletar</Typography>
        </MenuItem>
      </Popover>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        backgroundColor: "common.white",
        minWidth: "368px",
        maxWidth: "420px",
        height: "432px",
        padding: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            width: "100%",
            flexGrow: 1,
          }}
          onMouseEnter={() => {
            setIsCheckVisible(true);
          }}
          onMouseLeave={() => {
            setIsCheckVisible(false);
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              width: "calc(100% - 86px)",
              flexGrow: 1,
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  display: `${isCheckVisible || selectedCases.length ? "block" : "none"}`,
                }}
              >
                <CheckBoxOutlineBlankOutlinedIcon sx={{ color: "#CDD7E1" }} onClick={handleSelect} />
                {isSelected && (
                  <CheckRoundedIcon
                    sx={{ position: "absolute", left: "0.15em", width: "0.70em" }}
                    onClick={handleSelect}
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "calc(100% - 36px)",
              }}
            >
              <Typography
                variant="body"
                color="#09132C"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  width: "100%",
                }}
              >
                {name}
              </Typography>
              <Typography
                variant="body"
                color="orange.light.90"
                sx={{
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {applicantName}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              variant="body"
              color="orange.light.90"
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(getCasePath({ caseId: id }));
              }}
            >
              Acessar
            </Typography>
            <MoreVertIcon sx={{ color: "grey.400", cursor: "pointer" }} onClick={openOptionsMenu} />
            <OptionsMenu />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            height: `${CARD_SUMMARY_HEIGHT}px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 1,
          }}
        >
          {summary ? (
            <Typography
              sx={{
                width: "100%",
                height: `${CARD_SUMMARY_HEIGHT}px`,
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 6,
                WebkitBoxOrient: "vertical",
              }}
              variant="body1"
            >
              {summary}
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                height: "100%",
              }}
            >
              <ListeningToFeedback />
              <Typography
                variant="bold"
                sx={{
                  color: "common.shade",
                }}
              >
                Resumo ainda não gerado
              </Typography>
            </Box>
          )}
        </Box>
        <CaseTimeline timeline={timeline} />
      </Box>
    </Box>
  );
};
