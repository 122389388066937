import React, { forwardRef } from "react";
import { TextField, TextFieldProps } from "@mui/material";

export const InputField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  return (
    <TextField
      {...props}
      ref={ref}
      sx={{
        ".MuiFormLabel-root, .MuiInputLabel-root": {
          fontSize: "14px",
          transformOrigin: "center left",
        },
        ...props.sx,
      }}
    />
  );
});

InputField.displayName = "InputField";
