import React from "react";
import { createSearchParams, useNavigate } from "react-router-dom";

import { Stack, styled, Typography } from "@mui/material";

import { useAuthContext } from "@/contexts/AuthContext";
import { PlanType } from "@/hooks/credits/types";
import { useApi } from "@/hooks/useApi";
import { usePlanChips } from "@/hooks/usePlanChips";

import { openUrl } from "@/utils/openUrl";

import { ROUTE_PATHS } from "@/routes/routePaths";
import { PageLayout } from "@/components/PageLayout";

import { TestPeriodAlert } from "./TestPeriodAlert";
import { PlanCard } from "./PlanCard";
import { PlanChips } from "./PlanChips";

export const Plan = () => {
  const { getPaymentUserPortal } = useApi();
  const navigate = useNavigate();

  const {
    planName,
    plan,
    isTestingPlan,
    nextBillingDate,
    activeCasesQuota,
    usersQuota,
    activeCasesCount,
    usersCount,
    handleOpenCasesScreen,
    handleOpenUsersScreen,
  } = usePlanChips();
  const { user } = useAuthContext();

  const isProfessionalPlan = plan === PlanType.PROFESSIONAL_WITH_WPP;

  function handleSubscribeEssentialPlan() {
    navigate({
      pathname: ROUTE_PATHS.INTERNAL_PAYMENT,
      search: createSearchParams({ plan: PlanType.UNLIMITED }).toString(),
    });
  }

  function handleSubscribeProfessionalPlan() {
    navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT);
  }

  async function handleManagePlan() {
    if (!user) return;

    const response = await getPaymentUserPortal({ companyId: user.companyId });
    openUrl(response.paymentUserPortal, false);
  }

  return (
    <PageLayout
      variant="tertiary"
      contentProps={{ fullWidth: true, disablePadding: true }}
      contentSx={{ height: "100%" }}
    >
      <InternalContainer>
        <Typography variant="h4">Plano e cobranças</Typography>
        <Content>
          <PlanChips
            planName={planName}
            isTestingPlan={isTestingPlan}
            nextBillingDate={nextBillingDate}
            activeCasesCount={activeCasesCount}
            activeCasesQuota={activeCasesQuota}
            handleOpenCasesScreen={handleOpenCasesScreen}
            usersCount={usersCount}
            usersQuota={usersQuota}
            handleOpenUsersScreen={handleOpenUsersScreen}
          />
          {isTestingPlan && <TestPeriodAlert />}
          <PlansCards
            isTestingPlan={isTestingPlan}
            isProfessionalPlan={isProfessionalPlan}
            onSubscribeEssentialPlan={handleSubscribeEssentialPlan}
            onSubscribeProfessionalPlan={handleSubscribeProfessionalPlan}
            onManagePlan={handleManagePlan}
          />
        </Content>
      </InternalContainer>
    </PageLayout>
  );
};

const InternalContainer = styled(Stack)(({ theme }) => ({
  height: "100%",
  width: "100%",
  padding: theme.spacing(5),
  gap: theme.spacing(2),
}));

const Content = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey[100]}`,
  padding: theme.spacing(3),
  gap: theme.spacing(2),
}));

const PlansCards = ({
  isTestingPlan,
  isProfessionalPlan,
  onSubscribeEssentialPlan,
  onSubscribeProfessionalPlan,
  onManagePlan,
}: {
  isTestingPlan: boolean;
  isProfessionalPlan: boolean;
  onSubscribeEssentialPlan: () => void;
  onSubscribeProfessionalPlan: () => void;
  onManagePlan: () => void;
}) => {
  const shouldManageEssentialPlan = !isTestingPlan && !isProfessionalPlan;
  const shouldManageProfessionalPlan = isProfessionalPlan;

  return (
    <PlansCardsContainer>
      <PlanCard
        title="Essencial"
        price="R$ 199"
        buttonText={shouldManageEssentialPlan ? "Gerenciar" : "Assinar"}
        buttonProps={{
          color: "secondary",
          disabled: shouldManageProfessionalPlan,
          onClick: shouldManageEssentialPlan ? onManagePlan : onSubscribeEssentialPlan,
        }}
        features={[
          {
            text: (
              <>
                <strong>Laura</strong>, assistente jurídica 24 horas por dia
              </>
            ),
          },
          {
            text: "Delegue as tarefas jurídicas repetitivas à Laura, ganhe tempo e acelere seu negócio.",
          },
        ]}
      />

      <PlanCard
        title="Profissional"
        buttonText={shouldManageProfessionalPlan ? "Gerenciar" : "Solicitar demonstração"}
        variant="highlighted"
        priceColor="primary.dark"
        buttonProps={{ onClick: shouldManageProfessionalPlan ? onManagePlan : onSubscribeProfessionalPlan }}
        features={[
          {
            text: "Lia (Assistente de Relacionamento) e Laura (Assistente Jurídica) 24 horas por dia",
            bold: true,
          },
          {
            text: "Uma solução completa para atender com excelência seus clientes e aumentar as vendas do seu escritório.",
          },
        ]}
      />
    </PlansCardsContainer>
  );
};

const PlansCardsContainer = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: "0 1px 8px 0 #0000001F",
  flexDirection: "row",
  flex: 1,
}));
