import { SkillGroup } from "@/core/skillGroups/types";
import { CreditPerSkill } from "@/core/skills/types";

export const OtherTasksActionsSkillGroup: SkillGroup = {
  id: "create_new_document",
  name: "Outras tarefas",
  subtitle: "Selecione a tarefa:",
  credits: CreditPerSkill.ADVANCED_SKILL,
  skillShortcuts: [
    { label: "Tirar dúvida jurídica", skillId: "legal_questions" },
    { label: "Buscar Jurisprudência", skillId: "search_precedent" },
    { label: "Elaborar roteiro de entrevista inicial", skillId: "create_interview_script" },
    { label: "Roteiro para audiência", skillId: "hearings_script" },
    { label: "Resumir documento processual", skillId: "summary_document" },
    // TODO: Fica pendente a skill Analisar processo que ainda vai ser implementada
    // { label: "Analisar processo", skillId: "intercurrent_motion" },
  ],
  tags: ["recommend", "litigation"],
};
