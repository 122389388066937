import React from "react";

import { Box, Typography } from "@mui/material";
import { Button } from "@/components/Button";

export const LandingPageCard = ({
  imageUrl,
  title,
  description,
  openFormModal,
}: {
  imageUrl: string;
  title: string;
  description: string;
  openFormModal: () => void;
}) => {
  return (
    <Box
      sx={{
        maxWidth: "568px",
        width: "100%",
        maxHeight: "353px",
        height: "100%",
        borderRadius: "16px",
        backgroundColor: "grey.50",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: 4,
        p: 4,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Box
          component="img"
          src={imageUrl}
          sx={{
            height: "276px",
            width: "204px",
            borderRadius: "16px",
            objectFit: "fill",
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "secondary.main",
              "&:hover": {
                backgroundColor: "orange.light.30",
              },
            }}
            onClick={openFormModal}
          >
            Contratar a Lia
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
