import { getNotificationsQueryKey } from "@/hooks/notifications/useNotificationsQuery";
import { NotificationService } from "@/services/notification";
import { Notification } from "@/services/notification/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";

export const useOpenNotificationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [...getNotificationsQueryKey(), "openNotification"],
    mutationFn: (notificationId: number) => NotificationService.setNotificationOpen(notificationId),
    onMutate: async (notificationId: number) => {
      queryClient.setQueryData(getNotificationsQueryKey(), (oldData: Notification[]) => {
        return oldData.map((notification) =>
          notification.id === notificationId
            ? { ...notification, visualizedAt: DateTime.now().toString() }
            : notification
        );
      });
    },
    onError: (_, notificationId) => {
      queryClient.setQueryData(getNotificationsQueryKey(), (oldData: Notification[]) => {
        return oldData.map((notification) =>
          notification.id === notificationId ? { ...notification, visualizedAt: undefined } : notification
        );
      });
    },
  });
};
