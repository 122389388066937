import { useAuthContext } from "@/contexts/AuthContext";
import { AuthService } from "@/services/auth";
import { LegalProceedingService } from "@/services/legalProceeding";
import {
  BadgeOutlined as BadgeOutlinedIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  EventBusy as EventBusyIcon,
} from "@mui/icons-material";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import React from "react";
import toast from "react-hot-toast";

export const DevToolsPage = () => {
  const { user } = useAuthContext();

  return (
    <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
      <Typography variant="h6">Ferramentas de Desenvolvimento</Typography>
      <List>
        <DevToolItem
          label="Copiar token"
          onClick={async () => {
            const { token } = await AuthService.getValidToken();
            if (token) {
              await navigator.clipboard.writeText(token);
              toast.success("Token copiado para a área de transferência");
            }
          }}
          icon={<BadgeOutlinedIcon />}
        />
        <DevToolItem
          label="Limpar processos legais"
          onClick={async () => {
            if (!user) return;
            await toast.promise(
              LegalProceedingService.clearLegalProceedings({
                userId: user.userId,
                companyId: user.companyId,
              }),
              {
                loading: "Limpando processos legais...",
                success: "Processos legais limpos",
                error: "Erro ao limpar processos legais",
              }
            );
          }}
          icon={<DeleteForeverOutlinedIcon />}
        />
        <DevToolItem
          label="Limpar rastreamentos da judit"
          onClick={async () => {
            await toast.promise(LegalProceedingService.clearJuditTrackings(), {
              loading: "Limpando rastreamentos da judit...",
              success: (data) => data.message,
              error: "Erro ao limpar rastreamentos da judit",
            });
          }}
          icon={<EventBusyIcon />}
        />
      </List>
    </Box>
  );
};

const DevToolItem = ({ label, onClick, icon }: { label: string; onClick: () => void; icon: React.ReactNode }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
};
