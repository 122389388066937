import { useCallback, useEffect, useState } from "react";
import { useInterval } from "@/hooks/useInterval";
import { getTemplateToUse } from "./utils/getTemplateToUse";
import { LexZapChat } from "../types";
import { getTemplateTextWithoutMessage } from "../useSendLexZapMessage/templates";

interface UseLexZapChatInfoProps {
  chat?: LexZapChat;
}
export const useLexZapChatInfo = ({ chat }: UseLexZapChatInfoProps) => {
  const [templateToUse, setTemplateToUse] = useState(() => getTemplateToUse({ chat }));

  const checkLastClientMessage = useCallback(() => {
    setTemplateToUse(() => getTemplateToUse({ chat }));
  }, [chat]);

  useInterval(checkLastClientMessage, 1000 * 60);

  useEffect(() => {
    checkLastClientMessage();
  }, [checkLastClientMessage]);

  return {
    shouldUseTemplate: !!templateToUse,
    templateToUse,
    templateTextWithoutMessage: templateToUse
      ? getTemplateTextWithoutMessage({
          templateKey: templateToUse,
          applicantName: chat?.applicantName || "",
        })
      : null,
  };
};
