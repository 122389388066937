import { PaginationPayload } from "@/core/api/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { LegalProceedingService } from "@/services/legalProceeding";

export const legalProceedingsQueryKey = ["legalProceedings"];

export const getLegalProceedingsQueryKey = (pagination: Pick<PaginationPayload, "pageSize">) => [
  ...legalProceedingsQueryKey,
  { pageSize: pagination.pageSize },
];

export const usePaginatedLegalProceedingsQuery = ({
  pagination,
}: {
  pagination: Pick<PaginationPayload, "pageSize">;
}) => {
  return useInfiniteQuery({
    queryKey: getLegalProceedingsQueryKey(pagination),
    queryFn: async ({ pageParam }) =>
      await LegalProceedingService.getLegalProceedings({
        pagination: {
          page: pageParam,
          pageSize: pagination.pageSize,
        },
      }),
    retry: false,
    initialPageParam: 1,
    refetchInterval: ({ state }) => {
      if (state.data?.pages.some((page) => page?.status === "IMPORTING")) {
        return 60_000;
      }
      return false;
    },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage) return undefined;
      if (allPages.flatMap((page) => (page ? page.data : [])).length >= lastPage.totalResults) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
};
