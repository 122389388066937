import { useMemo } from "react";
import { CaseMessage, CaseMessageWithMedia } from "../types";
import { useLexZapMessages } from "../useLexZapMessages";
interface UseLexZapMessagesProps {
  applicantPhoneNumber?: string;
  disabled?: boolean;
}
export const useLexZapMessagesMedia = ({ applicantPhoneNumber, disabled }: UseLexZapMessagesProps) => {
  const {
    data: messages,
    isLoading,
    isError,
  } = useLexZapMessages({
    applicantPhoneNumber,
    disabled,
  });

  function isCaseWithMedia(input: CaseMessageWithMedia | CaseMessage): input is CaseMessageWithMedia {
    return input?.media !== undefined;
  }

  const casesWithMedia = useMemo(() => messages?.filter(isCaseWithMedia), [messages]);

  return {
    data: casesWithMedia,
    isLoading,
    isError,
  };
};
