import { LegalProceedingService } from "@/services/legalProceeding";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { oabsQueryKey } from "../useOabs";
import { OABType } from "@/services/legalProceeding/types";
import { Paginated } from "@/core/api/types";

export const useDeleteOab = () => {
  const api = LegalProceedingService;
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["deleteOab"],
    mutationFn: async ({ userId, oabId }: { userId: string; oabId: string }) => await api.deleteOab(userId, oabId),
    onMutate: async ({ oabId }) => {
      await queryClient.cancelQueries({ queryKey: oabsQueryKey });
      const previousOabs = queryClient.getQueryData(oabsQueryKey);

      queryClient.setQueryData(oabsQueryKey, (old: Paginated<OABType> | undefined) => {
        if (!old) return { items: [] };
        return {
          ...old,
          data: old.data.filter((oab) => oab.id !== oabId),
        };
      });

      return { previousOabs };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(oabsQueryKey, context?.previousOabs);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: oabsQueryKey });
    },
  });
};
