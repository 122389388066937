import React, { ReactNode } from "react";
import { Box, ButtonBase, Typography, styled } from "@mui/material";

interface ChipProps {
  title: string;
  children: ReactNode;
  onClick?: () => void;
}

export const Chip = ({ title, children, onClick }: ChipProps) => {
  let Container: typeof ChipContainer | typeof ChipButtonBaseContainer = ChipContainer;

  if (onClick) {
    Container = ChipButtonBaseContainer;
  }

  return (
    <Container onClick={onClick}>
      <ChipTitle variant="subtitle2">{title}</ChipTitle>
      {children}
    </Container>
  );
};

const ChipContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.dorian,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  width: "100%",
  borderRadius: theme.shape.borderRadius,
}));

const ChipButtonBaseContainer = styled(ButtonBase)(({ theme }) => ({
  backgroundColor: theme.palette.common.dorian,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

const ChipTitle = styled(Typography)({
  fontWeight: 700,
});
