import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ModalRight } from "@/components/Modal/ModalRight";
import React from "react";
import { logger } from "@/core/logger";
import { ChatFilterOption, ChatFilters } from "@/hooks/lexZap/types";
import { CloseOutlined } from "@mui/icons-material";
import { activityAreaOptions } from "@/hooks/company/types";

export function FilterModal({
  isOpen,
  onClose,
  chatFilters,
  selectedFilters,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  chatFilters: ChatFilters;
  selectedFilters: ChatFilters;
  onConfirm: (filters: ChatFilters) => void;
}) {
  const [newFilterOptions, setNewFilterOptions] = React.useState<ChatFilters>(selectedFilters);
  const handleFilter = async () => {
    try {
      onConfirm(newFilterOptions);
      onClose();
    } catch (err) {
      logger.error("Error filtering chats", err);
    }
  };

  const handleChangeFilter = (prop: ChatFilterOption) => {
    return () => {
      setNewFilterOptions((prevState) => ({
        ...prevState,
        options: prevState.options.find((option) => option.value === prop.value)
          ? prevState.options.filter((option) => option.value !== prop.value)
          : [...prevState.options, prop],
      }));
    };
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "minmax(0, 1fr)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        bgcolor: "background.paper",
        boxShadow: 24,
        zIndex: "modal",
      }}
    >
      <ModalRight
        title={"Filtrar lista"}
        size={"SMALL"}
        onClose={onClose}
        footer={
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              onClick={handleFilter}
              size="small"
              sx={{
                width: "100%",
              }}
            >
              Filtrar
            </Button>
          </Box>
        }
        open={isOpen}
        sx={{
          "& .MuiSvgIcon-fontSizeMedium": {
            width: "20px",
            height: "20px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                }}
                variant="body1"
              >
                Mostrar conversas
              </Typography>
              <FormGroup
                sx={{
                  paddingLeft: "12px",
                }}
              >
                {chatFilters.options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    control={
                      <Checkbox
                        onChange={handleChangeFilter(option)}
                        checked={newFilterOptions.options.find((item) => item.value === option.value) !== undefined}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </FormGroup>
            </Box>

            <>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                  variant="body1"
                >
                  Conversas das áreas:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                  variant="body2"
                >
                  Exibir apenas conversas que foram marcadas como pertencentes ás areas:
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                {newFilterOptions.activityAreas?.map((area) => (
                  <Chip
                    key={area.value}
                    label={area.label}
                    onDelete={() => {
                      setNewFilterOptions((prevState) => ({
                        ...prevState,
                        activityAreas: prevState.activityAreas?.filter((item) => item.value !== area.value),
                      }));
                    }}
                    sx={{
                      backgroundColor: "primary.main",
                      color: "common.white",
                    }}
                    deleteIcon={<CloseOutlined />}
                  />
                ))}
              </Box>

              <Box>
                <Autocomplete
                  disablePortal
                  options={activityAreaOptions}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <TextField {...params} label="Comece a digitar para buscar" />}
                  multiple
                  value={newFilterOptions.activityAreas}
                  noOptionsText="Nenhuma opção encontrada"
                  sx={{
                    "& .MuiChip-root": {
                      visibility: "hidden",
                      display: "none",
                      color: "red",
                    },
                    "& .MuiChip-label": {
                      display: "none",
                      visibility: "hidden",
                      color: "red",
                    },
                  }}
                  onChange={(_, selectedOptions) => {
                    setNewFilterOptions((prevState) => ({
                      ...prevState,
                      activityAreas: selectedOptions,
                    }));
                  }}
                />
              </Box>
            </>
          </Box>
        </Box>
      </ModalRight>
    </Box>
  );
}
