import { TemplateKey, TemplateMessageParams, TemplateParams } from "@/services/lexZap/types/templateMessage.types";

const templateData: Record<TemplateKey, { text: string }> = {
  [TemplateKey.ReactivateChat]: {
    text: "Não nos falamos faz algum tempo. Segue continuação da nossa conversa:\n\n{{1}}\n",
  },
  [TemplateKey.InitChat]: {
    text: "Olá, {{1}}! Tudo bem? Estou entrando em contato porque {{2}}",
  },
};

type TemplateParameterOrderMap = {
  [K in TemplateKey]: (keyof TemplateParams[K])[];
};

export const templateParameterOrder: TemplateParameterOrderMap = {
  [TemplateKey.ReactivateChat]: ["message"],
  [TemplateKey.InitChat]: ["applicantName", "message"],
};

export const getTemplateText = ({ templateMessage }: { templateMessage: TemplateMessageParams }): string => {
  const orderedParameters = templateParameterOrder[templateMessage.templateKey].map(
    (key) => templateMessage.templateParams[key as keyof typeof templateMessage.templateParams]
  );

  const template = templateData[templateMessage.templateKey];
  return orderedParameters.reduce((text, param, index) => text.replace(`{{${index + 1}}}`, param), template.text);
};

export const getTemplateTextWithoutMessage = ({
  templateKey,
  applicantName,
}: {
  templateKey: TemplateKey;
  applicantName: string;
}) => {
  switch (templateKey) {
    case TemplateKey.ReactivateChat: {
      return getTemplateText({
        templateMessage: {
          type: "template",
          templateKey,
          templateParams: {
            message: "",
          },
        },
      }).trim();
    }
    case TemplateKey.InitChat: {
      return getTemplateText({
        templateMessage: {
          type: "template",
          templateKey,
          templateParams: {
            applicantName,
            message: "",
          },
        },
      }).trim();
    }
  }
};

export const buildTemplateMessage = ({
  templateKey,
  message,
  applicantName,
}: {
  templateKey: TemplateKey;
  message: string;
  applicantName: string;
}): TemplateMessageParams => {
  switch (templateKey) {
    case TemplateKey.ReactivateChat: {
      return {
        type: "template",
        templateKey,
        templateParams: {
          message,
        },
      };
    }
    case TemplateKey.InitChat: {
      return {
        type: "template",
        templateKey,
        templateParams: {
          message,
          applicantName,
        },
      };
    }
  }
};
