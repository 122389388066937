import React, { ChangeEvent, useMemo } from "react";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Loading } from "@/components/Loading";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { WebToast } from "@/components/core/Toast";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { useCreateCompanyMember } from "@/hooks/company/useCreateCompanyMember";
import { CloseOutlined } from "@mui/icons-material";
import { Email } from "@/core/Email";
import { ActivityArea, activityAreaOptions, Department } from "@/hooks/company/types";

type Form = {
  email: string;
  isAdmin?: boolean;
  department?: Department;
  activityAreas: { value: ActivityArea; label: string }[];
};

export const NewMemberModal = ({ onClose }: { onClose: () => void }) => {
  const [form, setForm] = React.useState<Form>({
    email: "",
    activityAreas: [],
    isAdmin: false,
  });
  const { mutateAsync: createCompanyMember, isPending: isLoading } = useCreateCompanyMember();
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(true);

  const handleCreateCompanyMember = async () => {
    if (!form.email || !form.department) {
      return;
    }

    await createCompanyMember({
      email: form.email,
      department: form.department,
      isAdmin: form.isAdmin,
      activityAreas: form.department === Department.LEGAL ? form.activityAreas?.map((area) => area.value) : undefined,
    });

    onClose();
    return WebToast.success("Convite enviado com sucesso");
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (prop === "isAdmin") {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.checked,
        }));
      } else {
        setForm((prevState) => ({
          ...prevState,
          [prop]: e.target.value,
        }));
      }
    };
  };

  const saveButtonDisabled = useMemo(() => {
    return (
      form.email === "" ||
      !isValidEmail ||
      !form.department ||
      (form.department === Department.LEGAL && form.activityAreas?.length === 0)
    );
  }, [form.activityAreas?.length, form.department, form.email, isValidEmail]);

  return (
    <ModalRight
      title={"Novo colaborador"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        !isLoading && (
          <ModalFooter
            confirmLabel={"Convidar"}
            onConfirm={handleCreateCompanyMember}
            cancelLabel="Cancelar"
            onCancel={onClose}
            saveButtonDisabled={saveButtonDisabled}
          />
        )
      }
      open={true}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            gap: "16px",
            marginBottom: "8px",
          }}
        >
          <Typography variant="body2">
            Todos os colaboradores podem criar e visualizar as peças criadas na sua plataforma Lexter
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
              }}
            >
              Pra onde enviamos o convite?
            </Typography>
            <TextField
              placeholder="E-mail"
              onChange={(e) => {
                handleChangeForm("email")(e as ChangeEvent<HTMLInputElement>);
                setIsValidEmail(e.target.value === "" ? true : Email.fromString(e.target.value).isSuccess);
              }}
              value={form.email}
              label="E-mail"
              error={!isValidEmail}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginBop: "8px",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Administrador
              </Typography>
              <Typography variant="body2">
                Administrador é um colaborador que pode administar outros usuários
              </Typography>
            </Box>
            <FormControl>
              <FormControlLabel
                control={<Checkbox checked={form?.isAdmin || false} onChange={(e) => handleChangeForm("isAdmin")(e)} />}
                label="Administrador"
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Setor
              </Typography>
              <Typography variant="body2">
                Os setores influenciam especialmente no redirecionamento de um atendimento para um colaborador
              </Typography>
            </Box>
            <FormControl>
              <RadioGroup onChange={handleChangeForm("department")}>
                <FormControlLabel value={Department.ADMINISTRATIVE} control={<Radio />} label="Administrativo" />
                <FormControlLabel value={Department.FINANCIAL} control={<Radio />} label="Financeiro" />
                <FormControlLabel value={Department.LEGAL} control={<Radio />} label="Jurídico" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {form.department === Department.LEGAL && (
              <>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                    }}
                  >
                    Área de atuação
                  </Typography>
                  <Typography variant="body2">
                    A área de atuação impacta no redirecionamento ou não de um atendimento para você
                  </Typography>
                </Box>
                <Box>
                  <Autocomplete
                    disablePortal
                    options={activityAreaOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <TextField {...params} label="Comece a digitar para buscar" />}
                    multiple
                    value={form.activityAreas}
                    noOptionsText="Nenhuma opção encontrada"
                    sx={{
                      "& .MuiChip-root": {
                        visibility: "hidden",
                        display: "none",
                      },
                      "& .MuiChip-label": {
                        display: "none",
                        visibility: "hidden",
                      },
                    }}
                    onChange={(_, value) => {
                      setForm((prevState) => ({
                        ...prevState,
                        activityAreas: value,
                      }));
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexWrap: "wrap",
                  }}
                >
                  {form.activityAreas.map((areaOption) => (
                    <Chip
                      key={areaOption.value}
                      label={areaOption.label}
                      onDelete={() => {
                        setForm((prevState) => ({
                          ...prevState,
                          activityAreas: prevState.activityAreas.filter((item) => item.value !== areaOption.value),
                        }));
                      }}
                      sx={{
                        backgroundColor: "primary.main",
                        color: "common.white",
                      }}
                      deleteIcon={<CloseOutlined />}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Loading>
    </ModalRight>
  );
};
