import React, { useState } from "react";
import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { LegalProceedingType } from "@/services/legalProceeding/types";
import { tjNameToFullName } from "@/utils/tjNameToFullName";
import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@/components/Button";
import { Case } from "@/hooks/lexZap/types";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { ArrowOutward } from "@mui/icons-material";
import { logger } from "@/core/logger";

interface LegalProceedingColumnsProps {
  monitoringColumnEnabled?: boolean;
  cases: Case[];
  redirectTo: (path: string) => void;
  handleCreateCaseFromLegalProceeding: (cnj: string) => Promise<void>;
}

export const legalProceedingColumns = ({
  monitoringColumnEnabled,
  cases,
  redirectTo,
  handleCreateCaseFromLegalProceeding,
}: LegalProceedingColumnsProps): GridColDef<LegalProceedingType>[] => {
  let monitoringColumns: GridColDef<LegalProceedingType>[] = [];
  if (monitoringColumnEnabled) {
    monitoringColumns = [
      {
        field: "status",
        type: "custom",
        headerName: "Monitoramento",
        sortable: false,
        width: 130,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => (
          <Chip
            label={params.value === "ACTIVE" ? "Ativo" : "Inativo"}
            sx={{
              bgcolor: params.value === "ACTIVE" ? "primary.main" : null,
              color: params.value === "ACTIVE" ? "white" : "inherit",
            }}
          />
        ),
      },
      {
        field: "action",
        type: "custom",
        headerName: "",
        sortable: false,
        width: 130,
        align: "center",
        renderCell: (params) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isLoading, setIsLoading] = useState(false);

          const handleCreateCase = async () => {
            try {
              setIsLoading(true);
              await handleCreateCaseFromLegalProceeding(params.row.cnj);
            } catch (error) {
              logger.error(`Error creating case from legal proceeding ${params.row.cnj}`, error);
            } finally {
              setIsLoading(false);
            }
          };

          const caseData = cases.find((c) => c.cnjs?.includes(params.row.cnj));
          if (caseData) {
            return (
              <Button onClick={() => redirectTo(`${ROUTE_PATHS.CASES}/${caseData.id}`)} endIcon={ArrowOutward}>
                Ver caso
              </Button>
            );
          }

          return (
            <Button onClick={handleCreateCase} isLoading={isLoading}>
              Criar caso
            </Button>
          );
        },
      },
    ];
  }

  return [
    { field: "cnj", headerName: "Código CNJ", maxWidth: 200, sortable: false, flex: 1 },
    {
      field: "parties",
      headerName: "Cliente(s)",
      sortable: false,
      maxWidth: 400,
      flex: 1,
      renderCell: (params) => {
        const parties = params.value as LegalProceedingType["parties"];
        if (parties.length === 0) {
          return (
            <Typography component="div" variant="body3" color="text.disabled">
              Informação não encontrada
            </Typography>
          );
        }

        const firstParty = parties[0];
        return (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
            component="div"
            variant="body3"
            overflow="ellipsis"
          >
            {firstParty.name}
            <Tooltip
              sx={{
                cursor: "default",
              }}
              title={
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {parties.map((party) => (
                    <Typography
                      sx={{
                        fontSize: "11px",
                      }}
                      key={party.name}
                      variant="body2"
                      textAlign="center"
                    >
                      {party.name}
                    </Typography>
                  ))}
                </Box>
              }
            >
              <Typography component="span" variant="body3">
                {parties.length > 1 && `, +${parties.length - 1}`}
              </Typography>
            </Tooltip>
          </Typography>
        );
      },
    },
    { field: "name", headerName: "Processo", width: 200, sortable: false, flex: 1 },
    {
      field: "tribunalAcronym",
      headerName: "Orgão de origem",
      sortable: false,
      width: 250,
      valueGetter: (value) => {
        return tjNameToFullName[value];
      },
    },
    ...monitoringColumns,
  ];
};
