import { PageLayout } from "@/components/PageLayout";
import React from "react";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useLexZapCase } from "@/hooks/lexZap/useLexZapCase";
import { useDeleteCases } from "@/hooks/lexZap/useDeleteCases";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useArchiveCases } from "@/hooks/lexZap/useArchiveCases";
import { useUnarchiveCases } from "@/hooks/lexZap/useUnarchiveCases";
import { DeleteCasesModal } from "@/pages/Cases/components/CardsView/components/DeleteCasesModal";
import { ArchiveCasesModal } from "@/pages/Cases/components/CardsView/components/ArchiveCasesModal";
import { UnarchiveCasesModal } from "@/pages/Cases/components/CardsView/components/UnarchiveCasesModal";
import { useActivateCases } from "@/hooks/lexZap/useActivateCases";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { CaseDetails } from "./components/CaseDetails";
import { WebToastColoredWithTitle } from "@/components/core/Toast";

export const CasePage = () => {
  const { caseNextStepsEnabled } = useFeatureFlags();

  const { caseId } = useParams<{ caseId: string }>();
  const { caseData, isLoading: isLoadingCase } = useLexZapCase({ caseId });
  const { mutateAsync: deleteCases, isPending: isDeletingCase } = useDeleteCases();
  const { mutateAsync: archiveCases, isPending: isArchivingCase } = useArchiveCases();
  const { mutateAsync: unarchiveCases, isPending: isUnarchivingCase } = useUnarchiveCases();
  const { mutateAsync: activateCase, isPending: isActivatingCase } = useActivateCases();

  const [isOpenDeleteCase, setIsOpenDeleteCase] = React.useState(false);
  const [isOpenArchiveCase, setIsOpenArchiveCase] = React.useState(false);
  const [isOpenUnarchiveCase, setIsOpenUnarchiveCase] = React.useState(false);

  const navigate = useNavigate();

  const handleOpenDeleteCase = () => {
    if (!caseId) return;
    setIsOpenDeleteCase(true);
  };

  const handleCloseDeleteCase = () => {
    setIsOpenDeleteCase(false);
  };

  const handleDeleteCase = async () => {
    if (!caseId) {
      handleCloseDeleteCase();
      return;
    }
    try {
      await deleteCases({ casesIds: [caseId] });
      WebToastColoredWithTitle.success("Caso deletado com sucesso");
    } catch (e) {
      WebToastColoredWithTitle.error(
        "Erro ao deletar caso",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    } finally {
      handleCloseDeleteCase();
      navigate(ROUTE_PATHS.CASES);
    }
  };

  const handleOpenArchiveCase = () => {
    if (!caseId) return;
    setIsOpenArchiveCase(true);
  };

  const handleCloseArchiveCase = () => {
    setIsOpenArchiveCase(false);
  };

  const handleArchiveCase = async () => {
    if (!caseId) {
      handleCloseArchiveCase();
      return;
    }
    try {
      await archiveCases({ casesIds: [caseId] });
      WebToastColoredWithTitle.success("Caso arquivado com sucesso");
    } catch (e) {
      WebToastColoredWithTitle.error(
        "Erro ao arquivar caso",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    } finally {
      handleCloseArchiveCase();
    }
  };

  const handleOpenUnarchiveCase = () => {
    if (!caseId) return;

    setIsOpenUnarchiveCase(true);
  };

  const handleCloseUnarchiveCase = () => {
    setIsOpenUnarchiveCase(false);
  };

  const handleUnarchiveCase = async () => {
    if (!caseId) {
      handleCloseUnarchiveCase();
      return;
    }
    await unarchiveCases({ casesIds: [caseId] });
    handleCloseUnarchiveCase();
  };

  const handleActivateCase = async () => {
    if (!caseId) {
      return;
    }
    await activateCase({ casesIds: [caseId] });
    WebToastColoredWithTitle.success("Caso ativado com sucesso");
  };

  if (!caseId) return null;

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <CaseDetails
        loadingCase={isLoadingCase || isActivatingCase}
        caseData={caseData}
        caseId={caseId}
        showNextSteps={caseNextStepsEnabled}
        onDeleteCase={handleOpenDeleteCase}
        onArchiveCase={handleOpenArchiveCase}
        onUnarchiveCase={handleOpenUnarchiveCase}
        onActivateCase={handleActivateCase}
      />

      <DeleteCasesModal
        isOpen={isOpenDeleteCase}
        onConfirm={handleDeleteCase}
        onCancel={handleCloseDeleteCase}
        isLoading={isDeletingCase}
        amount={1}
      />

      <ArchiveCasesModal
        isOpen={isOpenArchiveCase}
        onConfirm={handleArchiveCase}
        onCancel={handleCloseArchiveCase}
        isLoading={isArchivingCase}
        amount={1}
      />

      <UnarchiveCasesModal
        isOpen={isOpenUnarchiveCase}
        onConfirm={handleUnarchiveCase}
        onCancel={handleCloseUnarchiveCase}
        isLoading={isUnarchivingCase}
        amount={1}
      />

      <Outlet />
    </PageLayout>
  );
};
