import React, { useEffect } from "react";

import { Box, Modal } from "@mui/material";

export default function WhatsAppAssistantFormModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "620px",
          height: "100%",
          maxHeight: "740px",
          bgcolor: "common.white",
          borderRadius: 1,
          overflow: "auto",
          "&::-webkit-scrollbar": { width: "6px" },
          "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            backgroundColor: "common.white",
            marginY: 1,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            backgroundColor: "grey.400",
            marginY: 1,
          },
        }}
      >
        <HubspotForm key={Math.random()} />
      </Box>
    </Modal>
  );
}

const HubspotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("id", "hubspot-script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "8374664",
          formId: "90c934f8-142d-40d7-a3d3-5a2af021b0fc",
          target: ".hs-form-frame",
          region: "na1",
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      script.remove();
    };
  }, []);

  return (
    <div>
      <div className="hs-form-frame" />
    </div>
  );
};
