import React from "react";
import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Check } from "@mui/icons-material";

interface PlanFeature {
  text: React.ReactNode;
  bold?: boolean;
}

interface PlanCardProps {
  title: string;
  buttonText: string;
  features: PlanFeature[];
  price?: string;
  variant?: "default" | "highlighted";
  buttonProps?: Partial<React.ComponentProps<typeof Button>>;
  priceColor?: string;
}

export const PlanCard = ({
  title,
  buttonText,
  features,
  price,
  variant = "default",
  buttonProps,
  priceColor,
}: PlanCardProps) => {
  return (
    <CardContainer variant={variant}>
      <Typography variant="h6">{title}</Typography>
      <Typography height={32} variant="h5" color={priceColor}>
        {price}
        {price && <Typography variant="bold">/por mês</Typography>}
      </Typography>
      <Button variant="contained" fullWidth {...buttonProps}>
        {buttonText}
      </Button>
      <Typography variant="subtitle1" fontWeight={700}>
        Inclui:
      </Typography>
      <Stack direction="column" gap={1}>
        {features.map((feature, index) => (
          <Stack key={index} direction="row" gap={1} alignItems="center">
            <Check transform="scale(0.5)" />
            <Typography variant="body2" fontWeight={feature.bold ? "700" : undefined}>
              {feature.text}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </CardContainer>
  );
};

const CardContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "variant",
})<{ variant: "default" | "highlighted" }>(({ theme, variant }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  width: "50%",
  ...(variant === "highlighted" && {
    backgroundColor: theme.palette.orange?.light[5],
  }),
}));
