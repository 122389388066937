import * as logger from "@/core/logger";
import { FirebaseService } from "@/services/firebase";
import { AUTHENTICATED_ROUTES_PREFIX, ROUTE_PATHS } from "@/routes/routePaths";
import { router } from "@/routes/router";
import { LoginType, useAuthStore } from "@/stores/useAuthStore";
import { isTokenExpired } from "@/utils/isTokenExpired";
import { queryClient } from "@/utils/queryClient";

export const AuthService = {
  async setupFromFirebase() {
    const storedToken = useAuthStore.getState().token;
    const token = await FirebaseService.getToken();
    if (storedToken !== token) {
      useAuthStore.getState().setToken({ token, loginType: LoginType.FIREBASE });
    }
    return { success: true, token };
  },

  async getValidToken() {
    const { token, loginType } = useAuthStore.getState();
    if (!loginType || !token) {
      return { token: undefined, loginType: undefined };
    }
    if (isTokenExpired(token)) {
      const { success, token: refreshedToken } = await this.refreshAuthToken();

      if (success && refreshedToken) {
        return { token: refreshedToken, loginType };
      } else {
        return { token: undefined, loginType: undefined };
      }
    }
    return { token, loginType };
  },

  async validateToken() {
    const { token } = await this.getValidToken();
    if (!token) {
      await this.logout({ redirectBack: true });
    }
  },

  async refreshAuthToken() {
    try {
      const loginType = useAuthStore.getState().loginType;
      if (!loginType || loginType !== LoginType.FIREBASE) {
        logger.debug(`Login type not found: ${loginType}`);
        return { success: false };
      }

      const token = await FirebaseService.refreshToken();
      if (!token || token === "") {
        logger.debug(`Firebase token not found: ${token}`);
        return { success: false };
      }
      useAuthStore.getState().setToken({ token, loginType: LoginType.FIREBASE });
      return { success: true, token };
    } catch (e) {
      logger.debug(`Error refreshing auth token, ${JSON.stringify(e)}`);
      await this.logout({ redirectBack: true });
      return { success: false };
    }
  },

  async logout({ redirectBack = false }: { redirectBack?: boolean } = {}) {
    const loginType = useAuthStore.getState().loginType;
    if (loginType === LoginType.FIREBASE) {
      await FirebaseService.signOut();
    }

    logger.clearProfile();
    useAuthStore.getState().clearToken();
    queryClient.clear();

    const isLoggedRoute = window.location.pathname.startsWith(AUTHENTICATED_ROUTES_PREFIX);
    const isRoot = window.location.pathname === "/";

    if (isLoggedRoute || isRoot) {
      await router.navigate(
        ROUTE_PATHS.LOGIN,
        redirectBack && !isRoot
          ? {
              state: { redirectTo: window.location.pathname + window.location.search },
            }
          : undefined
      );
    }
  },
};
