import { useEffect, useRef, useState } from "react";
import { clamp } from "@/utils/clamp";

type WidthOption = number | { value: number; unit: "%" };

export const useResizable = ({
  defaultWidth = { value: 40, unit: "%" },
  minWidth = { value: 20, unit: "%" },
  minOppositeWidth = { value: 20, unit: "%" },
  direction = "left",
  onWidthChange,
}: {
  defaultWidth?: WidthOption;
  minWidth?: WidthOption;
  minOppositeWidth?: WidthOption;
  direction?: "left" | "right";
  onWidthChange: (width: number) => void;
}) => {
  const [isResizing, setIsResizing] = useState(false);
  const isResizingRef = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const minOppositeWidthRef = useRef(minOppositeWidth);

  useEffect(() => {
    minOppositeWidthRef.current = minOppositeWidth;
  }, [minOppositeWidth]);

  const createOverlay = () => {
    const overlay = document.createElement("div");
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.bottom = "0";
    overlay.style.right = "0";
    overlay.style.cursor = "ew-resize";
    overlay.style.zIndex = "9999";
    overlayRef.current = overlay;
    document.body.appendChild(overlay);
  };

  const removeOverlay = () => {
    if (overlayRef.current) {
      document.body.removeChild(overlayRef.current);
      overlayRef.current = null;
    }
  };

  const handleMouseDown = () => {
    isResizingRef.current = true;
    setIsResizing(true);
    createOverlay();
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizingRef.current) {
      const container = containerRef.current?.getBoundingClientRect();
      if (!container) return;

      const newWidth = direction === "left" ? e.clientX - container.left : container.right - e.clientX;
      const effectiveMinWidth = convertWidth(minWidth, container.width);
      const effectiveMinOppositeWidth = convertWidth(minOppositeWidthRef.current, container.width);

      const clampedWidth = clamp(newWidth, effectiveMinWidth, container.width - effectiveMinOppositeWidth);

      onWidthChange(clampedWidth);
    }
  };

  const handleMouseUp = () => {
    isResizingRef.current = false;
    setIsResizing(false);
    removeOverlay();
  };

  const getContainerWidth = () => {
    if (!containerRef.current) return;

    return containerRef.current.getBoundingClientRect().width;
  };

  const convertWidth = (width: WidthOption, containerWidth: number) => {
    if (typeof width === "number") {
      return width;
    }
    return Math.round(containerWidth * (width.value / 100));
  };

  useEffect(() => {
    const containerWidth = getContainerWidth();
    if (containerWidth) {
      onWidthChange(convertWidth(defaultWidth, containerWidth));
    }

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("resize", () => {
      const containerWidth = getContainerWidth();
      if (containerWidth) {
        onWidthChange(convertWidth(defaultWidth, containerWidth));
      }
    });

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("resize", () => {});
      removeOverlay();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    containerRef,
    isResizing,
    handleMouseDown,
  };
};
