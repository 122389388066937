import { LexZapChat } from "../types";
import { useLexZapChats } from "../useLexZapChats";

interface UseLexZapChatProps {
  applicantPhoneNumber?: string;
  disabled?: boolean;
}

interface UseLexZapChatReturn {
  chat?: LexZapChat;
  isLoading: boolean;
  isError: boolean;
}

export const useLexZapChat = ({ applicantPhoneNumber, disabled }: UseLexZapChatProps): UseLexZapChatReturn => {
  const { data: chats, isLoading, isError } = useLexZapChats({ disabled });

  return {
    chat: chats?.find((chat) => chat.endClientNumber === applicantPhoneNumber),
    isLoading,
    isError,
  };
};
