import { PlanType } from "@/hooks/credits/types";
import { CreditsApiResponse } from "@/hooks/useApi/types";
import { DateTime } from "luxon";

export const isPaidUser = (credits?: CreditsApiResponse) => {
  const plan = credits?.companyPlan?.plan;
  return plan !== PlanType.TRIAL && plan !== PlanType.FREEMIUM;
};

export const isFreeUser = (credits?: CreditsApiResponse) => {
  const plan = credits?.companyPlan?.plan;
  return plan === PlanType.TRIAL || plan === PlanType.FREEMIUM;
};

export const notShowCredits = (credits?: CreditsApiResponse, shouldHideCredits?: boolean) => {
  if (shouldHideCredits) return true;

  const plan = credits?.companyPlan?.plan;
  return (
    plan === PlanType.TRIAL ||
    plan === PlanType.UNLIMITED ||
    plan === PlanType.PROFESSIONAL_WITH_WPP ||
    plan === PlanType.PERFORMANCE
  );
};

export const calculateDaysToEndPlan = (nextBillingDate?: string) => {
  if (nextBillingDate) {
    const now = DateTime.now();
    const diffDays = DateTime.fromISO(nextBillingDate).diff(now, "days");
    if (diffDays.days > 0 && diffDays.days < 1) {
      return 1;
    }

    return Number(diffDays.days.toFixed());
  }

  return 0;
};

export const getPlanDaysLeftDescription = (nextBillingDate?: string) => {
  const daysToEndPlan = calculateDaysToEndPlan(nextBillingDate);
  if (daysToEndPlan === 0) {
    return "Último dia de teste";
  }

  if (daysToEndPlan < 0) {
    return "Expirado";
  }

  return `${daysToEndPlan} ${daysToEndPlan === 1 ? "dia" : "dias"} restantes`;
};
