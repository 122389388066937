import { LegalProceedingService } from "@/services/legalProceeding";
import { useMutation } from "@tanstack/react-query";
import { AddCourtCredentialPayload } from "@/services/legalProceeding/types";
import { queryClient } from "@/utils/queryClient";
import { courtCredentialsQueryKey } from "../useCourtCredentials";

export const useAddCourtCredential = () => {
  const api = LegalProceedingService;

  return useMutation({
    mutationKey: ["addCourtCredential"],
    mutationFn: async ({ userId, credential }: { userId: string; credential: AddCourtCredentialPayload }) =>
      await api.addCourtCredentials(userId, credential),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: courtCredentialsQueryKey });
    },
  });
};
