import React from "react";
import { InfoOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TestPeriodAlert = () => {
  return (
    <Alert>
      <InfoOutlined color={"info"} transform="scale(.8)" />
      <Stack>
        <Typography variant="body1" fontWeight={700}>
          Você está em um período de teste
        </Typography>
        <Typography variant="body2">
          Após esse prazo, será necessário assinar um de nossos planos para continuar utilizando os serviços.
        </Typography>
      </Stack>
    </Alert>
  );
};

const Alert = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.alert?.info,
  borderRadius: theme.shape.borderRadius / 2,
  flexDirection: "row",
  gap: theme.spacing(1.5),
  padding: theme.spacing(2),
}));
