import { Box, Button, Collapse } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Search } from "./components/Search";
import { ChatItem } from "./components/ChatItem";
import { EmptyList } from "./components/EmptyList";
import { ChatItemSkeleton } from "./components/ChatItem/skeleton";
import { ChatFilter, ChatFilters, ChatMode, ChatStatus, LexZapChat, TicketStatus } from "@/hooks/lexZap/types";

import { Add as AddIcon } from "@mui/icons-material";
import { ApplicantsList } from "@/components/Applicants/ApplicantsList";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { Filter } from "./components/Filter";
import { useAuthContext } from "@/contexts/AuthContext";
import { activityAreaOptions } from "@/hooks/company/types";
import { useLexZapChats } from "@/hooks/lexZap/useLexZapChats";
interface ChatListProps {
  chats?: LexZapChat[];
  isLoading: boolean;
  setupCollapseHeader: (ref: React.MutableRefObject<HTMLElement | undefined>) => void;
}

const chatFilters: ChatFilters = {
  activityAreas: activityAreaOptions,
  options: [
    {
      label: "Minhas conversas",
      value: ChatFilter.MY_CHATS,
    },
    {
      label: "Aguardando humano",
      value: ChatFilter.AWAITING_HUMAN,
    },
    {
      label: "Arquivadas",
      value: ChatFilter.ARCHIVED,
    },
  ],
};

export const ChatList = ({ chats, isLoading, setupCollapseHeader }: ChatListProps) => {
  const { activeChat, setActiveChat } = useLexZapActiveChat();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<ChatFilters>({
    activityAreas: [],
    options: [
      {
        label: "Aguardando humano",
        value: ChatFilter.AWAITING_HUMAN,
      },
    ],
  });
  const [isApplicantsListOpen, setIsApplicantsListOpen] = useState(false);
  const { user } = useAuthContext();
  useLexZapChats();

  const shouldShowArchivedChats = useMemo(() => {
    return selectedFilters.options.find((option) => option.value === ChatFilter.ARCHIVED);
  }, [selectedFilters.options]);

  const allChats = useMemo(() => {
    if (shouldShowArchivedChats) return chats;
    else {
      return chats?.filter((item) => item.status !== ChatStatus.ARCHIVED);
    }
  }, [chats, shouldShowArchivedChats]);

  const filteredChatsBySearch = useMemo(() => {
    if (!searchTerm) return allChats;

    return allChats?.filter((item) => item.applicantName?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [allChats, searchTerm]);

  const filteredChatsByFilter = useMemo(() => {
    let filteredChatsByEachFilter: LexZapChat[] = [];

    if (!selectedFilters.options.length && !selectedFilters.activityAreas.length) return filteredChatsBySearch;

    selectedFilters.options.forEach((filter) => {
      if (filter.value === ChatFilter.MY_CHATS) {
        filteredChatsByEachFilter = filteredChatsByEachFilter.concat(
          filteredChatsBySearch?.filter((item) => item.lastTicket?.responsibleUserId === user?.userId) || []
        );
      }
      if (filter.value === ChatFilter.AWAITING_HUMAN) {
        filteredChatsByEachFilter = filteredChatsByEachFilter.concat(
          filteredChatsBySearch?.filter(
            (item) => item.chatMode !== ChatMode.AI && item.lastTicket?.status !== TicketStatus.CLOSED
          ) || []
        );
      }
      if (filter.value === ChatFilter.ARCHIVED) {
        filteredChatsByEachFilter = filteredChatsByEachFilter.concat(
          filteredChatsBySearch?.filter((item) => item.status === ChatStatus.ARCHIVED) || []
        );
      }
    });

    selectedFilters.activityAreas.forEach((area) => {
      filteredChatsByEachFilter = filteredChatsByEachFilter.concat(
        filteredChatsBySearch?.filter((item) => item.lastTicket?.activityAreas?.includes(area.value)) || []
      );
    });

    return filteredChatsByEachFilter.sort((a, b) => {
      const timestampA = a.lastMessage?.timestamp || 0;
      const timestampB = b.lastMessage?.timestamp || 0;
      return timestampB - timestampA;
    });
  }, [selectedFilters.options, selectedFilters.activityAreas, filteredChatsBySearch, user?.userId]);

  const hasFilters = useMemo(() => {
    return selectedFilters.options.length > 0 || selectedFilters.activityAreas.length > 0;
  }, [selectedFilters.options, selectedFilters.activityAreas]);

  const openApplicantsList = () => {
    setIsApplicantsListOpen(true);
  };

  const closeApplicantsList = () => {
    setIsApplicantsListOpen(false);
  };

  const headerRef = useRef<HTMLElement>();

  useEffect(() => {
    if (isLoading) return;

    if (filteredChatsByFilter?.length) {
      setActiveChat(filteredChatsByFilter[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    setupCollapseHeader(headerRef);
  }, [headerRef]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "orange.light.10",
        paddingTop: 2.5,
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          paddingLeft: 2,
          gap: 2,
          alignItems: "center",
          paddingRight: 4,
        }}
        ref={headerRef}
      >
        <Search onChange={setSearchTerm} />
        <Button
          sx={{
            height: "48px",
            width: "48px",
            minWidth: "48px",
            minHeight: "48px",
            backgroundColor: "orange.light.30",
            "&:hover": {
              backgroundColor: "orange.light.20",
            },
          }}
          onClick={openApplicantsList}
        >
          <AddIcon sx={{ color: "common.black" }} />
        </Button>
        <Collapse
          in={!hasFilters}
          easing={{
            enter: "linear",
            exit: "linear",
          }}
          orientation="horizontal"
          timeout={100}
        >
          <Filter
            onChange={setSelectedFilters}
            selectedFilters={selectedFilters}
            chatFilters={chatFilters}
            totalResults={filteredChatsByFilter?.length || 0}
          />
        </Collapse>
      </Box>

      <Collapse
        in={hasFilters}
        easing={{
          enter: "linear",
          exit: "linear",
        }}
        orientation="vertical"
        timeout={100}
      >
        <Box sx={{ display: "flex", paddingX: 2, color: "grey.700" }}>
          <Filter
            onChange={setSelectedFilters}
            selectedFilters={selectedFilters}
            chatFilters={chatFilters}
            totalResults={filteredChatsByFilter?.length || 0}
          />
        </Box>
      </Collapse>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: 2,
            paddingBottom: 2,
            gap: 1,
            overflow: "auto",
          }}
        >
          {Array.from({ length: 10 }, (_, index) => (
            <ChatItemSkeleton key={index} isActive={index === 0} />
          ))}
        </Box>
      ) : (
        <>
          {!filteredChatsByFilter?.length ? (
            <EmptyList />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                paddingX: 2,
                paddingBottom: 2,
                gap: 1,
                overflow: "auto",
              }}
            >
              {filteredChatsByFilter.map((item) => (
                <ChatItem
                  key={item.endClientNumber}
                  onClick={() => setActiveChat(item)}
                  chat={item}
                  isActive={item.endClientNumber === activeChat?.endClientNumber}
                />
              ))}
            </Box>
          )}

          {isApplicantsListOpen && <ApplicantsList isOpen={true} onClose={closeApplicantsList} />}
        </>
      )}
    </Box>
  );
};
