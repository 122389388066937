import { ActionId, DecisionType, LegalPieceMacroType, UploadingFile } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { base64ToFile } from "@/utils/base64ToFile";
import { fileToUploadingFile } from "@/utils/fileToUploadingFile";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  document_name: Type.String(),
  legal_piece_macro_type: Type.String(),
  area: Type.String(),
  client: Type.String(),
  reference_piece: Type.Optional(Type.String()),
  decision_type: Type.Optional(Type.String()),
  case_breakdown: Type.Optional(Type.String()),
  theses: Type.Optional(Type.String()),
  evidence_text: Type.Optional(Type.String()),
  requests: Type.Optional(Type.String()),
  timeliness_text: Type.Optional(Type.String()),
});

const actionId = ActionId.CREATE_ONE_PIECE;

export const createOtherTypeOfPieceSkill = {
  id: "create_other_type_of_piece",
  name: "Outro tipo de peça",
  credits: CreditPerSkill.ADVANCED_SKILL,
  inputSchema,
  actionId,
  type: "message",
  getMessage: (input) => {
    let file = undefined;
    if (input.reference_piece) {
      file = input.reference_piece.startsWith("data:")
        ? fileToUploadingFile(base64ToFile(input.reference_piece))
        : undefined;
    }

    if (!file && input.legal_piece_macro_type === LegalPieceMacroType.RESPONSE) {
      throw new Error("Contestation file not found");
    }

    if (!file && input.legal_piece_macro_type === LegalPieceMacroType.APPEAL) {
      throw new Error("Appeal file not found");
    }

    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Criar outro tipo de peça",
      actions: [
        {
          id: actionId,
          text: "Criar outro tipo de peça",
          client: input.client,
          legal_piece_macro_type: input.legal_piece_macro_type as LegalPieceMacroType,
          legal_piece_micro_type: input.document_name,
          decision_type: input.decision_type as DecisionType,
          area: input.area,
          case_breakdown: input.case_breakdown
            ? {
                source: "TEXT",
                text: input.case_breakdown,
              }
            : undefined,
          theses: input.theses,
          evidence_text: input.evidence_text,
          requests: input.requests,
          timeliness_text: input.timeliness_text,
          reference_piece: file
            ? {
                source: "FILE",
                file,
              }
            : undefined,
        },
      ],
      files: [file].filter((file): file is UploadingFile => Boolean(file)),
    };
  },
} satisfies Skill<typeof inputSchema>;
