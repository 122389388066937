import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DialogModal } from "@/components/DialogModal";

import { WebToast } from "@/components/core/Toast";
import { useResendCompanyMemberInvitation } from "@/hooks/company/useResendCompanyMemberInvitation";

export const ResendEmailModal = ({
  isOpen,
  onConfirm,
  onCancel,
  isLoading,
  email,
  memberId,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  email: string;
  memberId: string;
}) => {
  const { mutateAsync: resendCompanyMemberInvitation, isPending: isResending } = useResendCompanyMemberInvitation();

  const handleResendCompanyMemberInvitation = async () => {
    try {
      await resendCompanyMemberInvitation({
        memberId,
      });

      onConfirm();
      return WebToast.success("E-mail reenviado com sucesso");
    } catch (err) {
      return WebToast.error("Falha ao reenviar e-mail");
    }
  };

  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title={"Reenviar e-mail"}
      description={
        isLoading ? (
          <Box display="flex" color="secondary" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Typography variant="body1" sx={{ color: "text.primary", textAlign: "center" }}>
            Confirmando, nós reenviaremos o convite de e-mail para{" "}
            <Typography
              sx={{
                display: "inline",
                fontWeight: "bold !important",
                color: "primary.main",
                textDecoration: "underline",
              }}
            >
              {email}
            </Typography>
          </Typography>
        )
      }
      descriptionTextAlign="left"
      buttonsDirection="row"
      buttons={[
        {
          label: "Cancelar",
          onClick: isLoading || isResending ? undefined : onCancel,
          sx: {
            color: "text.primary",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
          },
        },
        {
          label: "Confirmar",
          onClick: handleResendCompanyMemberInvitation,
          color: "primary",
          disabled: isLoading || isResending,
        },
      ]}
      variant="error"
    />
  );
};
