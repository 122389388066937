import React, { ChangeEvent, useEffect, useMemo } from "react";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Loading } from "@/components/Loading";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { WebToast } from "@/components/core/Toast";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { ActivityArea, activityAreaOptions, Department, getActivityAreaLabel } from "@/hooks/company/types";
import { CloseOutlined } from "@mui/icons-material";
import { useUpdateTicket } from "@/hooks/lexZap/useUpdateTicket";
import { Ticket } from "@/hooks/lexZap/types";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";

type Form = {
  responsibleUserId?: string;
  department?: Department;
  activityAreas?: ActivityArea[];
};

export const TicketDetailsModal = ({ onClose, ticket }: { onClose: () => void; ticket: Ticket }) => {
  const [form, setForm] = React.useState<Form>({
    responsibleUserId: ticket.responsibleUserId,
    department: ticket.department,
    activityAreas: ticket.activityAreas,
  });
  const { mutateAsync: updateTicket, isPending: isUpdating } = useUpdateTicket();
  const { data: companyUsers, isLoading: isLoadingCompanyUsers } = useCompanyTeam();

  const selectedActivityAreasOptions = useMemo(() => {
    return form.activityAreas?.map((area) => ({
      value: area,
      label: getActivityAreaLabel(area),
    }));
  }, [form.activityAreas]);

  useEffect(() => {
    if (ticket) {
      setForm({
        responsibleUserId: ticket.responsibleUserId,
        department: ticket.department,
        activityAreas: ticket.activityAreas,
      });
    }
  }, [ticket]);

  const activeCompanyUsers = useMemo(() => {
    if (!companyUsers) return;

    return companyUsers.filter((user) => user.isActive);
  }, [companyUsers]);

  const handleUpdateTicket = async () => {
    if (!form.responsibleUserId) {
      WebToast.error("Preencha todos os campos obrigatórios");
      return;
    }

    try {
      await updateTicket({
        ticketId: ticket.id,
        applicantPhoneNumber: ticket.applicantPhoneNumber,
        updateFields: {
          department: form.department,
          activityAreas: form.activityAreas,
          responsibleUserId: form.responsibleUserId,
        },
      });

      onClose();
      return WebToast.success("Atendimento atualizado com sucesso");
    } catch (err) {
      onClose();
      return WebToast.error("Falha ao atualizar atendimento");
    }
  };

  const handleChangeForm = <K extends keyof Form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement> | SelectChangeEvent<Department | string>) => {
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
    };
  };

  const saveButtonDisabled = useMemo(() => {
    return !form.responsibleUserId;
  }, [form]);

  return (
    <ModalRight
      title={"Gerenciar conversa"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        !isLoadingCompanyUsers &&
        !isUpdating && (
          <ModalFooter
            confirmLabel={"Atualizar"}
            onConfirm={handleUpdateTicket}
            cancelLabel="Cancelar"
            onCancel={onClose}
            saveButtonDisabled={saveButtonDisabled}
          />
        )
      }
      open={true}
    >
      <Loading isLoading={isUpdating || isLoadingCompanyUsers}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginBottom: "8px",
          }}
        >
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Área das conversa
              </Typography>
              <Typography variant="body2">Essa conversa foi marcada como pertencente à area:</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {form.activityAreas?.map((area) => (
                <Chip
                  key={area}
                  label={getActivityAreaLabel(area)}
                  onDelete={() => {
                    setForm((prevState) => ({
                      ...prevState,
                      activityAreas: prevState.activityAreas?.filter((item) => item !== area),
                    }));
                  }}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "common.white",
                  }}
                  deleteIcon={<CloseOutlined />}
                />
              ))}
            </Box>

            <Box>
              <Autocomplete
                disablePortal
                options={activityAreaOptions}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <TextField {...params} label="Comece a digitar para buscar" />}
                multiple
                value={selectedActivityAreasOptions}
                noOptionsText="Nenhuma opção encontrada"
                sx={{
                  "& .MuiChip-root": {
                    visibility: "hidden",
                    display: "none",
                    color: "red",
                  },
                  "& .MuiChip-label": {
                    display: "none",
                    visibility: "hidden",
                    color: "red",
                  },
                }}
                onChange={(_, selectedOptions) => {
                  setForm((prevState) => ({
                    ...prevState,
                    activityAreas: selectedOptions.map((item) => item.value),
                  }));
                }}
              />
            </Box>
          </>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Setor
              </Typography>
              <Typography variant="body2">Essa conversa foi marcada como pertencente ao setor:</Typography>
            </Box>
            <FormControl>
              <InputLabel shrink={true} id="select-state-label">
                Setor
              </InputLabel>
              <Select
                labelId="select-state-label"
                value={form?.department}
                label="Setor"
                displayEmpty
                onChange={handleChangeForm("department")}
                renderValue={form.department ? undefined : () => "Selecione o setor da conversa"}
              >
                <MenuItem value={Department.ADMINISTRATIVE}>Administrativo</MenuItem>
                <MenuItem value={Department.FINANCIAL}>Financeiro</MenuItem>
                <MenuItem value={Department.LEGAL}>Jurídico</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "16px",
                }}
              >
                Usuário responsável pela conversa
              </Typography>
            </Box>
            <FormControl>
              <InputLabel shrink={true} id="select-state-label">
                Usuário
              </InputLabel>
              <Select
                labelId="select-state-label"
                value={form.responsibleUserId}
                label="Usuário"
                displayEmpty
                onChange={handleChangeForm("responsibleUserId")}
                renderValue={form.responsibleUserId ? undefined : () => "Selecione o usuário responsável pela conversa"}
              >
                {activeCompanyUsers?.map((user) => (
                  <MenuItem key={user.userId} value={user.userId}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Loading>
    </ModalRight>
  );
};
